import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "./css/Dashboard.scss";
import "./css/Common.scss";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { eyeIcon } from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import { Label } from "@progress/kendo-react-labels";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import Breadcrumbs from "./components/Breadcrumbs";
import axios from "axios";
import {
  commonBaseUrl,
  commonChartColor,
  RightAlignedHeaderCell,
} from "../services/CommonUtil";
import { process, State } from "@progress/kendo-data-query";

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}

interface ViewPaymentCellProps extends GridCellProps {
  paymentView: (item: any) => void;
}

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const Dashboard = () => {
  // Set initial state for the dynamic data
  const baseURL = commonBaseUrl();

  const navigate = useNavigate();
  const hasFetched = useRef(false);

  const [allCount, setAllCount] = useState({
    totalBookings: 0,
    totalSpaces: 0,
    checkInCount: 0,
    pendingPayments: 0,
    totalRevenue: 69620,
  });
  const [allCheckedList, setAllCheckedList] = useState([]);
  const [allOccupancyList, setAllOccupancyList] = useState([]);
  const [allPaymentList, setAllPaymentList] = useState([]);
  const [allInvoiceList, setAllInvoiceList] = useState<any>();
  const [selected, setSelected] = useState<string>("today");
  const [dataState, setDataState] = useState<State>(initialDataState);

  // YYYY-MM-DD format conversion
  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Home",
      route: "",
    },
    {
      id: "2",
      text: "Dashboard",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    handleTodayClick();
  });

  // Initial API call
  const allDashboardCount = async (fromDate: any, toDate: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetDashboardCount?bookingFrom=${fromDate}&bookingTo=${toDate}`
      );
      setAllCount(response.data);
      allCheckInAPI(fromDate, toDate);
    } catch (err) {
      alert(err);
    }
  };

  const allCheckInAPI = async (fromDate: any, toDate: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetAllCheckInCustomers?bookingFrom=${fromDate}&bookingTo=${toDate}`
      );
      setAllCheckedList(response.data);
      console.log(response.data.length);
      allOccupancyGraphAPI(fromDate, toDate);
    } catch (err) {
      alert(err);
    }
  };

  const allOccupancyGraphAPI = async (fromDate: any, toDate: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetDashboardOccupancy?bookingFrom=${fromDate}&bookingTo=${toDate}`
      );
      setAllOccupancyList(response.data);
      allPaymentGraphAPI(fromDate, toDate);
    } catch (err) {
      alert(err);
    }
  };

  const allPaymentGraphAPI = async (fromDate: any, toDate: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetAllPayments?bookingFrom=${fromDate}&bookingTo=${toDate}`
      );
      setAllPaymentList(response.data.reverse().slice(0, 5));
      allInvoiceAPI(fromDate, toDate);
    } catch (err) {
      alert(err);
    }
  };

  const allInvoiceAPI = async (fromDate: any, toDate: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetDashboardInvoices?bookingFrom=${fromDate}&bookingTo=${toDate}`
      );
      const invoiceGraph = [];
      let totalData = {};
      let pendingData = {};
      if (response.data.totalPaid > 0) {
        totalData = {
          category: "Paid",
          percentage: response.data.totalPaid,
        };
      }
      if (response.data.totalPending > 0) {
        pendingData = {
          category: "Pending",
          percentage: response.data.totalPending,
        };
      }
      invoiceGraph.push(totalData);
      invoiceGraph.push(pendingData);
      setAllInvoiceList(invoiceGraph);
    } catch (err) {
      alert(err);
    }
  };
  //

  // Grid View Option
  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    for (let d = 0; d < allCheckedList.length; d++) {
      if (item.bookingId === allCheckedList[d]["bookingId"]) {
        getBookingData(allCheckedList[d]["bookingId"], true);
        break;
      }
    }
  };

  // Booking API
  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(` ${baseURL}odata/Bookings(${id})`);
      navigate("/viewbooking", {
        state: { infoId: response.data, view },
      });
    } catch (err) {}
  };

  const ViewDashboardPaymentCell = (props: ViewPaymentCellProps) => (
    <ViewPaymentCell {...props} />
  );

  const ViewPaymentCell = (props: ViewPaymentCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.paymentView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const paymentView = (item: any) => {
    console.log("The item was -->", item);
    for (let d = 0; d < allPaymentList.length; d++) {
      if (item.bookingId === allPaymentList[d]["bookingId"]) {
        getPaymentsData(allPaymentList[d]["bookingId"], true);
        break;
      }
    }
  };

  // Payment API
  const getPaymentsData = async (id: any, view: any) => {
    try {
      const response = await axios.get(`${baseURL}GetInvoiceHeader`);
      for (let d = 0; d < response.data.length; d++) {
        if (id === response.data[d]["bookingId"]) {
          navigate("/viewpayment", {
            state: {
              invoiceId: response.data[d]["id"],
              bookingId: response.data[d]["bookingId"],
              view: true,
            },
          });
          break;
        }
      }
    } catch (err) {}
  };

  const handleInvoiceViewMore = () => {
    navigate("/management/listbooking/invoice");
  };

  // Handle flow
  const handleSelect = (view: string) => {
    setSelected(view);
    setDataState(initialDataState);
    if (view === "today") {
      handleTodayClick();
    } else if (view === "week") {
      handleThisWeekClick();
    } else if (view === "month") {
      handleThisMonthClick();
    }
  };

  // Multi button option
  const handleTodayClick = () => {
    const currentDate = new Date();
    allDashboardCount(formatDate(currentDate), formatDate(currentDate));
  };

  const handleThisWeekClick = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOfWeek + 1); // Start on Monday (assuming 0 is Sunday)

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End on Sunday

    allDashboardCount(formatDate(startOfWeek), formatDate(endOfWeek));
  };

  const handleThisMonthClick = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    allDashboardCount(formatDate(startOfMonth), formatDate(endOfMonth));
  };

  const labelContent = (e: any) => `${e.dataItem.percentage} %`;

  // Checked in handle

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  return (
    <div className="dashboard-bg">
      <div>
        <div className="dashboard-button">
          <Breadcrumbs breadCrumbData={breadCrumbsData} />
          <ButtonGroup>
            <Button
              selected={selected === "today"}
              onClick={() => {
                handleSelect("today");
              }}
              className={
                selected === "today" ? "selected-color" : "unselect-color"
              }
            >
              TODAY
            </Button>
            <Button
              selected={selected === "week"}
              onClick={() => handleSelect("week")}
              className={
                selected === "week" ? "selected-color" : "unselect-color"
              }
            >
              THIS WEEK
            </Button>
            <Button
              selected={selected === "month"}
              onClick={() => handleSelect("month")}
              className={
                selected === "month" ? "selected-color" : "unselect-color"
              }
            >
              THIS MONTH
            </Button>
          </ButtonGroup>
        </div>
        <hr className="hr-border" />
      </div>
      {/* Count view */}
      <div className="dashboard-grid">
        <div className="dashboard-item">
          <Card>
            <CardBody>
              <h3>Active Spaces</h3>
              <p>{allCount.totalSpaces}</p>
            </CardBody>
          </Card>
        </div>

        <div className="dashboard-item">
          <Card>
            <CardBody>
              <h3>Total Bookings</h3>
              <p>{allCount.totalBookings}</p>
            </CardBody>
          </Card>
        </div>

        <div className="dashboard-item">
          <Card>
            <CardBody>
              <h3>Checked In</h3>
              <p>{allCount.checkInCount}</p>
            </CardBody>
          </Card>
        </div>

        {/* <div className="dashboard-item">
          <Card>
            <CardBody>
              <h3>Pending Payments</h3>
              <p>{allCount.pendingPayments}</p>
            </CardBody>
          </Card>
        </div> */}

        <div className="dashboard-item">
          <Card>
            <CardBody>
              <h3>Total Revenue</h3>
              <p>{allCount.totalRevenue}</p>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* Checked-In and Payment Grid */}
      <div className="dashboard">
        <div className="grid-container">
          <div className="grid-section">
            <div className="header-row">
              <Label className="dashboard-header">Checked-in Customers</Label>
            </div>
            <Grid
              data={process(allCheckedList, dataState)}
              pageable={true}
              skip={dataState.skip}
              take={dataState.take}
              total={allCheckedList.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="id"
                title="S.No."
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="spaceTypeName"
                title="Space Type"
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "left",
                    }}
                  >
                    {props.dataItem.spaceTypeName}
                  </td>
                )}
              />
              <GridColumn
                field="durationName"
                title="Duration"
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "left",
                    }}
                  >
                    {props.dataItem.durationName}
                  </td>
                )}
              />
              <GridColumn
                field="customerName"
                title="Customer Name"
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "left",
                    }}
                  >
                    {props.dataItem.customerName}
                  </td>
                )}
              />
              <GridColumn
                title="Action"
                width={10}
                cell={(props) => (
                  <div>
                    <MyViewCommandCell {...props} enterView={enterView} />
                  </div>
                )}
              />
            </Grid>
          </div>
          <div className="grid-section">
            <div className="header-row">
              <Label className="dashboard-header">Invoice Payments</Label>
              <Button
                onClick={handleInvoiceViewMore}
                className="view-more-button"
              >
                View More
              </Button>
            </div>
            <Grid data={allPaymentList}>
              <GridColumn
                field="id"
                title="S.No."
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="customerName"
                title="Customer Name"
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "left",
                    }}
                  >
                    {props.dataItem.customerName}
                  </td>
                )}
              />
              <GridColumn
                field="spaceTypeName"
                title="Space Type"
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "left",
                    }}
                  >
                    {props.dataItem.spaceTypeName}
                  </td>
                )}
              />
              <GridColumn
                field="paid"
                title="Paid (₹)"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "55px",
                    }}
                  >
                    {props.dataItem.paid}
                  </td>
                )}
              />
              <GridColumn
                title="Action"
                width={10}
                cell={(props) => (
                  <div>
                    <ViewDashboardPaymentCell
                      {...props}
                      paymentView={paymentView}
                    />
                  </div>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
      <div className="dashboard-chart report-top">
        <div className="chart-container report-chart">
          {/* Donut chart */}
          {/* <div className="chart-section">
            <Label className="dashboard-header">Invoices</Label>
            {allPaymentList.length > 0 ? (
              <div className="view-chart-top">
                <Card>
                  <Chart transitions={false}>
                    <ChartLegend position="bottom" />
                    <ChartSeries>
                      <ChartSeriesItem
                        color={commonChartColor}
                        type="donut"
                        data={allInvoiceList}
                        categoryField="category"
                        field="percentage"
                      >
                        <ChartSeriesLabels
                          color="#fff"
                          background="none"
                          content={labelContent}
                        />
                      </ChartSeriesItem>
                    </ChartSeries>
                  </Chart>
                </Card>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                No Invoices data available
              </div>
            )}
          </div> */}
          {/* Pie chart */}
          <div className="chart-full-section">
            <Label className="dashboard-header">Occupancy (%)</Label>
            {allOccupancyList.length > 0 ? (
              <div className="view-chart-top">
                <Card>
                  <Chart transitions={false}>
                    <ChartLegend position="right" />
                    <ChartSeries>
                      <ChartSeriesItem
                        color={commonChartColor}
                        type="pie"
                        data={allOccupancyList}
                        field="percentage"
                        categoryField="spaceTypeName"
                        labels={{ visible: true }}
                      >
                        <ChartSeriesLabels
                          color="#000"
                          background="none"
                          content={labelContent}
                        />
                      </ChartSeriesItem>
                    </ChartSeries>
                  </Chart>
                </Card>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                No Occupancy data available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
