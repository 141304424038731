import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  chartPieIcon,
  chevronDownIcon,
  exportIcon,
  listUnorderedIcon,
} from "@progress/kendo-svg-icons";
import { Label } from "@progress/kendo-react-labels";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import "../css/Reports.scss";
import "../css/Common.scss";
import "../css/Dashboard.scss";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  commonBaseUrl,
  barChartColor,
  commonChartColor,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
import axios from "axios";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  filterBy,
  FilterDescriptor,
  process,
  State,
} from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox } from "@progress/kendo-react-inputs";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const CafeteriaSales = () => {
  const baseURL = commonBaseUrl();

  const hasFetched = useRef(false);
  const [selected, setSelected] = useState<string>("summary");
  const userTypeList = [
    {
      Id: 1,
      Name: "Company",
    },
    {
      Id: 2,
      Name: "Individual",
    },
  ];
  const defaultUserTypeValue = {
    Id: 0,
    Name: "Select User Type",
  };
  const defaultOrderStatusValue = {
    id: 0,
    name: "Select Order Status",
  };
  const [loading, setLoading] = React.useState(false);
  const [userType, setUserType] = useState(defaultUserTypeValue);
  const [orderStatus, setOrderStatus] = useState(defaultOrderStatusValue);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [bookingFrom, setBookingFrom] = useState<Date | null>(null);
  const [bookingTo, setBookingTo] = useState<Date | null>(null);

  const [userTypeId, setUserTypeId] = useState(0);
  const [orderStatusId, setOrderStatusId] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);

  const [dataState, setDataState] = useState<State>(initialDataState);

  const _export = React.useRef<ExcelExport | null>(null);
  const _grid = React.useRef<any>();
  const pdfExportComponent = React.useRef<PDFExport>(null);

  const [cafeteriaSalesList, setcafeteriaSalesList] = useState([]);
  const [cafeteriaPieChartList, setcafeteriaPieChartList] = useState([]);
  const [cafeteriaBarChartList, setcafeteriaBarChartList] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categorySource, setCategorySource] = useState<string[]>([]);
  const [subCategorySource, setsubCategorySource] = useState<string[]>([]);

  const [openedCategory, setOpenedCategory] = useState(false);
  const [openedSubCategory, setOpenedSubCategory] = useState(false);

  const [categoryState, setCategoryState] = React.useState({
    data: categorySource,
    value: "",
  });
  const [subCategoryState, setSubCategoryState] = React.useState({
    data: subCategorySource,
    value: "",
  });

  // CSS styling for the components
  const styles = `
  .no-wheel input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Hide spinner controls */
    pointer-events: none;
    user-select: none;
  }
  
  .no-wheel input::-webkit-inner-spin-button,
  .no-wheel input::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  .no-wheel input::-moz-inner-spin-button {
    display: none;
  }
  `;

  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };

  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Function to format date to YYYY-MM-DD
  const formatDate = (date: any) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getStartDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getEndDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  };

  // Helper function to get Veg/Non-Veg based on the input string
  const getFoodType = (inputString: any) => {
    const valuesArray = inputString.split(", ").map(Number);
    if (
      (Number(valuesArray[0]) === 2 && Number(valuesArray[1]) === 1) ||
      (Number(valuesArray[0]) === 1 && Number(valuesArray[1]) === 2)
    ) {
      return "Non-Veg, Veg"; // Non-Veg, Veg
    }
    if (Number(valuesArray[0]) === 1) {
      return "Veg"; // Veg
    }
    if (Number(valuesArray[0]) === 2) {
      return "Non-Veg"; // Non-Veg
    }
  };

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    // Function to handle mouse wheel events
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault(); // Prevent scrolling with mouse wheel
    };

    // Function to handle keyboard events
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener to prevent scrolling with mouse wheel
      element.addEventListener("wheel", handleWheel, { passive: false });

      const inputElement = element.querySelector("input");

      if (inputElement) {
        // Attach keydown event listener to prevent up/down arrows and numbers
        inputElement.addEventListener("keydown", handleKeyDown);
      }

      // Cleanup event listeners when component unmounts
      return () => {
        element.removeEventListener("wheel", handleWheel);
        if (inputElement) {
          inputElement.removeEventListener("keydown", handleKeyDown);
        }
      };
    }
  }, []);

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "Cafeteria Sales Report",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;

    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    OrderStatusAPI();
  });

  // Initial API Call
  const OrderStatusAPI = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/GetAllFoodOrderStatus`);
      setOrderStatusList(response.data);
      categoryAPI();

      const startDate = formatDate(getStartDate());
      const endDate = formatDate(getEndDate());
      cafeteriaSalesReportAPI(startDate, endDate, 0, 0, 0, 0, response.data);
    } catch (err) {
      alert(err);
    }
  };

  const categoryAPI = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Categorys`);
      const cateList = response.data.value;
      let categoryList = [];
      for (let c = 0; c < cateList.length; c++) {
        categoryList.push(cateList[c].Name);
      }
      setCategorySource(categoryList);
      setCategoryList(response.data.value);
    } catch (err) {
      alert(err);
    }
  };

  const subCategoryAPI = async (subCategoryId: number) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/SubCategorys/?$filter=CategoryId eq ${subCategoryId}`
      );
      const subList = response.data.value;
      let subCategoryList = [];
      for (let s = 0; s < subList.length; s++) {
        subCategoryList.push(subList[s].Name);
      }
      setsubCategorySource(subCategoryList);
      setSubCategoryList(response.data.value);
    } catch (err) {
      alert(err);
    }
  };

  const cafeteriaSalesReportAPI = async (
    startDate: string,
    endDate: string,
    userTypeId: number,
    orderStatusId: number,
    selectedCategoryId: number,
    selectedSubCategoryId: number,
    orderStatusList: any
  ) => {
    let fullURL = ``;
    let userURL = userTypeId === 0 ? "" : `&userType=${userTypeId}`;
    let statusURL = orderStatusId === 0 ? "" : `&OrderStatus=${orderStatusId}`;
    let categoryURL =
      selectedCategoryId === 0 ? "" : `&categoryId=${selectedCategoryId}`;
    let subURL =
      selectedSubCategoryId === 0
        ? ""
        : `&subCategoryId=${selectedSubCategoryId}`;

    fullURL = `${baseURL}GetCafeteriaSalesReport?startDate=${startDate}&endDate=${endDate}${userURL}${statusURL}${categoryURL}${subURL}`;
    try {
      const response = await axios.get(fullURL);
      var content = response.data;
      for (let i = 0; i < content.length; i++) {
        const orderTypeString = getFoodType(content[i]["combineOrderType"]);
        const orderDateString = content[i]["orderDate"];
        content[i]["userType"] =
          content[i]["userType"] === 1 ? "Company" : "Individual";
        content[i]["combineOrderType"] = orderTypeString;
        content[i]["orderDate"] = customDateOnly(orderDateString);
        console.log("content", orderStatusList);
        for (let s = 0; s < orderStatusList.length; s++) {
          console.log("status");
          if (
            Number(content[i]["orderStatus"]) ===
            Number(orderStatusList[s]["id"])
          ) {
            console.log("status if");
            content[i]["orderStatus"] = orderStatusList[s]["name"];
            break;
          }
        }
      }
      const dataWithSerialNumbers = content.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      // console.log("dataWithSerialNumbers", dataWithSerialNumbers);
      setcafeteriaSalesList(dataWithSerialNumbers);
      if (searchTerm.trim() === "") {
        setFilteredData(dataWithSerialNumbers); // Reset to original data when search is empty
      } else {
        const newData = dataWithSerialNumbers.filter((item: any) => {
          return item.userName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase());
        });
        setDataState(initialDataState);
        setFilteredData(newData);
      }
      cafeteriaPieChartAPI(
        startDate,
        endDate,
        userTypeId,
        orderStatusId,
        selectedCategoryId,
        selectedSubCategoryId
      );
    } catch (err) {
      alert(err);
    }
  };

  const cafeteriaPieChartAPI = async (
    startDate: string,
    endDate: string,
    userTypeId: number,
    orderStatusId: number,
    selectedCategoryId: number,
    selectedSubCategoryId: number
  ) => {
    let fullURL = ``;
    let userURL = userTypeId === 0 ? "" : `&userType=${userTypeId}`;
    let statusURL = orderStatusId === 0 ? "" : `&OrderStatus=${orderStatusId}`;
    let categoryURL =
      selectedCategoryId === 0 ? "" : `&categoryId=${selectedCategoryId}`;
    let subURL =
      selectedSubCategoryId === 0
        ? ""
        : `&subCategoryId=${selectedSubCategoryId}`;

    fullURL = `${baseURL}GetCafeteriaSalesPieChart?startDate=${startDate}&endDate=${endDate}${userURL}${statusURL}${categoryURL}${subURL}`;
    try {
      const response = await axios.get(fullURL);
      setcafeteriaPieChartList(response.data);
      cafeteriaBarChartAPI(
        startDate,
        endDate,
        userTypeId,
        orderStatusId,
        selectedCategoryId,
        selectedSubCategoryId
      );
    } catch (err) {
      alert(err);
    }
  };

  const cafeteriaBarChartAPI = async (
    startDate: string,
    endDate: string,
    userTypeId: number,
    orderStatusId: number,
    selectedCategoryId: number,
    selectedSubCategoryId: number
  ) => {
    let fullURL = ``;
    let userURL = userTypeId === 0 ? "" : `&userType=${userTypeId}`;
    let statusURL = orderStatusId === 0 ? "" : `&OrderStatus=${orderStatusId}`;
    let categoryURL =
      selectedCategoryId === 0 ? "" : `&categoryId=${selectedCategoryId}`;
    let subURL =
      selectedSubCategoryId === 0
        ? ""
        : `&subCategoryId=${selectedSubCategoryId}`;

    fullURL = `${baseURL}GetCafeteriaSalesBarChart?startDate=${startDate}&endDate=${endDate}${userURL}${statusURL}${categoryURL}${subURL}`;
    try {
      const response = await axios.get(fullURL);
      var content = response.data;
      for (let i = 0; i < content.length; i++) {
        content[i]["date"] = customDateOnly(content[i]["date"]);
      }
      setcafeteriaBarChartList(content);
    } catch (err) {
      alert(err);
    }
  };

  const handleFilterChange = () => {
    cafeteriaSalesReportAPI(
      formatDate(bookingFrom),
      formatDate(bookingTo),
      userTypeId,
      orderStatusId,
      selectedCategoryId,
      selectedSubCategoryId,
      orderStatusList
    );
  };

  const handleClearFilters = () => {
    setCategoryState({ data: categorySource, value: "" });
    setSubCategoryState({ data: subCategorySource, value: "" });
    setUserType(defaultUserTypeValue);
    setOrderStatus(defaultOrderStatusValue);
    setUserTypeId(0);
    setOrderStatusId(0);
    setSelectedCategoryId(0);
    setSelectedSubCategoryId(0);
    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    const startDate = formatDate(getStartDate());
    const endDate = formatDate(getEndDate());
    cafeteriaSalesReportAPI(startDate, endDate, 0, 0, 0, 0, orderStatusList);
  };

  // Filter flow
  // From Date filter
  const onFilterChangefromdate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      const selectedFromDate = ev.value;
      setBookingFrom(selectedFromDate);
      if (bookingTo && new Date(bookingTo) < new Date(selectedFromDate)) {
        setBookingTo(selectedFromDate);
      }
    }
  };

  // To Date filter
  const onFilterChangetodate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setBookingTo(ev.value);
    }
  };

  const onFilterDropDown = (ev: any) => {
    const { name, value } = ev.target;

    if (name === "userType") {
      setUserType(value);
      setUserTypeId(value.Id);
    }

    if (name === "orderStatus") {
      setOrderStatus(value);
      setOrderStatusId(value.id);
    }
  };

  // Button Group

  const handleSelect = (view: string) => {
    setSelected(view);
  };

  const excelExport = () => {
    setLoading(true);
    if (_export.current !== null) {
      _export.current.save(cafeteriaSalesList, _grid.current.columns);
    }
  };

  const exportPDFWithComponent = () => {
    setLoading(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setLoading(false);
    }
  };

  // Grid flow
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(cafeteriaSalesList);

  const handleSearchChange = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchTerm(value);
    if (value === "") {
      setFilteredData(cafeteriaSalesList); // Reset to original data when search is empty
    } else {
      const newData = cafeteriaSalesList.filter((item: any) => {
        return (
          item.userName?.toLowerCase().includes(value.toLowerCase()) ||
          item.spaceTypeName?.toLowerCase().includes(value.toLowerCase())
        );
        // return item.userName?.toLowerCase().includes(value.toLowerCase());
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const handleExport = (e: any) => {
    setLoading(false);
  };

  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(cafeteriaSalesList);
  };

  const labelContent = (e: any) => `${e.dataItem.percentage} %`;

  // Bar chart
  const [currentBarGraphData, setCurrentBarGraphData] = useState<
    Array<{ date: string; totalRevenue: number }>
  >([]);
  // Truncate long usernames for the x-axis labels
  const truncatedData = currentBarGraphData.map((item) => ({
    ...item,
    truncatedUserName:
      item.date.length > 20 ? `${item.date.substring(0, 15)}...` : item.date,
  }));
  // Current page Updation
  const pageSize = 5; // Number of bars to display per page
  const [barGraphStartIndex, setBarGraphStartIndex] = useState(0);

  // Update current bar graph data whenever cafeteriaBarChartList or start index changes
  useEffect(() => {
    setCurrentBarGraphData(
      cafeteriaBarChartList.slice(
        barGraphStartIndex,
        barGraphStartIndex + pageSize
      )
    );
  }, [barGraphStartIndex, cafeteriaBarChartList]);

  // Handle pagination for the bar chart
  const handleNextBarPage = () => {
    if (barGraphStartIndex + pageSize < cafeteriaBarChartList.length) {
      setBarGraphStartIndex(barGraphStartIndex + pageSize);
    }
  };

  const handlePreviousBarPage = () => {
    if (barGraphStartIndex > 0) {
      setBarGraphStartIndex(barGraphStartIndex - pageSize);
    }
  };

  const summaryView = () => {
    return (
      <div>
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName="Booking Summary Report"
        >
          {/* Count */}
          <div className="filter-container">
            <Label className="dashboard-header booking-card-header-align">
              Cafeteria Sales
            </Label>
          </div>
          {/* Chart */}
          {cafeteriaPieChartList.length > 0 ||
          cafeteriaBarChartList.length > 0 ? (
            <div>
              <div className="dashboard-chart">
                <div className="chart-container">
                  {/* Bar chart */}
                  <div className="chart-section">
                    <Label className="dashboard-header">
                      Food order revenue per day
                    </Label>
                    {cafeteriaBarChartList.length > 0 ? (
                      <>
                        <Chart transitions={false}>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={truncatedData.map(
                                (item) => item.truncatedUserName
                              )}
                              labels={{
                                rotation: "auto",
                                visual: (e) => {
                                  const text = e.createVisual();
                                  text.options.set("width", 80); // Set the width as needed
                                  text.options.set("wrap", "word"); // Enable wrapping
                                  return text;
                                },
                              }}
                            />
                          </ChartCategoryAxis>
                          <ChartSeries>
                            <ChartSeriesItem
                              color={barChartColor}
                              type="column"
                              data={truncatedData}
                              field="totalRevenue"
                              categoryField="date"
                              labels={{ visible: true }}
                            />
                          </ChartSeries>
                        </Chart>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            className="common-button-view clear-color"
                            onClick={handlePreviousBarPage}
                            disabled={barGraphStartIndex === 0}
                          >
                            Previous
                          </Button>
                          <Button
                            className="common-button-view search-color"
                            onClick={handleNextBarPage}
                            disabled={
                              barGraphStartIndex + pageSize >=
                              cafeteriaBarChartList.length
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Space Type data available
                      </div>
                    )}
                  </div>
                  {/* Pie chart */}
                  <div className="chart-section">
                    <Label className="dashboard-header">
                      Based on space type
                    </Label>
                    {cafeteriaPieChartList.length > 0 ? (
                      <Chart className="report-border" transitions={false}>
                        <ChartLegend position="bottom" />
                        <ChartSeries>
                          <ChartSeriesItem
                            color={commonChartColor}
                            type="pie"
                            field="percentage"
                            categoryField="spaceTypeName"
                            data={cafeteriaPieChartList}
                            labels={{ visible: true }}
                          >
                            <ChartSeriesLabels
                              color="#000"
                              background="none"
                              content={labelContent}
                            />
                          </ChartSeriesItem>
                        </ChartSeries>
                      </Chart>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Average Booking Duration data available
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard-chart report-top">
                <div className="chart-container report-chart"></div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              No summary view data available
            </div>
          )}
        </PDFExport>
      </div>
    );
  };

  const detailedView = () => {
    return (
      <div>
        {/* Checked-In and Cancelled Grid */}
        <div className="dashboard">
          <div className="report-booking-summary-container">
            <Label className="dashboard-header booking-card-header-align">
              Cafeteria Sales
            </Label>
            <div className="report-booking-summary-section ">
              <ExcelExport
                ref={_export}
                fileName="Food Order.xlsx"
                onExportComplete={(e) => handleExport(e)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1%",
                  alignItems: "center",
                }}
              >
                <Label className="dashboard-header">Food Order List</Label>
                <TextBox
                  className="textBoxcustom"
                  placeholder="Search using Customer Name & Space Type"
                  name="roomcost"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {searchTerm ? (
                          <Button icon="close" onClick={resetSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
              <Grid
                className={loading ? "k-disabled" : ""}
                data={process(filteredData, dataState)}
                pageable={getPageableSettings()}
                skip={dataState.skip}
                take={dataState.take}
                total={filteredData.length}
                onPageChange={(e) => setDataState(e.page)}
                onDataStateChange={handlePageChange}
                ref={_grid}
              >
                <GridColumn
                  field="serialNumber"
                  title="S.No."
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                />
                <GridColumn field="bookingId" title="Booking ID" />
                <GridColumn field="foodOrderNumber" title="Order ID" />
                <GridColumn field="orderStatus" title="Order Status" />
                <GridColumn
                  field="orderDate"
                  title="Order Date"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.orderDate}
                    </td>
                  )}
                />
                <GridColumn field="userType" title="Customer Type" />
                <GridColumn
                  field="userName"
                  title="Customer Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.userName}
                    </td>
                  )}
                />
                <GridColumn field="spaceTypeName" title="Space Type" />
                <GridColumn field="combineOrderType" title="Food Type" />
                <GridColumn
                  field="subcatName"
                  title="Food Name (Qty)"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.subcatName}
                    </td>
                  )}
                />
                <GridColumn
                  field="quantity"
                  title="Total Quantity"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "45px",
                      }}
                    >
                      {props.dataItem.quantity}
                    </td>
                  )}
                />
                <GridColumn
                  field="totalAmount"
                  title="Total Price"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.totalAmount}
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const RightAlignedHeaderCell = (props: any) => (
  //   <th
  //     style={{
  //       color: "#757575",
  //       fontWeight: "700",
  //       marginRight: "20px",
  //       textAlign: "right",
  //       paddingBottom: "0px",
  //     }}
  //     {...props}
  //   >
  //     {props.title}
  //   </th>
  // );

  // Auto complete
  const filterData = (value: string, flow: any) => {
    const data = flow === "category" ? categorySource : subCategorySource;
    const filter: FilterDescriptor = {
      value: value,
      operator: "contains",
      ignoreCase: true,
    };
    return value ? filterBy(data, filter) : data;
  };

  const onChange = (event: AutoCompleteChangeEvent) => {
    const { name } = event.target;
    if (event.value.length <= 25) {
      if (name === "category") {
        setCategoryState({
          data: filterData(event.value, "category"),
          value: event.value,
        });
        const cateId = categoryList.map((item) =>
          item["Name"] === event.value ? item["Id"] : 0
        );
        const nonZeroValues = cateId.filter((num) => num !== 0);
        if (nonZeroValues[0] > 0) {
          subCategoryAPI(nonZeroValues[0]);
          setOpenedCategory(false);
        } else {
          setSubCategoryState({ data: subCategorySource, value: "" });
          setSelectedSubCategoryId(0);
        }
        setSelectedCategoryId(
          nonZeroValues[0] === undefined ? 0 : nonZeroValues[0]
        );
      }

      if (name === "sub") {
        setSubCategoryState({
          data: filterData(event.value, "sub"),
          value: event.value,
        });
        const subId = subCategoryList.map((item) =>
          item["Name"] === event.value ? item["Id"] : 0
        );
        const nonZeroValues = subId.filter((num) => num !== 0);
        if (nonZeroValues[0] > 0) {
          setOpenedSubCategory(false);
        }
        setSelectedSubCategoryId(
          nonZeroValues[0] === undefined ? 0 : nonZeroValues[0]
        );
      }
    }
  };

  const handleFocus = (event: any) => {
    const { name } = event.target;
    if (name === "category") {
      if (categoryState.value === "") {
        setCategoryState({
          data: categorySource,
          value: "",
        });
      }
      setOpenedCategory(true);
    }
    if (name === "sub") {
      if (subCategoryState.value === "") {
        setSubCategoryState({ data: subCategorySource, value: "" });
      }
      setOpenedSubCategory(true);
    }
  };

  const handleBlur = (event: any) => {
    const { name } = event.target;
    if (name === "category") {
      setOpenedCategory(false);
    }
    if (name === "sub") {
      setOpenedSubCategory(false);
    }
  };

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <div>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                <div>
                  {selected === "summary" ? (
                    <div>
                      {(cafeteriaPieChartList.length > 0 ||
                        cafeteriaBarChartList.length > 0) && (
                        <Button
                          className="bio-device-button-view"
                          onClick={exportPDFWithComponent}
                        >
                          Download Summary
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {cafeteriaSalesList.length > 0 && (
                        <Button
                          className="bio-export-button-view"
                          svgIcon={exportIcon}
                          onClick={excelExport}
                        >
                          Export To CSV
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              {/* Filter */}
              <div className="common-margin">
                <div>
                  <Label className="list-heading">Filter</Label>
                  <div className=" filter-view ">
                    <div className="rowset">
                      <div className="columnset">
                        <Label className="common-label-size">
                          Booking From
                        </Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="fromDate"
                            className="book-dropdown"
                            onChange={onFilterChangefromdate}
                            value={bookingFrom}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset">
                        <Label className="common-label-size">Booking To</Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="toDate"
                            className="book-dropdown"
                            onChange={onFilterChangetodate}
                            min={bookingFrom!}
                            value={bookingTo}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">User Type</Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="userType"
                          className="book-dropdown"
                          textField="Name"
                          dataItemKey="Id"
                          data={userTypeList}
                          value={userType}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">
                          Order Status
                        </Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="orderStatus"
                          className="book-dropdown"
                          textField="name"
                          dataItemKey="id"
                          data={orderStatusList}
                          value={orderStatus}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">Category</Label>
                        <AutoComplete
                          name="category"
                          className="book-dropdown"
                          data={categoryState.data}
                          value={categoryState.value}
                          onChange={onChange}
                          placeholder="Category"
                          onFocus={(e) => handleFocus(e)}
                          onBlur={handleBlur}
                          opened={openedCategory}
                          style={{ width: "300px" }}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">
                          Sub Category
                        </Label>
                        <AutoComplete
                          name="sub"
                          className="book-dropdown"
                          data={subCategoryState.data}
                          value={subCategoryState.value}
                          onChange={onChange}
                          disabled={selectedCategoryId > 0 ? false : true}
                          onFocus={(e) => handleFocus(e)}
                          onBlur={(e) => handleBlur(e)}
                          opened={openedSubCategory}
                          placeholder="Sub Category"
                          style={{ width: "300px" }}
                        />
                      </div>
                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view search-color"
                          onClick={handleFilterChange}
                        >
                          Search
                        </Button>
                      </div>
                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view clear-color"
                          onClick={handleClearFilters}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Group Button */}
              <div className="button-toggle report-top">
                <ButtonGroup>
                  <Button
                    selected={selected === "summary"}
                    onClick={() => {
                      handleSelect("summary");
                    }}
                    className={
                      selected === "summary"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    svgIcon={chartPieIcon}
                    style={{ borderColor: "#42B677" }}
                  >
                    SUMMARY VIEW
                  </Button>
                  <Button
                    selected={selected === "detailed"}
                    onClick={() => handleSelect("detailed")}
                    svgIcon={listUnorderedIcon}
                    className={
                      selected === "detailed"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    style={{ borderColor: "#42B677" }}
                  >
                    DETAILED VIEW
                  </Button>
                </ButtonGroup>
              </div>
              {selected === "summary" ? summaryView() : detailedView()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
