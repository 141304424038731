import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, TextArea, TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios, { AxiosError } from "axios";
import { eyeIcon, pencilIcon, trashIcon } from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";

export const Amenities = () => {
  const baseURL = commonBaseUrl();
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = amenityList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Amenities",
      route: "",
    },
  ];
  const [amenityList, amenityListSet] = useState<any>([]);
  const [firsttime, setFirstTime] = useState(true);

  useEffect(() => {
    const AmenityApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Amenitys`);
        amenityListSet(response.data.value);
      } catch (err) {}
    };
    // setVisibleDialog(false);
    if (firsttime === true) {
      console.log("Called");
      AmenityApi();
      setFirstTime(false);
    }
  });
  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [newAmenity, newAmenitySet] = useState("");
  const [newAmenityDescription, newAmenityDescriptionSet] = useState("");
  const [newAmenityQty, newAmenityQtySet] = useState("");
  const [newAmenityDescriptionError, newAmenityDescriptionErrorSet] =
    useState("");
  const [newCheck, newCheckSet] = useState(false);
  const [newAmenityError, newAmenityErrorSet] = useState("");

  const addAmenity = () => {
    newCheckSet(true);
    if (newAmenity.trim() === "") {
      newAmenityErrorSet("Name must be provide");
    } else {
      newAmenityErrorSet("");
    }
    if (newAmenityDescription.trim() === "") {
      newAmenityDescriptionErrorSet("Description must be provide");
    } else {
      newAmenityDescriptionErrorSet("");
    }
    if (newAmenity.trim() !== "" && newAmenityDescription.trim() !== "") {
      let addAmenity = {
        Name: newAmenity,
        Description: newAmenityDescription,
      };
      newAmenityApi(addAmenity);
    }
  };
  const newAmenityApi = async (data: any) => {
    console.log(data, "Received data");
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(`${baseURL}odata/Amenitys`, data);
      console.log("Amenity Added", response.data);
      alertSet("Amenity added successfully");
      statusDialogSet(true);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };
  const clearInput = () => {
    newAmenitySet("");
    newAmenityDescriptionSet("");
    newCheckSet(false);
    newAmenityErrorSet("");
    newAmenityDescriptionErrorSet("");
  };

  const [amenityId, amenityIdSet] = useState(0);
  const [amenityName, amenityNameSet] = useState("");
  const [amenityDescription, amenityDescriptionSet] = useState("");
  const [amenityQty, amenityQtySet] = useState("");
  const [amenityError, amenityErrorSet] = useState("");
  const [amenitydescriptionError, amenitydescriptionErrorSet] = useState("");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [check, checkSet] = useState(false);

  const enableField = (id: any) => {
    // setEdit(true);
    // setEditID(id);
    amenityIdSet(id.Id);
    amenityNameSet(id.Name);
    amenityDescriptionSet(id.Description);
    console.log("EditEnableId", id, edit);
    console.log("Duration List", amenityList);
    setVisibleDialog(true);
  };

  const handleView = () => {
    checkSet(true);
    if (amenityName.trim() === "") {
      amenityErrorSet("Amenity Name cannot be empty");
    } else {
      amenityErrorSet("");
      //HANDLE THE API FUNCTIONALITY
    }
    if (amenityDescription.trim() === "") {
      amenitydescriptionErrorSet("Description cannot be empty");
    } else {
      amenitydescriptionErrorSet("");
      //HANDLE THE API FUNCTIONALITY
    }
    if (
      amenityError === "" &&
      amenitydescriptionError === "" &&
      amenityName.trim() !== "" &&
      amenityDescription.trim() !== ""
    ) {
      console.log("the Selected id is -->", amenityId);
      console.log("the Selected id is -->", amenityName);
      console.log("the Selected id is -->", amenityDescription);
      updateAmenity();
    }
  };
  const updateAmenity = async () => {
    let update = {
      Id: amenityId,
      Name: amenityName,
      Description: amenityDescription,
    };
    console.log("Recieved data -->", update);
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `${baseURL}odata/UpdateAmenity?key=${amenityId}`,
        update
      );
      console.log("Amenity Added", response.data);
      // window.location.reload();
      alertSet("Amenity updated successfully");
      statusDialogSet(true);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(amenityList);

  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      setFilteredData(
        amenityList.filter(
          (item: any) =>
            item.Name.toLowerCase().includes(lowerCaseTerm) ||
            item.Description.toLowerCase().includes(lowerCaseTerm)
        )
      );
      setDataState(initialDataState);
    } else {
      setFilteredData(amenityList);
    }
  }, [searchTerm, amenityList]);

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");

    setFilteredData(amenityList); // Reset to original data
  };
  return (
    <div className="overall-view ">
      {visibleDialog == true ? (
        <Dialog
          height={400}
          onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "350px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Edit Amenity</h1>
              <div>
                <div className="dialog-item">
                  <div>
                    Name<span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={amenityName}
                    required={check}
                    maxLength={25}
                    onChange={(e) => {
                      amenityNameSet(e.value);
                      if (amenityName.trim() !== "") {
                        amenityErrorSet("");
                      }
                      console.log("Changed duration name", e.value);
                    }}
                  />
                  {/* <div>{durationName}</div> */}
                </div>
                {amenityError && <Error>{amenityError}</Error>}
              </div>
              <div>
                <div className="dialog-item">
                  <div>
                    Description <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <TextArea
                    value={amenityDescription}
                    required={check}
                    maxLength={500}
                    onChange={(e) => {
                      amenityDescriptionSet(e.value);
                      if (amenityDescription.trim() !== "") {
                        amenitydescriptionErrorSet("");
                      }
                      console.log("Changed amenity name", e.value);
                    }}
                  />
                  {/* <div>{durationName}</div> */}
                </div>
                {amenitydescriptionError && (
                  <Error>{amenitydescriptionError}</Error>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={handleView}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                    UPDATE
                  </Button>
                  <div style={{ padding: "10px" }} />
                  {/* </div> */}
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={(e) => {
                      setVisibleDialog(false);
                      amenityErrorSet("");
                      amenitydescriptionErrorSet("");
                    }}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    CANCEL
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div> </div>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div className="custom-inputs-container">
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Name of the Amenity <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="book-height"
                type="text"
                value={newAmenity}
                maxLength={25}
                required={newCheck}
                onChange={(e) => {
                  newAmenitySet(e.value);
                  if (newAmenity.trim() !== "") {
                    newAmenityErrorSet("");
                    // newCheckSet(false);
                  }
                }}
                placeholder="Enter name of the amenity"
              />
              {newAmenityError && <Error>{newAmenityError}</Error>}
            </div>
            <div style={{ width: "40px" }}> </div>
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Description <span style={{ color: "red" }}>*</span>
              </Label>
              <TextArea
                className="book-height"
                value={newAmenityDescription}
                maxLength={500}
                required={newCheck}
                onChange={(e) => {
                  newAmenityDescriptionSet(e.value);
                  if (newAmenityDescription.trim() !== "") {
                    newAmenityDescriptionErrorSet("");
                    // newCheckSet(false);
                  }
                }}
                placeholder="Enter Description"
              />
              {newAmenityDescriptionError && (
                <Error>{newAmenityDescriptionError}</Error>
              )}
            </div>
            <div
              className="custom-inputs-view common-label-padding"
              style={{ marginTop: "2%" }}
            >
              <Button className="button-view " onClick={addAmenity}>
                Create
              </Button>
              <Button className="common-button-view" onClick={clearInput}>
                Clear
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "1%",
              marginTop: "5%",
              alignItems: "center",
            }}
          >
            <Label className="list-heading">List of Amenities</Label>

            <TextBox
              className="textBoxcustom"
              placeholder="Search using any keywords"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchTerm ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handleDataStateChange}
            >
              <GridColumn
                field="Id"
                title="S.No."
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "40px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="Name"
                title="Amenity Name"
                cell={(props) => <td>{props.dataItem.Name}</td>}
              />
              <GridColumn
                field="Description"
                title="Description"
                cell={(props) => <td>{props.dataItem.Description}</td>}
              />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-color"
                      onClick={() => {
                        enableField(props.dataItem);
                        console.log("Selected Data", props.dataItem);
                      }}
                      svgIcon={pencilIcon}
                    />
                    {/* <Button
                      className="delete-color"
                      //    onClick={() => setDeleteID(props.dataItem.Id)}
                      svgIcon={trashIcon}
                    /> */}
                  </td>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
