import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn,
  GridDetailRowProps,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { Input, TextBox, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  chartPieIcon,
  chevronDownIcon,
  exportIcon,
  listUnorderedIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import Breadcrumbs from "../components/Breadcrumbs";
import { useEffect, useRef, useState } from "react";
import { process, State } from "@progress/kendo-data-query";
import {
  commonBaseUrl,
  commonChartColor,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
import {
  CenturyViewService,
  DatePicker,
} from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { Column } from "jspdf-autotable";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { Loader } from "@progress/kendo-react-indicators";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

const PackageUtilization = () => {
  interface FoodDetail {
    foodName: string;
    packageQuantity: number;
    consumedQuantity: number;
    remainingQuantity: number;
  }

  interface BookingItem {
    bookingID: string;
    userName: string;
    customerType: string;
    orderID: number;
    packageType: string;
    spaceType: string;
    foodName: string;
    packageQuantity: number;
    consumedQuantity: number;
    remainingQuantity: number;
    // expanded: boolean;
  }
  const transformedData: any = [];

  const defaultRoomTypeValue = {
    Id: 0,
    Name: "Select Space Type",
  };
  const defaultPackageType = {
    Id: 0,
    PackageName: "Select Package Type",
  };
  const defaultUserTypeValue = {
    Id: 0,
    Name: "Select User Type",
  };
  const [roomType, setRoomType] = useState(defaultRoomTypeValue);
  const [packageType, setPackageType] = useState(defaultPackageType);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [packageTypeList, packageTypeListSet] = useState([]);
  const [userType, setUserType] = useState(defaultUserTypeValue);
  const [bookingFrom, setBookingFrom] = useState<Date | null>(null);
  const [bookingTo, setBookingTo] = useState<Date | null>(null);
  const [roomTypeId, setRoomTypeId] = useState(0);
  const [userTypeId, setUserTypeId] = useState(0);
  const [packageTypeId, setPackageTypeId] = useState(0);
  const [statusDialog, statusDialogSet] = useState(false);
  const _grid = useRef<any>();
  const [alert, alertSet] = useState("");
  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "Package Utilization Reports",
      route: "",
    },
  ];
  const baseURL = commonBaseUrl();
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    let len = filteredData.length;
    const length = len;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  const [searchTerm, setSearchTerm] = useState("");

  const [packageutilize, packageutilizeSet] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      setFilteredData(
        packageutilize.filter(
          (item: any) =>
            item.bookingID.toLowerCase().includes(lowerCaseTerm) ||
            item.userName.toLowerCase().includes(lowerCaseTerm)
        )
      );
      setDataState(initialDataState);
    } else {
      setFilteredData(packageutilize);
    }
  }, [searchTerm, packageutilize]);

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(packageutilize);
  };
  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  // CSS styling for the components
  const styles = `
  .no-wheel input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Hide spinner controls */
    pointer-events: none;
    user-select: none;
  }
  
  .no-wheel input::-webkit-inner-spin-button,
  .no-wheel input::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  .no-wheel input::-moz-inner-spin-button {
    display: none;
  }
  `;
  const [visibleDialog, visibleDialogSet] = useState(false);
  const [dataItem, dataItemSet] = useState<any>({});
  useEffect(() => {
    console.log("The updated data item was -->", dataItem.consume);
  }, [dataItem]);
  // Initial API call
  const getRoomType = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/SpaceTypes`);
      setRoomTypeList(response.data.value);
      getPackageType();
    } catch (err) {
      // alert(err);
    }
  };
  const getPackageType = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/PackageDetails`);

      packageTypeListSet(response.data.value);
    } catch (err) {
      // alert(err);
    }
  };
  const onFilterDropDown = (ev: any) => {
    const { name, value } = ev.target;

    if (name === "roomType") {
      setRoomType(value);
      setRoomTypeId(value.Id);
    }

    if (name === "userType") {
      setUserType(value);
      setUserTypeId(value.Id);
    }

    if (name === "packageType") {
      setPackageType(value);
      setPackageTypeId(value.Id);
    }
  };
  const userTypeList = [
    {
      Id: 1,
      Name: "Company",
    },
    {
      Id: 2,
      Name: "Individual",
    },
  ];
  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    // Function to handle mouse wheel events
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault(); // Prevent scrolling with mouse wheel
    };

    // Function to handle keyboard events
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener to prevent scrolling with mouse wheel
      element.addEventListener("wheel", handleWheel, { passive: false });

      const inputElement = element.querySelector("input");

      if (inputElement) {
        // Attach keydown event listener to prevent up/down arrows and numbers
        inputElement.addEventListener("keydown", handleKeyDown);
      }

      // Cleanup event listeners when component unmounts
      return () => {
        element.removeEventListener("wheel", handleWheel);
        if (inputElement) {
          inputElement.removeEventListener("keydown", handleKeyDown);
        }
      };
    }
  }, []);
  // Filter flow
  // From Date filter
  const onFilterChangefromdate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      const selectedFromDate = ev.value;
      setBookingFrom(selectedFromDate);
      if (bookingTo && new Date(bookingTo) < new Date(selectedFromDate)) {
        setBookingTo(selectedFromDate);
      }
    }
  };

  // To Date filter
  const onFilterChangetodate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setBookingTo(ev.value);
    }
  };

  const handleFilterChange = () => {
    UtilizationApi(
      roomTypeId,
      userTypeId,
      packageTypeId,
      formatDate(bookingFrom),
      formatDate(bookingTo)
    );
    SummaryApi(
      roomTypeId,
      userTypeId,
      packageTypeId,
      formatDate(bookingFrom),
      formatDate(bookingTo)
    );
  };

  const handleClearFilters = () => {
    setRoomType(defaultRoomTypeValue);
    setUserType(defaultUserTypeValue);
    setPackageType(defaultPackageType);
    setRoomTypeId(0);
    setUserTypeId(0);
    setPackageTypeId(0);
    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    UtilizationApi(
      0,
      0,
      0,
      formatDate(getStartDate()),
      formatDate(getEndDate())
    );
    SummaryApi(0, 0, 0, formatDate(getStartDate()), formatDate(getEndDate()));
  };
  const hasFetched = useRef(false);

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    const startDate = formatDate(getStartDate());
    const endDate = formatDate(getEndDate());
    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    getRoomType();
    // getPackageType();
    UtilizationApi(roomTypeId, userTypeId, packageTypeId, startDate, endDate);
    SummaryApi(roomTypeId, userTypeId, packageTypeId, startDate, endDate);
  });

  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };

  // Function to format the date string DD-MMM-YYYY hh:mm a
  const customDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = String(hours).padStart(2, "0");

    return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Function to format date to YYYY-MM-DD
  const formatDate = (date: any) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getStartDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getEndDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  };
  const labelContent = (e: any) => `${e.dataItem.percentage} %`;

  const UtilizationApi = async (
    roomTypeId: any,
    userTypeId: any,
    packageTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let spaceURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerType=${userTypeId}`;
    let packageURL =
      packageTypeId === 0 ? "" : `&packageTypeId=${packageTypeId}`;
    fullURL = `${baseURL}odata/GetPackageUtilizationDetail?startDate=${startDate}&endDate=${endDate}${packageURL}${spaceURL}${userURL}`;
    console.log("Full Url -->", fullURL);
    try {
      const response = await axios.get(fullURL);
      // setPackageUtilize(response.data);
      console.log("The response was ==> ", response.data);
      let alteredData = response.data;
      // let alteredData = tempData;
      // for(let i=0;i<alteredData.length;i++){
      //   alteredData[i].expanded = true;
      // }
      alteredData.forEach((item: any, idx: any) => {
        item.foodDetails.forEach((foodDetail: any) => {
          console.log("Food Details -->", item);
          transformedData.push({
            bookingID:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.bookingID
                : "",
            userName:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.userName
                : "",
            customerType:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.customerType
                : "",
            orderID:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.orderID
                : "",
            packageType:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.packageType
                : "",
            spaceType:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.spaceType
                : "",
            foodName: foodDetail.foodName,
            packageQuantity: foodDetail.packageQuantity,
            consumedQuantity: foodDetail.consumedQuantity,
            remainingQuantity: foodDetail.remainingQuantity,
            sno:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? idx + 1
                : "",
            utilizedDate:
              item.foodDetails[0].foodName === foodDetail.foodName
                ? item.createdOn
                : "",
            // expanded: true,
          });
        });
      });
      console.log("The data after forEach -->", transformedData);
      setFilteredData(transformedData);
      packageutilizeSet(transformedData);
      // getCurrentMonthActiveDetails(roomTypeId, userTypeId, startDate, endDate);
    } catch (err) {
      // alert(err);
    }
  };
  const [summaryList, summaryListSet] = useState([]);
  const SummaryApi = async (
    roomTypeId: any,
    userTypeId: any,
    packageTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let spaceURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerType=${userTypeId}`;
    let packageURL =
      packageTypeId === 0 ? "" : `&packageTypeId=${packageTypeId}`;
    fullURL = `${baseURL}odata/GetPackageUtilizationSummary?startDate=${startDate}&endDate=${endDate}${packageURL}${spaceURL}${userURL}`;
    console.log("Full Url -->", fullURL);
    try {
      const response = await axios.get(fullURL);
      console.log("Response -->", response.data);
      summaryListSet(response.data);
    } catch (err) {
      // alert(err);
    }
  };
  const [selected, setSelected] = useState<string>("summary");
  const [loading, setLoading] = useState(false);

  const pdfExportComponent = useRef<PDFExport>(null);

  const summaryView = () => {
    return (
      <div>
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName="Utilized Package Summary Report"
        >
          {/* Chart */}
          {summaryList.length > 0 ? (
            <div>
              <div className="dashboard-chart">
                <div
                  className="chart-container"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* Pie chart */}
                  <div className="chart-section">
                    <Label
                      className="dashboard-header"
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      Utilized Package Summary
                    </Label>
                    {summaryList.length > 0 ? (
                      <Chart className="report-border" transitions={false}>
                        <ChartLegend position="bottom" />
                        <ChartSeries>
                          <ChartSeriesItem
                            color={commonChartColor}
                            type="pie"
                            field="percentage"
                            categoryField="packageName"
                            data={summaryList}
                            labels={{ visible: true }}
                          >
                            <ChartSeriesLabels
                              color="#000"
                              background="none"
                              content={labelContent}
                            />
                          </ChartSeriesItem>
                        </ChartSeries>
                      </Chart>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Average Booking Duration data available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              No summary view data available
            </div>
          )}
        </PDFExport>
      </div>
    );
  };
  const _export = useRef<ExcelExport | null>(null);
  const handleExport = (e: any) => {
    setLoading(false);
  };
  const detailedView = () => {
    return (
      <div>
        {/* Checked-In and Cancelled Grid */}
        <div className="dashboard">
          <div className="report-booking-summary-container">
            <div className="report-booking-summary-section ">
              <ExcelExport
                ref={_export}
                fileName="Package Utilization.xlsx"
                onExportComplete={(e) => handleExport(e)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1%",
                  alignItems: "center",
                }}
              >
                <Label className="dashboard-header">
                  List of Utilized Packages
                </Label>
                <TextBox
                  className="textBoxcustom"
                  placeholder="Search using Booking ID & Customer Name"
                  name="roomcost"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {searchTerm ? (
                          <Button icon="close" onClick={resetSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
              <Grid
                className={loading ? "k-disabled" : ""}
                data={process(filteredData, dataState)}
                pageable={getPageableSettings()}
                skip={dataState.skip}
                take={dataState.take}
                total={filteredData.length}
                onPageChange={(e) => setDataState(e.page)}
                onDataStateChange={handlePageChange}
                ref={_grid}
              >
                <GridColumn
                  field="sno"
                  title="S.No."
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                />
                <GridColumn field="bookingID" title="Booking ID" />
                <GridColumn field="orderID" title="Order ID" />
                <GridColumn
                  field="utilizedDate"
                  title="Package Utilized Date"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.utilizedDate}
                    </td>
                  )}
                />
                <GridColumn
                  field="userName"
                  title="Customer Name"
                  cell={(props) => <td>{props.dataItem.userName}</td>}
                />
                <GridColumn field="customerType" title="Customer Type" />
                <GridColumn field="spaceType" title="Space Type" />
                <GridColumn field="packageType" title="Package Type" />
                <GridColumn
                  field="foodName"
                  title="Food Name"
                  width={180}
                  cell={(props) => <td>{props.dataItem.foodName}</td>}
                />
                <GridColumn
                  field="packageQuantity"
                  title="Package Quantity"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.packageQuantity}
                    </td>
                  )}
                />
                <GridColumn
                  field="consumedQuantity"
                  title="Consumed Quantity"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.consumedQuantity}
                    </td>
                  )}
                />
                <GridColumn
                  field="remainingQuantity"
                  title="Remaining Quantity"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.remainingQuantity}
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportPDFWithComponent = () => {
    setLoading(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setLoading(false);
    }
  };
  const excelExport = () => {
    setLoading(true);
    if (_export.current !== null) {
      _export.current.save(filteredData, _grid.current.columns);
    }
  };

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <div className="overall-view ">
          {visibleDialog == true ? (
            <Dialog
              height={400}
              onClose={() => visibleDialogSet(false)}
              className="dialog-containers"
            >
              <div
                className="dilog-content-size"
                style={{
                  width: "350px",
                  height: "350px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontSize: "15px",
                }}
              >
                <div className="dialog-content">
                  <h1>Edit Quantities</h1>
                  <div>
                    <div className="dialog-item">
                      <div>
                        Description <span style={{ color: "red" }}>*</span>
                      </div>
                    </div>
                    <div className="dialog-item">
                      <Input value={dataItem.qty} maxLength={25} />
                    </div>
                  </div>
                  <div>
                    <div className="dialog-item">
                      <div>Quantity</div>
                    </div>
                    <div className="dialog-item">
                      <Input
                        type="number"
                        maxLength={25}
                        onChange={(e) => {
                          console.log("Changed amenity qty", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          // marginRight: "20px",
                          backgroundColor: "#42B677",
                          padding: "10px",
                          color: "#fff",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        UPDATE
                      </Button>
                      <div style={{ padding: "10px" }} />
                      <Button
                        onClick={(e) => {
                          visibleDialogSet(false);
                          //   amenityErrorSet("");
                          //   amenitydescriptionErrorSet("");
                        }}
                        style={{
                          // marginRight: "20px",
                          backgroundColor: "#ED823A",
                          padding: "10px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          color: "#fff",
                        }}
                      >
                        CANCEL
                      </Button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          ) : (
            <div> </div>
          )}
          {statusDialog && (
            <Dialog>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <h3>{alert}</h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "30px",
                  }}
                >
                  <Button
                    className="button-view"
                    onClick={() => window.location.reload()}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </Dialog>
          )}
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                <div>
                  {selected === "summary" ? (
                    <div>
                      {summaryList.length > 0 && (
                        <Button
                          className="bio-device-button-view"
                          onClick={exportPDFWithComponent}
                        >
                          Download Summary
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {filteredData.length > 0 && (
                        <Button
                          className="bio-export-button-view"
                          svgIcon={exportIcon}
                          onClick={excelExport}
                        >
                          Export To CSV
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin">
              <div className="common-margin">
                <div>
                  <Label className="list-heading">Filter</Label>
                  <div className=" filter-view ">
                    <div className="rowset">
                      <div className="columnset">
                        <Label className="common-label-size">From Date</Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="fromDate"
                            className="book-dropdowns"
                            onChange={onFilterChangefromdate}
                            value={bookingFrom}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset">
                        <Label className="common-label-size">To Date</Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="toDate"
                            className="book-dropdowns"
                            onChange={onFilterChangetodate}
                            min={bookingFrom!}
                            value={bookingTo}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset">
                        <Label className="common-label-size">Space Type</Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="roomType"
                          className="book-dropdowns"
                          textField="Name"
                          dataItemKey="Id"
                          data={roomTypeList}
                          value={roomType}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">User Type</Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="userType"
                          className="book-dropdowns"
                          textField="Name"
                          dataItemKey="Id"
                          data={userTypeList}
                          value={userType}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">
                          Package Type
                        </Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="packageType"
                          className="book-dropdowns"
                          textField="PackageName"
                          dataItemKey="Id"
                          data={packageTypeList}
                          value={packageType}
                          onChange={onFilterDropDown}
                        />
                      </div>

                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view search-color"
                          onClick={handleFilterChange}
                        >
                          Search
                        </Button>
                      </div>
                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view clear-color"
                          onClick={handleClearFilters}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-toggle report-top">
                <ButtonGroup>
                  <Button
                    selected={selected === "summary"}
                    onClick={() => {
                      setSelected("summary");
                    }}
                    className={
                      selected === "summary"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    svgIcon={chartPieIcon}
                    style={{ borderColor: "#42B677" }}
                  >
                    SUMMARY VIEW
                  </Button>
                  <Button
                    selected={selected === "detailed"}
                    onClick={() => setSelected("detailed")}
                    svgIcon={listUnorderedIcon}
                    className={
                      selected === "detailed"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    style={{ borderColor: "#42B677" }}
                  >
                    DETAILED VIEW
                  </Button>
                </ButtonGroup>
              </div>
              <Label className="dashboard-header booking-card-header-align">
                Package Utilization
              </Label>
              {selected === "summary" ? summaryView() : detailedView()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageUtilization;
