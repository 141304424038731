export const getBaseUrl = () => {
  let url = process.env.REACT_APP_BASE_URL;
  return url;
};

export const getStorageBaseUrl = () => {
  var url = process.env.REACT_APP_STORAGE_API_URL;
  return url;
};

export const config = {
  baseURL: getBaseUrl(),
  version: "1.0.0",
  customerID: 1,
};

export const storageBaseURL = getStorageBaseUrl();

export default config;
