import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "../cafeteria/Orders.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import { useEffect, useState } from "react";
import axios from "axios";
import { commonBaseUrl, RightAlignedHeaderCell } from "../../services/CommonUtil";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { send } from "process";

const ADJUST_PADDING: number = 4;
const COLUMN_MIN: number = 4;
const Orders = () => {
  const baseURL = commonBaseUrl();
  // Example data for active orders
  const ordersData = [
    {
      orderNo: "001",
      status: "New",
      items: "2 Tea, 1 Water Bottle",
      customer: "ABC Corp",
    },
    {
      orderNo: "002",
      status: "In Progress",
      items: "2 Coffee",
      customer: "XYZ Corp",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelect = (e: any) => {
    console.log("Selected value", e.selected);
    let value = e.selected + 1;
    if (e.selected === 2) {
      value = 4;
    }
    if (e.selected === 3) {
      value = 3;
    }
    setSelectedTab(e.selected);
    console.log("Selected status value", value);
    statusIdSet(value);
  };
  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Orders",
      //   route: "",
    },
  ];
  const [statusId, statusIdSet] = useState(1);
  const [newList, newListSet] = useState([]);
  const [progressList, progressListSet] = useState([]);
  const [completeList, completeListSet] = useState([]);
  const [cancelList, cancelListSet] = useState([]);

  useEffect(() => {
    console.log("Status id", statusId);
    GetOrderList(statusId);
  }, [statusId]);
  const GetOrderList = async (id: any) => {
    // console.log("The updated time is ==>", fromDate , "==>", toDate);
    loadingSet(true);
    console.log(`${baseURL}odata/GetAllFoodOrderListByStatusId?statusId=${id}`);
    try {
      const response = await axios.get(
        `${baseURL}GetAllFoodOrderListByStatusId?statusId=${id}`
      );
      console.log("Fetched Data 11", response.data);
      let list = response.data;
      console.log("The updated list was", list);
      for (let i = 0; i < list.length; i++) {
        list[i].sno = i + 1;
      }
      if (id === 1) {
        newListSet(list);
      } else if (id === 2) {
        progressListSet(list);
      } else if (id === 3) {
        cancelListSet(list);
      } else {
        completeListSet(list);
      }
      loadingSet(false);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      loadingSet(false);
    }
  };
  const packageUpdateApi = async (id: any) => {
    console.log(" the package id was -->", id);
    console.log(`${baseURL}odata/UpdateUtilizedQuantitiesForCanceledOrder?orderId=${id}`);
    try {
      const response = await axios.post(`${baseURL}odata/UpdateUtilizedQuantitiesForCanceledOrder?orderId=${id}`);
      console.log("Package cancelled Data -->", response);
      console.log("Package cancelled Data -->", response.data);
      window.location.reload();
    } catch (err: any) {
      console.log("error", err);
      window.location.reload();
    }
  };
  const CustomItemsOrderedCell = (props: any) => {
    const { itemsOrdered } = props.dataItem;

    // Concatenating the itemsOrdered into a single string
    const formattedItems = itemsOrdered
      .map((item: any) => `${item.quantity} * ${item.itemName}`)
      .join(", ");

    return <td>{formattedItems}</td>;
  };

  const updateStatusApi = async (id: any, status: any) => {
    console.log("Status", status, id);
    if(status === 3){
      //New Cancel Api
      try {
        const cancelResponse = await axios.post(`${baseURL}odata/CancelFoodOrderStatus?orderId=${id}`);
        console.log("Data Cancelled", cancelResponse.data);
        packageUpdateApi(id);
        GetOrderList(statusId);
      } catch (error) {
        console.log("Error cancel food order -->", error);
      }
    }else{
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateFoodOrderStatus?foodId=${id}&statusId=${status}`
      );
      console.log("Fetched Data", response.data);
      GetOrderList(statusId);
      // window.location.reload();
    } catch (err: any) {
      console.log("error was -->", err.response.data.detail);
    }
  }
  };

  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };

  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const CustomDateOnlyCell = (props: any) => {
    const { field, dataItem } = props;
    return <td style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "50px",
                      }}
                    >{customDateOnly(dataItem[field])}</td>;
  };
  const [loading, loadingSet] = useState(false);
  const [cancel, cancelSet] =  useState(false);
  const [selectedId,selectedIdSet] = useState(0);
  const [sendId, sendIdSet] = useState(0);
  const [alert, alertSet] = useState("");
  const [visible, visibleSet] = useState(false);
  const checkingMethod = async(ID:any)=>{
    cancelSet(false);
    console.log("Cancel order data set was", selectedId,sendId);
    try {
      const response = await axios.get(
        `${baseURL}odata/FoodOrderHeaders/${ID}`
      );
      // console.log("Response -->",response.data.value[0]);
      var Status = response.data.value[0].OrderStatus;
      // return Status;

      if(statusId === Status){
        console.log("Condition True");
        updateStatusApi(selectedId, sendId);
      } else if(Status === 3){
        alertSet("Your Order was already Cancelled by the user");
        visibleSet(true);
      }

      // if(Status === 1){
      //   // console.log("yes",data.Id);
      //   // updateStatusApi(selectedId, sendId);
      // }else if(Status === 2){
      //   // alertSet("Already Your Order was taken by Cafeteria");
      //   console.log("Already Your Order was taken by Cafeteria",selectedId,sendId);
      //   updateStatusApi(selectedId, sendId);
      //   visibleSet(true);
      // }else if(Status === 4){
      //   alertSet("Your Order was Completed");
      //   visibleSet(true);
      // }else{
      //   alertSet("Already Your Order was cancelled by Cafeteria");
      //   console.log("Status cancel -->",Status);
      //   // visibleSet(true);
      //   // updateStatusApi(selectedId, sendId);
      // }
    } catch (error) {
      
    }
  }

  const updateDialog = ()=>{
    visibleSet(false);
  }

  const handleAccept =(status:any, dataID:any, stateID:any)=>{
    console.log("Status Data Send", status, dataID, stateID);
    cancelSet(true);
  };

  const [verifyId, verifyIdSet] = useState(0); 
  return (
    <div className="orders-container">
      {visible && (
              <Dialog title={"Message"} onClose={updateDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                  {alert}
                  {/* Booking Details Updated Successfully */}
                </p>
                <DialogActionsBar>
                  <Button type="button" onClick={updateDialog}>
                    Ok
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
      {cancel && (
              <Dialog title={"Message"} onClose={()=> cancelSet(false)}>
              <p style={{ margin: "25px", textAlign: "center" }}>
                Are you sure want to {sendId === 2 ? "take": sendId===3? "cancel" : "complete"} this order ?
                {/* Booking Details Updated Successfully */}
              </p>
              <DialogActionsBar>
                <Button type="button" onClick={(e)=>{
                  e.preventDefault();
                  console.log("Status Data Send", statusId, selectedId, sendId);
                  checkingMethod(selectedId);
                  // updateStatusApi(selectedId,sendIdSet);
                }}>
                  Ok
                </Button>
              </DialogActionsBar>
            </Dialog>
            )}
      {loading 
      ? (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )
      : (<div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
      <Breadcrumbs breadCrumbData={breadCrumbsData} />
      </div>
      <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
        <TabStripTab title={`New`}>
          <Grid data={newList}>
            <GridColumn field="sno" title="S.No"
             headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "50px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}/>
            <GridColumn field="foodOrderNumber" title="Order No"/>
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer Name"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn
              field="orderDate"
              title="Order Date"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => <CustomDateOnlyCell {...props} />}
            />
            <GridColumn field="totalAmount" title="Total Amount"
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "70px",
                      }}
                    >
                      {props.dataItem.totalAmount}
                    </td>
                  )} />
            <GridColumn
              field="action"
              title="Actions"
              width={180}
              cell={(props) => (
                <td>
                  <div style={{display:"flex"}}>
                    <Button
                      className="edit-color"
                      onClick={() => {
                        // GetOrderList(1);
                        //StatusID - DataID - SendID
                        handleAccept(statusId,props.dataItem.id, 2);
                        selectedIdSet(props.dataItem.id);
                        sendIdSet(2);
                        // updateStatusApi(props.dataItem.id, 2);
                      }}
                    >Accept</Button>
                    <Button
                      className="wrong-color"
                      onClick={() => {
                        // GetOrderList(1);
                        selectedIdSet(props.dataItem.id);
                        sendIdSet(3);
                        handleAccept(statusId,props.dataItem.id, 3);
                        // updateStatusApi(props.dataItem.id, 3);
                      }}
                    >Cancel</Button>
                  </div>
                </td>
              )}
            />
          </Grid>
        </TabStripTab>
        <TabStripTab title={`In-Progress`}>
          <Grid data={progressList}>
            <GridColumn field="sno" title="S.No"
             headerCell={RightAlignedHeaderCell}
             cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )} />
            <GridColumn field="foodOrderNumber" title="Order No" />
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer Name"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn
              field="orderDate"
              title="Order Date"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => <CustomDateOnlyCell {...props} />}
            />
            <GridColumn field="totalAmount" title="Total Amount"  
            headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "70px",
                      }}
                    >
                      {props.dataItem.totalAmount}
                    </td>
                  )}/>
            {/* <GridColumn field="orderStatus" title="Status" /> */}
            <GridColumn
              field="action"
              title="Actions"
              cell={(props) => (
                <td>
                  <div>
                    <Button
                      className="edit-color"
                      onClick={(e) => {
                        // GetOrderList(2);
                        e.preventDefault();
                        selectedIdSet(props.dataItem.id);
                        sendIdSet(4);
                        handleAccept(statusId,props.dataItem.id, 4);
                        // updateStatusApi(props.dataItem.id, 4);
                      }}
                    >Completed</Button>
                  </div>
                </td>
              )}
            />
          </Grid>
        </TabStripTab>
        <TabStripTab title={`Completed`}>
          <Grid data={completeList}>
            <GridColumn field="sno" title="S.No" headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "70px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}/>
            <GridColumn field="foodOrderNumber" title="Order No" />
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer Name"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn
              field="orderDate"
              title="Order Date"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => <CustomDateOnlyCell {...props} />}
            />
            <GridColumn field="orderStatus" title="Status" />
            <GridColumn field="totalAmount" title="Total Amount" 
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "70px",
                }}
              >
                {props.dataItem.totalAmount}
              </td>
            )}
            />
          </Grid>
        </TabStripTab>
        <TabStripTab title={`Cancelled`}>
          <Grid data={cancelList}>
            <GridColumn field="sno" title="S.no"
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "35px",
                }}
              >
                {props.dataIndex + 1}
              </td>
            )} />
            <GridColumn field="foodOrderNumber" title="Order No" />
            <GridColumn field="itemsOrdered" title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer Name"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn
              field="orderDate"
              title="Order Date"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => <CustomDateOnlyCell {...props} />}
            />
            <GridColumn field="orderStatus" title="Status" />
            <GridColumn field="totalAmount" title="Total Amount" 
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "70px",
                }}
              >
                {props.dataItem.totalAmount}
              </td>
            )} />
          </Grid>
        </TabStripTab>
        </TabStrip>
    </div>)}
    </div>
  );
};

export default Orders;
