import Api from "../Api";
import config from "../Config";
import axios from "axios";
import { commonBaseUrl } from "../CommonUtil";

export const samplePostAPI = (params) => {
  return Api.post("/example/usrs", params);
};

export const sampleGetAPI = () => {
  const header = {
    accept: "application/json;odata.metadata=minimal;odata.streaming=true",
  };
  return Api.get("/odata/SpaceTypes", { headers: header });
};

export const apiAuthenticateLogin = (headers) => {
  const baseURL = commonBaseUrl();
  // const headers = {
  //   accept: "application/json;odata.metadata=minimal;odata.streaming=true",
  //   // accept: "*/*",
  //   // "content-type": "*",
  //   // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  // };
  // const instance = axios.create();
  // // let url = "https://uat-cw-api.equadriga.in/"; //config.baseURL;
  // let url = "https://uat-wfh-api.equadriga.in/"; //config.baseURL;

  // return instance.post(
  //   url + "login?userName=" + userName + "&password=" + password
  // );
  // return instance.post(url + "login", params);
  return Api.post(
    `${baseURL}Login`,
    {},
    {
      headers: headers,
    }
  );
};
