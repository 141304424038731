import React, { useState, useEffect } from "react";
import {
  Input,
  InputPrefix,
  TextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { chevronDownIcon } from "@progress/kendo-svg-icons";
import "../css/EditInfo.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import { Error, Label } from "@progress/kendo-react-labels";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { commonBaseUrl } from "../../services/CommonUtil";

const baseURL = commonBaseUrl();

// Convert selected time format
const convertTimeFormat = (currentTime: any) => {
  const localDate1 = new Date(currentTime);

  // Get the local date-time components
  const localYear = localDate1.getFullYear();
  const localMonth = String(localDate1.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const localDay = String(localDate1.getDate()).padStart(2, "0");
  const localHours = String(localDate1.getHours()).padStart(2, "0");
  const localMinutes = String(localDate1.getMinutes()).padStart(2, "0");
  const localSeconds = String(localDate1.getSeconds()).padStart(2, "0");

  // Construct the local date-time string in your desired format
  const localDateTime = `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;

  // Convert the local time string to a Date object
  const [datePart, timePart] = localDateTime.split(" ");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  // Create a new Date object in the local timezone
  const localDate = new Date(year, month - 1, day, hours, minutes, seconds);

  // Convert the local Date object to an ISO string (UTC)
  const globalDateTime = localDate.toISOString();

  // Create a Date object from the ISO string
  const date = new Date(globalDateTime);

  // Extract the hours, minutes, and seconds
  const hours1 = String(date.getUTCHours()).padStart(2, "0");
  const minutes1 = String(date.getUTCMinutes()).padStart(2, "0");

  // Combine them into a time string
  const timeOnly = `${hours1}:${minutes1}`;

  return timeOnly;
};

export const ViewBookingDetails = () => {
  const navigate = useNavigate();
  const { state: { infoId, view } = {} } = useLocation();
  const [views, setViews] = useState<boolean>(view);
  var documents;
  const [editFormData, setEditFormData] = useState(infoId);
  const formatMomentTime = (dateStr: string, dateTimeFormat: string) => {
    return moment(dateStr).format(dateTimeFormat);
  };
  const [formData, setFormData] = useState({
    bookingid: infoId.BookingId,
    roomtype: infoId.RoomTypeId === 1 ? "Single Seater" : "Lounge",
    noofseats: infoId.NoOfSeats,
    customerid: infoId.CustomerId,
    customername: infoId.CustomerName,
    customertype: infoId.CustomerType,
    companyname: infoId.CompanyName,
    contactnumber: infoId.ContactNo,
    email: infoId.Email,
    address: infoId.Address,
    gst: infoId.GSTNumber,
    notes: infoId.Notes,
    fromTime:
      infoId.DurationId === 1
        ? formatMomentTime(infoId.BookingFrom, "HH:mm")
        : "",
    toTime:
      infoId.DurationId === 1
        ? formatMomentTime(infoId.BookingTo, "HH:mm")
        : "",
    duration: infoId.DurationId,
    bookingstatus: infoId.BookingStatus,
    package: infoId.PackageSelection,
    bookeddate: convertTimeFormat(editFormData.BookingDatetime),
    paymentstatus: infoId.PaymentStatus === 1 ? "Pending" : "Paid",
    roomcost: infoId.TotalAmount,
    refund: "0",
  });
  const [particularPackageData, setParticularPackageData] = useState("");
  const [fileName, setFileName] = useState("");
  const [kycDocuments, setKycDocuments] = useState({});
  const customerTypes = ["Company", "Individual"];
  const [durationList, setDurationList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);
  const [kycList, setKycList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [monthList, monthListSet] = useState<any>([]);
  const [statusList, setStatusList] = useState([]);
  const [check, setCheck] = useState(false);
  const packageValidationMessage = "Please choose the Package";
  const contactValidationMessage = "Please enter the Valid Mobile Number";
  const refundValidationMessage = "Please enter the Refund Amount";
  var a;
  const [selectedMonth, selectedMonthSet] = useState(
    editFormData.NoOfMonths === 0 || editFormData.NoOfMonths === null
      ? 0
      : editFormData.NoOfMonths
  );
  const [selectedSeat, selectedSeatSet] = useState(editFormData.NoOfSeats);
  const [seatList, seatListSet] = useState<any>([]);
  const monthLoop = () => {
    var looping = [];
    for (var i = 0; i < 12; i++) {
      a = i + 1;
      looping.push(a);
    }
    console.log("List ==>", looping);
    monthListSet(looping);
  };
  const seatLoop = () => {
    var looping = [];
    for (var i = 0; i < 10; i++) {
      a = i + 1;
      looping.push(a);
    }
    seatListSet(looping);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (
      name === "ContactNo" ||
      name === "Deposit" ||
      name === "roomcost" ||
      name === "Refund"
    ) {
      if (/^\d*$/.test(value)) {
        setEditFormData({ ...editFormData, [name]: value });
      }
    } else {
      if (editFormData.CompanyName === "") {
        setcompanyNameMessage("Company name is required");
      }
      setEditFormData({ ...editFormData, [name]: value });
    }
  };

  const formatFileName = (fileName: string, fileType: string) => {
    const baseName = fileName + "." + fileType;
    return `${baseName}`;
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDurationName, setSelectedDurationName] = useState("");
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedSpaceId, setSelectedSpaceId] = useState(editFormData.spaceId);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedKyc, setSelectedKyc] = useState(editFormData.kycDocumentId);
  const [fromTimeFeild, setFromTimeFeild] = useState(formData.fromTime);
  const [toTimeFeild, setToTimeFeild] = useState(formData.toTime);
  const [checkIn, checkInSet] = useState(editFormData.BookingFrom.split("T"));
  const [checkOut, checkOutSet] = useState(editFormData.BookingTo.split("T"));
  const [formatedBookingFrom, formatedBookingFromSet] = useState(
    new Date(checkIn)
  );
  const [formatedBookingTo, formatedBookingToSet] = useState(
    new Date(checkOut)
  );
  const spaceFeildValue =
    selectedSpace !== null ? selectedSpace["SpaceName"] : "";
  const packageFeildValue =
    selectedPackage !== null ? selectedPackage["PackageName"] : "";
  const DurationFeildValue =
    selectedDuration !== null ? selectedDuration["DurationType"] : "";

  const [myTime, setMyTime] = useState(8);
  const handleDateChange = (name: any, value: any) => {
    const curDate = new Date();
    if (
      curDate.getDate() === value.getDate() &&
      curDate.getMonth() === value.getMonth() &&
      curDate.getFullYear() === value.getFullYear()
    ) {
      setMyTime(curDate.getHours());
    } else {
      setMyTime(8);
    }
    setFormatedBookingDateTime(value);
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    setCheck(true);
    e.preventDefault();
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Home",
      route: "",
    },
    {
      id: "2",
      text: "Dashboard",
      route: "/home/dashboard",
    },
    {
      id: "3",
      text: "View Booking",
      route: "",
    },
  ];

  const breadCrumbsDataView = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "Booking Summary Reports",
      route: "/report/bookingsummary",
    },
    {
      id: "3",
      text: "View Booking",
      route: "",
    },
  ];
  const [advancevalidation, setAdvanceValidationMessage] = useState("");
  const [notesvalidation, setNotesValidationMessage] = useState("");

  const [createdDate, timeWithZone1] = editFormData.CreatedOn.split("T");
  const [bookedDate, timeWithZone2] = editFormData.BookingDatetime.split("T");
  const [formatedBookingCreationDate, setFormatedBookingCreationDateTime] =
    useState(new Date(createdDate));
  const [formatedBookingDateTime, setFormatedBookingDateTime] = useState(
    new Date(bookedDate)
  );
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [rawStart, setRawStart] = useState(
    editFormData.RawpowerStartingUnit | 0
  );
  const [rawEnd, setRawEnd] = useState(editFormData.RawpowerClosingUnit | 0);
  const [upsStart, setUpStart] = useState(
    editFormData.UpspowerStartingUnit | 0
  );
  const [upsEnd, setUpsEnd] = useState(editFormData.UpspowerClosingUnit | 0);
  const [overallUnit, setOverallUnit] = useState(
    editFormData.TotalUnitConsumed | 0
  );
  const [unitCost, setUnitCost] = useState(editFormData.PerUnitCost | 0);
  const [overallEB, setOverallEB] = useState(editFormData.OverallCharges | 0);
  const [coffee, setCoffee] = useState(editFormData.CoffeeTea | 0);
  const [meals, setMeals] = useState(editFormData.Meals | 0);
  const [miscellaneous, setMiscellaneous] = useState(
    editFormData.Miscellaneous | 0
  );
  const [otherAmount, setOtherAmount] = useState(editFormData.OtherAmount | 0);
  const [deposit, setDeposit] = useState(editFormData.Deposit | 0);
  const [spacerent, setSpacerent] = useState(editFormData.SpaceRent | 0);
  const [collect, setCollect] = useState(editFormData.NeedToCollect | 0);
  const [refund, setRefund] = useState(editFormData.Refund | 0);
  const [spacerentValue, setSpaceRentValue] = useState(
    editFormData.SpaceRent | 0
  );

  // Basic validation
  const [companyNameMessage, setcompanyNameMessage] = useState("");
  const [customerNameMessage, setCustomerNameMessage] = useState("");
  const [customerEmailMessage, setCustomerEmailMessage] = useState("");
  const [customerNumberMessage, setCustomerNumberMessage] = useState("");
  const [startTimeMessage, setStartTimeMessage] = useState("");
  const [endTimeMessage, setEndTimeMessage] = useState("");

  const mobileRegex = /^[6-9][0-9]{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [seatCheck, seatCheckSet] = useState(false);

  //
  useEffect(() => {
    if (overallUnit !== 0) {
      if (unitCost === undefined || parseInt(unitCost.toString()) <= 0) {
        costErrorSet("Cost must be provided");
      } else {
        costErrorSet("");
      }
    }
  }, [overallUnit]);

  useEffect(() => {
    for (let i = 0; i < spaceList.length; i++) {
      if (editFormData.SpaceId == 1 || editFormData.SpaceId == 2) {
        seatCheckSet(true);
      } else {
        seatCheckSet(false);
      }
    }
  }, [spaceList, setSelectedSpace]);

  useEffect(() => {
    const DurationApi = async (spaceId: any) => {
      try {
        const response = await axios.get(
          `${baseURL}odata/Durations(${infoId.DurationId})`
        );
        console.log("DurationApi", response.data.value);
        setDurationList(response.data.value);
        // for (let d = 0; d < response.data.value.length; d++) {
        // if (infoId.DurationId === response.data.value[d].DurationId) {
        setSelectedDuration(response.data.value[0]);
        setSelectedDurationName(response.data.value[0].DurationType);
        // console.log(response.data.value[d].DurationType);
        // break;
        // }
        // }
      } catch (err) {}
    };

    const DocumentApi = async () => {
      try {
        const response = await axios.get(
          `${baseURL}odata/DocumentUploads?$filter=refno eq ${infoId.UserId} and contains(reftype, 'Space')`
        );
        let documentation = response.data.value;
        documents = response.data.value[documentation.length - 1];
        setKycDocuments(documents);
        setdocument(response.data.value);
        setFileName(formatFileName(documents.Name, documents.Type));
      } catch (err) {}
    };

    const PackageApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/PackageDetails`);
        setPackageList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (parseInt(infoId.PackageSelection) === response.data.value[d].Id) {
            setParticularPackageData(response.data.value[d].PackageName);
            setSelectedPackage(response.data.value[d]);
            setSelectedPackageId(response.data.value[d].id);
            break;
          }
        }
      } catch (err) {}
    };

    const StatusApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Status`);
        setStatusList(response.data);
        for (let s = 0; s < response.data.length; s++) {
          if (editFormData.BookingStatus === response.data[s].id) {
            setSelectedItem(response.data[s]);
            break;
          }
        }
      } catch (err) {}
    };

    const SpacesApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/SpaceHeaders`);
        setSpaceList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (editFormData.SpaceId === response.data.value[d].Id) {
            setSelectedSpace(response.data.value[d]);
            setSelectedSpaceId(response.data.value[d].Id);
            DurationApi(response.data.value[d].Id);
            break;
          }
        }
        StatusApi();
      } catch (err) {}
    };

    const KycApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/KycDocuments`);
        setKycList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (editFormData.kycDocumentId === response.data.value[d].Id) {
            setSelectedKyc(response.data.value[d]);
            break;
          }
        }
        StatusApi();
      } catch (err) {}
    };

    if (firsttime === true) {
      DocumentApi();
      PackageApi();
      StatusApi();
      SpacesApi();
      KycApi();
      setFirstTime(false);
      monthLoop();
      seatLoop();
    }

    if (rawEnd >= rawStart && upsEnd >= upsStart) {
      calculationPart();
    } else {
      setOverallUnit(0);
      setOverallEB(0);
      setOtherAmount(0);
      setRefund(0);
      setCollect(0);
    }
  }, [
    rawEnd,
    upsEnd,
    rawStart,
    upsStart,
    unitCost,
    coffee,
    meals,
    miscellaneous,
    otherAmount,
    deposit,
    spacerent,
    collect,
    refund,
  ]);

  const calculationPart = () => {
    var overall = rawEnd - rawStart + (upsEnd - upsStart);
    setOverallUnit(Math.round(overall * 10) / 10);
    var allEB = overall * unitCost;
    setOverallEB(Math.round(allEB * 10) / 10);
    var food = coffee - 0 + (meals - 0) + (miscellaneous - 0);
    var others = food + allEB;
    setOtherAmount(Math.round(others * 10) / 10);
    var totalcollection = spacerentValue - deposit - 0 + others;
    console.log("totalcollection", spacerent, deposit, others, deposit);

    if (totalcollection > 0) {
      console.log(
        "calculation",
        totalcollection,
        Math.round(totalcollection * 10),
        Math.round(totalcollection * 10) / 10
      );
      setCollect(Math.round(totalcollection * 10) / 10);
      setRefund(0);
    } else {
      setCollect(0);
      setRefund((Math.round(totalcollection * 10) / 10) * -1);
    }
  };

  const [firsttime, setFirstTime] = useState(true);

  // Basic Validation
  const handleValueSet = (
    e: any,
    editor: any,
    json: any,
    errorMessge: any,
    flow: any
  ) => {
    if (json === "" && flow === "") {
      editor(errorMessge);
    } else if (
      (json.length < 10 || !mobileRegex.test(json)) &&
      flow === "mobile"
    ) {
      editor("Invalid mobile number and must be 10 digits");
    } else if ((json === "" || !emailRegex.test(json)) && flow === "email") {
      editor("Invalid email address");
    } else {
      editor("");
    }
  };

  const [document, setdocument] = useState([]);
  const [costError, costErrorSet] = useState("");
  const [loading, setLoading] = useState(false);

  const putBack = () => {
    // if (views === true) {
    //   console.log("back1");
    navigate(-1);
    window.scrollTo(0, 0);
    // } else {
    //   console.log("back2");
    //   navigate(-1);
    // }
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div>
            {views === true ? (
              <Breadcrumbs breadCrumbData={breadCrumbsData} />
            ) : (
              <Breadcrumbs breadCrumbData={breadCrumbsDataView} />
            )}
            <hr className="hr-border" />
          </div>
          <div className="common-margina edit-booking-margin">
            <form className="editForm" onSubmit={handleSubmit}>
              <div className="form-row-edit">
                <div className="form-group">
                  <label>Booking ID</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="BookingId"
                    value={editFormData.BookingId}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div className="form-group">
                  <label>Booking Creation Date</label>
                  <DatePicker
                    show={false}
                    className="book-input-view book-height-edit disable-fields"
                    value={formatedBookingCreationDate}
                    onChange={(e) => handleDateChange("bookeddate", e.value)}
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label>Space Type</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="bookingid"
                    value={spaceFeildValue}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div className="form-group">
                  <label>Package</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="bookingid"
                    value={packageFeildValue}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div className="form-group">
                  <label>Check-In Date</label>
                  <DatePicker
                    show={false}
                    className="book-input-view book-height-edit disable-fields"
                    value={formatedBookingFrom}
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="form-group">
                  <label>Duration</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="bookingid"
                    value={selectedDurationName}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                {selectedDuration !== null &&
                selectedDuration["DurationType"] === "Monthly" ? (
                  <div className="form-group">
                    <label>No of Months</label>
                    <DropDownList
                      svgIcon={chevronDownIcon}
                      className="book-dropdown-new disable-fields"
                      data={monthList}
                      value={selectedMonth}
                      required={check}
                      validationMessage={packageValidationMessage}
                      onChange={(e) => selectedMonthSet(e.value)}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div />
                )}
                {seatCheck ? (
                  <div className="form-group">
                    <label>No of Seats</label>
                    <DropDownList
                      svgIcon={chevronDownIcon}
                      className="book-dropdown-new disable-fields"
                      data={seatList}
                      value={selectedSeat}
                      required={check}
                      validationMessage={packageValidationMessage}
                      onChange={(e) => selectedSeatSet(e.value)}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div />
                )}

                {selectedDuration !== null &&
                selectedDuration["DurationType"] === "Daily" ? (
                  <div className="form-group">
                    <label>Check-Out Date</label>
                    <DatePicker
                      show={false}
                      className="book-input-view book-height-edit disable-fields"
                      value={formatedBookingTo}
                      format="dd/MM/yyyy"
                    />
                  </div>
                ) : (
                  <div />
                )}
                {selectedDuration !== null &&
                selectedDuration["DurationType"] === "Hourly" ? (
                  <div className="form-group">
                    {" "}
                    <label>Start Time</label>
                    <Input
                      className="book-input-view book-height-edit disable-fields"
                      name="bookingid"
                      value={fromTimeFeild}
                      onChange={handleChange}
                      readOnly={true}
                    />
                    {/* )} */}
                    {startTimeMessage && <Error>{startTimeMessage}</Error>}
                  </div>
                ) : (
                  <div></div>
                )}

                {selectedDuration !== null &&
                selectedDuration["DurationType"] === "Hourly" ? (
                  <div className="form-group">
                    <label>End Time</label>
                    <Input
                      className="book-input-view book-height-edit  disable-fields"
                      name="bookingid"
                      value={toTimeFeild}
                      onChange={handleChange}
                      readOnly={true}
                    />
                    {/* )} */}
                    {endTimeMessage && <Error>{endTimeMessage}</Error>}
                  </div>
                ) : (
                  <div> </div>
                )}
                <div className="form-group">
                  <label>Customer Type</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="bookingid"
                    value={editFormData.CustomerType}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                {editFormData.CustomerType === "Company" ? (
                  <div className="form-group">
                    <label>Company Name</label>
                    <Input
                      className="book-input-view book-height-edit disable-fields"
                      name="CompanyName"
                      value={editFormData.CompanyName}
                      onChange={handleChange}
                      readOnly={true}
                      onBlur={(e) =>
                        handleValueSet(
                          e,
                          setcompanyNameMessage,
                          editFormData.CompanyName.trim(),
                          "Company name is required",
                          ""
                        )
                      }
                    />
                    {/* } */}
                    {companyNameMessage && <Error>{companyNameMessage}</Error>}
                  </div>
                ) : null}

                <div className="form-group">
                  <label>Customer Name</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="CustomerName"
                    value={editFormData.CustomerName}
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerNameMessage,
                        editFormData.CustomerName.trim(),
                        "Customer name is required",
                        ""
                      )
                    }
                  />
                  {customerNameMessage && <Error>{customerNameMessage}</Error>}
                </div>
                <div className="form-group">
                  <label>Contact Number</label>
                  <Input
                    maxLength={10}
                    className="book-input-view book-height-edit disable-fields"
                    name="ContactNo"
                    readOnly={true}
                    value={editFormData.ContactNo}
                    onChange={handleChange}
                    required={check}
                    validationMessage={contactValidationMessage}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerNumberMessage,
                        editFormData.ContactNo,
                        "Invalid mobile number and must be 10 digits",
                        "mobile"
                      )
                    }
                  />
                  {customerNumberMessage && (
                    <Error>{customerNumberMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="Email"
                    readOnly={true}
                    value={editFormData.Email}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerEmailMessage,
                        editFormData.Email.trim(),
                        "Email is required",
                        "email"
                      )
                    }
                  />
                  {customerEmailMessage && (
                    <Error>{customerEmailMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <TextArea
                    className="book-input-view disable-fields"
                    name="Address"
                    rows={3}
                    readOnly={true}
                    value={editFormData.Address}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>GST No</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="GSTNumber"
                    readOnly={true}
                    value={editFormData.GSTNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Notes</label>
                  <TextArea
                    className="book-input-view disable-fields"
                    name="Notes"
                    rows={3}
                    readOnly={true}
                    value={editFormData.Notes}
                    onChange={handleChange}
                  />
                  {notesvalidation && <Error>{notesvalidation}</Error>}
                </div>
                {editFormData.customerType === "Individual" ? (
                  <div className="form-group"></div>
                ) : null}
                <div className="form-group">
                  <label>Paid Amount</label>
                  {views === false ? (
                    <TextBox
                      name="Deposit"
                      type="number"
                      value={editFormData.TotalAmount}
                      readOnly={true}
                      className="book-height-edit disable-fields"
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                      style={{ width: "80%" }}
                    />
                  ) : (
                    <TextBox
                      name="Deposit"
                      type="number"
                      value={editFormData.TotalAmount}
                      readOnly={true}
                      className="book-height-edit disable-fields"
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                      style={{ width: "80%" }}
                    />
                  )}
                  {advancevalidation && <Error>{advancevalidation}</Error>}
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
              </div>
              <div className="button-group">
                <Button
                  type="submit"
                  className="common-button-view"
                  onClick={putBack}
                >
                  CANCEL
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
