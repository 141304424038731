import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import React, { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { pencilIcon, plusIcon, trashIcon } from "@progress/kendo-svg-icons";
import { process, State } from "@progress/kendo-data-query";
import axios from "axios";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const DeviceConfiguration = () => {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handleExport = () => {
    console.log("Export data");
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Biometric Devices",
      route: "",
    },
    {
      id: "2",
      text: "Device Configuration",
      route: "",
    },
  ];

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  // Edit, Delete column device list
  const MyEditCommandCell = (props: EditCommandCellProps) => (
    <EditCommandCell {...props} enterEdit={props.enterEdit} />
  );

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = async (item: any) => {
    // try {
    //   const response = await axios.get(
    //     `https://dev-cw-api.equadriga.in/odata/SpaceHeaders(${item.Id})?$expand=SpaceDetails`
    //   );
    //   if (response.status === 200 || response.status === 201) {
    //     console.log(response.status, "<");
    //     const datas = response.data || [];
    //     navigate("/masters/room/createroom", { state: datas });
    //   }
    // } catch (err) {}
  };

  const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
    <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  );

  const DeleteCommandCell = (props: DeleteCommandCellProps) => {
    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
        ></Button>
      </td>
    );
  };

  const enterDelete = (item: any) => {
    console.log(item.Id);
  };

  // Search filter changes
  const onFilterChange = (ev: any) => {
    const value = ev.target.value; // Update to use `target.value` from event
    setFilterValue(value);

    if (value === "") {
      setFilteredData(originalData); // Reset to original data when search is empty
    } else {
      const newData = originalData.filter((item: any) => {
        return (
          item.SpaceName?.toLowerCase().includes(value.toLowerCase()) ||
          item.Description?.toLowerCase().includes(value.toLowerCase()) ||
          item.SpaceTypeName?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");
    setFilteredData(originalData);
  };

  // Generate dynamic s.no
  const SerialNumberCell = (props: any) => {
    const serialNumber = dataState.skip + props.dataIndex + 1;
    return <td>{serialNumber}</td>;
  };

  return (
    <div className="overall-view ">
      <div className="space-burger">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <Button
          className="bio-device-button-view"
          svgIcon={plusIcon}
          onClick={handleExport}
        >
          Add Device
        </Button>
      </div>
      <hr className="hr-border" />
      <div>
        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">List of Biometric Devices</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using any keywords"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="SNo"
                title="S.No"
                width={"100px"}
                cell={SerialNumberCell}
              />
              <GridColumn
                field="SpaceName"
                title="Device ID"
                filter="numeric"
                cell={(props) => <td>{props.dataItem.SpaceName}</td>}
              />
              <GridColumn
                field="Description"
                title="Device Name"
                filter="text"
              />
              <GridColumn
                field="SpaceTypeName"
                title="Device Type"
                className="customer-name-styles"
              />
              <GridColumn field="SpaceOpenTime" title="Assigned Space" />
              <GridColumn field="SpaceCloseTime" title="Status" />
              <GridColumn
                field="sno"
                title="Action"
                className="Data"
                width={"240px"}
                cell={(props) => (
                  <div>
                    <MyEditCommandCell {...props} enterEdit={enterView} />
                    <MyDeleteCommandCell {...props} enterDelete={enterDelete} />
                  </div>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}

interface DeleteCommandCellProps extends GridCellProps {
  enterDelete: (item: any) => void;
}
