import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import {
  eyeIcon,
  pencilIcon,
  plusIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const RoomMaster = () => {
  const baseURL = commonBaseUrl();
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handleCreate = () => {
    navigate("/masters/room/createroom", { state: { infoId: [] } });
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Space Master",
      route: "",
    },
  ];

  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const [editItems, setEditItem] = React.useState({});

  // Edit, View & Delete column booking list
  const MyEditCommandCell = (props: EditCommandCellProps) => (
    <EditCommandCell {...props} enterEdit={props.enterEdit} />
  );

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterEdit = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], undefined);
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(`${baseURL}odata/Bookings(${id})`);
      navigate("/management/editbooking", {
        state: { infoId: response.data, view },
      });
    } catch (err) {}
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => {
            // navigate("/management/editbooking", {
            //   state: { infoId: response.data, view },
            // });
            props.enterView(props.dataItem);
          }}
        ></Button>
      </td>
    );
  };

  const enterView = async (item: any) => {
    console.log(item.Id, "<<");

    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceHeaders(${item.Id})?$expand=SpaceDetails`
      );
      if (response.status === 200 || response.status === 201) {
        console.log(response.status, "<");
        const datas = response.data || [];

        console.log(datas, "<");
        navigate("/masters/room/createroom", { state: datas });
      }
    } catch (err) {}

    setEditItem(item);
  };

  const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
    <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  );

  const DeleteCommandCell = (props: DeleteCommandCellProps) => {
    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
        ></Button>
      </td>
    );
  };

  const enterDelete = (item: any) => {
    console.log("Pressed the delete button");
    console.log(item.Id);
  };
  useEffect(() => {
    // List of space types
    const SpacesApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/SpaceHeaders`);
        const spaces = response.data.value || [];
        setOriginalData(spaces);
        setFilteredData(spaces); // Also initialize searchData with original data
      } catch (err) {
        console.error(err);
      }
    };
    SpacesApi();
  }, []);
  // Get duration API
  const getDurationDetails = async (spaceId: String) => {
    // console.log("repeat4");
    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceMappings?$filter=SpaceId%20eq%20${spaceId}`
      );
      // console.log("Duration", response.data.value[0]);
    } catch (err) {
      // setLoading(false);
      alert(err);
    }
  };

  // Get space package details API
  const getPackageDetails = async (packageId: String) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/PackageDetails(${packageId})`
      );
      // console.log(response);
      // Selected Space
      window.setTimeout(() => {
        window.scroll({
          top: 640,
          left: 0,
          behavior: "smooth",
        });
      }, 5);
    } catch (err) {
      alert(err);
    }
  };

  const onFilterChange = (ev: any) => {
    const value = ev.target.value; // Update to use `target.value` from event
    setFilterValue(value);

    if (value === "") {
      setFilteredData(originalData); // Reset to original data when search is empty
    } else {
      const newData = originalData.filter((item: any) => {
        return (
          item.SpaceName?.toLowerCase().includes(value.toLowerCase()) ||
          item.Description?.toLowerCase().includes(value.toLowerCase()) ||
          item.SpaceTypeName?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");

    setFilteredData(originalData); // Reset to original data
  };
  const SerialNumberCell = (props: any) => {
    const serialNumber = dataState.skip + props.dataIndex + 1;
    return (
      <td
        style={{
          height: "75px",
          textAlign: "right",
          paddingRight: "40px",
        }}
      >
        {serialNumber}
      </td>
    );
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const CustomDateCell = (props: any) => {
    const { field, dataItem } = props;
    return <td>{formatDate(dataItem[field])}</td>;
  };

  const MultilineTextCell = (props: any) => {
    const { dataItem, field } = props;
    return (
      <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {dataItem[field]}
      </td>
    );
  };
  return (
    <div className="overall-view ">
      <div className="space-burger">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <Button
          className="large-button-view"
          onClick={handleCreate}
          svgIcon={plusIcon}
        >
          Create a new room
        </Button>
      </div>
      <hr className="hr-border" />
      <div>
        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">List of Spaces</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using any keywords"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="SNo"
                title="S.No"
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={SerialNumberCell} // Use custom SerialNumberCell
              />
              <GridColumn
                field="SpaceName"
                title="Space Name"
                filter="numeric"
                // width={200}
                cell={(props) => <td>{props.dataItem.SpaceName}</td>}
              />
              <GridColumn
                field="Description"
                title="Description"
                filter="text"
                cell={MultilineTextCell}
              />
              <GridColumn
                field="SpaceTypeName"
                title="Space Type"
                className="customer-name-styles"
              />
              <GridColumn
                field="SpaceOpenTime"
                title="Open Time"
                cell={(props) => <CustomDateCell {...props} />}
              />
              <GridColumn
                field="SpaceCloseTime"
                title="Close Time"
                cell={(props) => <CustomDateCell {...props} />}
              />{" "}
              <GridColumn
                field="sno"
                title="Action"
                className="Data"
                width={"240px"}
                cell={(props) => (
                  <div>
                    {/* <MyViewCommandCell {...props} enterView={enterView} /> */}
                    <MyEditCommandCell {...props} enterEdit={enterView} />
                    {/* <MyDeleteCommandCell {...props} enterDelete={enterDelete} /> */}
                  </div>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}
interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
interface DeleteCommandCellProps extends GridCellProps {
  enterDelete: (item: any) => void;
}
