import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  TextArea,
  RadioButton,
  Checkbox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import Breadcrumbs from "../../components/Breadcrumbs";
import "../../css/Common.scss";
import "../../css/Duration.scss";
import "../../css/Dialog.scss";
import "../../css/TaxMaster.scss";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../../services/CommonUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import axios from "axios";
import { log } from "console";
import { Dialog } from "@progress/kendo-react-dialogs";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

interface TaxTemplate {
  taxId: 0;
}

export const CreateTaxTemplate = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const { state: { infoId } = {} } = useLocation();
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const hasFetched = useRef(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [errors, setErrors] = useState({
    taxName: "",
    taxDescription: "",
    taxTemplateDetails: "",
    taxPercentage: "",
  });
  const [newPercentage, newPercentageSet] = useState("");

  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [maximize, maximizeSet] = useState<TaxTemplate[]>([]);
  const [editTax, setEditTax] = useState();
  const [active, setActive] = useState(1);
  const [taxName, setTaxName] = useState("");
  const [description, setTaxDescription] = useState("");
  const [percentage, setPercentage] = useState("");

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Tax Template",
      route: "/masters/taxtemplate",
    },
    {
      id: "3",
      text: "Create Tax Template",
      route: "",
    },
  ];

  const breadCrumbsUpateData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Tax Template",
      route: "/masters/taxtemplate",
    },
    {
      id: "3",
      text: "Update Tax Template",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    getTaxesapi();
  }, []);

  const getTaxesapi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Taxes`);
      const taxes = response.data.value || [];
      console.log("The data was -->", taxes);
      const updated = taxes.map((item: any, idx: any) => {
        return {
          ...item,
          selected: false,
        };
      });
      setFilteredData(updated); // Also initialize searchData with original data
      if (infoId !== undefined) {
        getTaxTemplateAPI(updated);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTaxTemplateAPI = async (filtList: any) => {
    try {
      const response = await axios.get(
        `${baseURL}GetTaxTemplateById?id=${infoId}`
      );
      const taxes = response.data;
      setEditTax(taxes);
      setTaxData(taxes, filtList);
    } catch (err) {
      console.error(err);
    }
  };

  const [taxId, taxIdSet] = useState<any[]>([]);
  const setTaxData = (taxes: any, filtList: any) => {
    console.log("Data", taxes[0]);
    const name = taxes[0].taxName;
    const names = name.split(" ");
    setTaxName(names[0]);
    setTaxDescription(taxes[0].description);
    setPercentage(taxes[0].percentage + "%");
    setActive(taxes[0].isActive === true ? 1 : 2);

    let taxData = taxes[0].taxTemplateDetails;
    const taxList = [];
    for (let i = 0; i < taxData.length; i++) {
      taxList.push(taxData[i].taxId);
    }
    taxIdSet(taxList);
    maximizeSet(taxes[0].taxTemplateDetails);
    checkUpdation(taxList, filtList);
    console.log("the Selected Data was -->", taxList);
  };

  const checkUpdation = (list: any, filtList: any) => {
    console.log("the data -->", list);
    const update = filtList.map((data: any) => {
      const found = list.find((r: any) => r === data.Id);
      if (found) {
        return { ...data, selected: true };
      }
      return data;
    });
    setFilteredData(update);

    console.log("The updated Data was =>", update);
  };

  useEffect(() => {
    console.log("The given id were -->", taxId, filteredData);
  }, [taxId]);

  //   const handleCheckboxChange = (index: number, tableRow: any) => {
  //     if (maximize.length < 2) {
  //     setFilteredData((prevData) =>
  //       prevData.map((row, idx) => {
  //         return Number(idx) === Number(index) && row.IsActive === true
  //           ? {
  //               ...row,
  //               selected: !row.selected,
  //             }
  //           : row;
  //       })
  //     );
  //     if (maximize.length > 0) {
  //       const existingItem = maximize.find(
  //         (taxItem) => taxItem.taxId === tableRow.Id
  //       );
  //       if (existingItem === undefined) {
  //         if (tableRow.IsActive === true) {
  //           setTaxTemplateData(tableRow);
  //         }
  //       } else {
  //         removeItemAtIndex(tableRow.Id);
  //       }
  //     } else {
  //       if (tableRow.IsActive === true) {
  //         setTaxTemplateData(tableRow);
  //       }
  //     }
  //     }
  //   };

  const handleDialogOK = () => {
    // window.location.reload();
    navigate("/masters/taxtemplate");
  };

  const handleCheckboxChange = (index: number, tableRow: any) => {
    setFilteredData((prevData) =>
      prevData.map((row, idx) => {
        if (Number(idx) === Number(index) && row.IsActive === true) {
          const isSelected = !row.selected;

          // Prevent unchecking if the limit is 2 and both are checked
          if (isSelected && maximize.length >= 2) {
            return row; // No changes
          }

          // Update the selection status
          return { ...row, selected: isSelected };
        }
        return row;
      })
    );

    // Handle adding or removing from the maximize array
    if (tableRow.IsActive === true) {
      const existingItem = maximize.find(
        (taxItem) => taxItem.taxId === tableRow.Id
      );

      if (existingItem) {
        // Remove the item if it is being unchecked
        removeItemAtIndex(tableRow.Id);
      } else if (maximize.length < 2) {
        // Add the item if it is being checked and the limit is not exceeded
        setTaxTemplateData(tableRow);
      }
    }
  };
  const setTaxTemplateData = (tableRow: any) => {
    const taxTemplate = {
      taxId: tableRow.Id,
    };
    maximizeSet([...maximize, taxTemplate]);
  };

  const removeItemAtIndex = (indexId: any) => {
    const newArray = maximize.filter((item) => item.taxId !== indexId);
    maximizeSet(newArray);
  };

  const validateForm = () => {
    const newErrors = {
      taxName: taxName ? "" : "Template Name cannot be empty",
      taxDescription: description ? "" : "Template Description cannot be empty",
      taxPercentage: percentage ? "" : "Template Percentage cannot be empty",
      taxTemplateDetails: maximize.length > 0 ? "" : "Tax Slab cannot be empty",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleCreate = async () => {
    if (!validateForm()) return;

    const fullTax = {
      taxName: taxName + " " + percentage,
      description: description,
      percentage: parseFloat(percentage),
      isActive: active == 1 ? true : false,
      taxTemplateDetails: maximize,
    };
    console.log("response", fullTax);
    try {
      await axios.post(`${baseURL}odata/TaxTemplates`, fullTax, {
        headers: header,
      });
      //   navigate("/masters/taxtemplate");
      statusDialogSet(true);
      alertSet("Tax Template created successfully");
    } catch (error: any) {
      console.error("Failed to create tax:", error);
      statusDialogSet(true);
      alertSet(error.response.data.detail);
    }
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;

    const fullTax = {
      taxName: taxName + " " + percentage,
      description: description,
      percentage: parseFloat(percentage),
      isActive: active == 1 ? true : false,
      taxTemplateDetails: maximize,
    };
    console.log("response", fullTax, editTax);
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateTaxTemplates?key=${editTax && editTax[0]["id"]}`,
        fullTax,
        {
          headers: header,
        }
      );
      console.log("Updated", response);
      //   navigate("/masters/taxtemplate");
      statusDialogSet(true);
      alertSet("Tax Template updated successfully");
    } catch (error: any) {
      console.error("Failed to create tax:", error);
      statusDialogSet(true);
      alertSet(error.response.data.detail);
    }
  };

  const handleCancel = () => {
    navigate(-1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="overall-view ">
      <div className="overall-view">
        <Breadcrumbs
          breadCrumbData={
            infoId === undefined ? breadCrumbsData : breadCrumbsUpateData
          }
        />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div className="custom-input-containers">
            <div className="custom-input-views">
              <Label className="common-label-size">
                Template Name <span style={{ color: "red" }}>*</span>
              </Label>
              {/* className="book-dropdown-new" */}
              <select
                className="book-dropdown-new"
                name="taxname"
                value={taxName}
                disabled={infoId === undefined ? false : true}
                onChange={(e) => {
                  const value = e.target.value;
                  setTaxName(value); // Set selected value
                  if (value === "Select Name") {
                    setTaxName("");
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxName: "Tax Name is required",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxName: "",
                    }));
                  }
                }}
                onBlur={() => {
                  if (!taxName || taxName === "Select Name") {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxName: "Tax Name is required",
                    }));
                  }
                }}
                style={{ fontSize: "14px" }}
              >
                <option value="Select Name">Select Name</option>
                <option value="GST">GST</option>
                <option value="IGST">IGST</option>
              </select>
              <br />
              {errors.taxName && (
                <p style={{ color: "red" }}>{errors.taxName}</p>
              )}
            </div>
            <div style={{ width: "40px" }}> </div>
            <div className="custom-input-views" style={{ width: "50%" }}>
              <Label className="common-label-size">
                Template Description <span style={{ color: "red" }}>*</span>
              </Label>
              <TextArea
                className="book-input-view"
                name="description"
                rows={3}
                maxLength={100}
                placeholder="Enter description"
                value={description}
                disabled={infoId === undefined ? false : true}
                onChange={(e) => {
                  const value = e.value.trimStart(); // Prevent leading spaces
                  if (/^[a-zA-Z0-9.%\s]*$/.test(value)) {
                    // Allow alphanumeric, spaces, and periods
                    setTaxDescription(value); // Only set value if valid
                  }
                }}
                onBlur={() => {
                  if (!description.trim()) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxDescription: "Description is required",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxDescription: "",
                    }));
                  }
                }}
              />
              {errors.taxDescription && (
                <p style={{ color: "red" }}>{errors.taxDescription}</p>
              )}
            </div>
            <div className="custom-input-views">
              <Label className="common-label-size">
                Template Percentage <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="book-dropdown-new"
                name="percentage"
                value={percentage}
                // onChange={(e) => {
                //   const inputValue = e.value;

                //   // Allow valid percentages or clearing/backspacing
                //   if (
                //     inputValue === "" || // Allow empty input for clearing/backspacing
                //     /^(?!0)((\d{1,2}(\.\d{0,2})?|100)(%?))$/.test(inputValue) // Valid percentage format
                //   ) {
                //     setPercentage(inputValue);
                //   }
                // }}
                // onBlur={() => {
                //   // Validation: Ensure the value ends with '%' and is greater than 0
                //   if (percentage && !percentage.trim().endsWith("%")) {
                //     setErrors((prevErrors) => ({
                //       ...prevErrors,
                //       taxPercentage:
                //         "Please provide the percentage (%) symbol at the end.",
                //     }));
                //   } else if (percentage && parseFloat(percentage) <= 0) {
                //     setErrors((prevErrors) => ({
                //       ...prevErrors,
                //       taxPercentage:
                //         "Percentage must be greater than 0 and include the (%) symbol.",
                //     }));
                //   } else {
                //     setErrors((prevErrors) => ({
                //       ...prevErrors,
                //       taxPercentage: "",
                //     }));
                //   }
                // }}
                // onWheelCapture={(e) => e.preventDefault()}
                // onWheel={(e) => e.currentTarget.blur()} // Prevent scroll changes
                // onKeyDown={(e) => {
                //   if (
                //     e.key === "ArrowUp" ||
                //     e.key === "ArrowDown" ||
                //     (percentage === "100%" && e.key === ".")
                //   ) {
                //     e.preventDefault(); // Prevent arrow key changes
                //   }
                // }}
                disabled={infoId === undefined ? false : true}
                onChange={(e) => {
                  const inputValue = e.value || ""; // Ensure value is always a string

                  // Allow valid percentages (1% to 100% with up to two decimal places) or clearing
                  if (
                    inputValue === "" || // Allow clearing the input
                    /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)%?$/.test(inputValue) // Validate format
                  ) {
                    setPercentage(inputValue); // Update state with the raw value
                  }
                }}
                onBlur={() => {
                  let trimmedValue = (percentage || "").trim(); // Ensure value is a string

                  if (trimmedValue === "") {
                    // Handle empty input
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxPercentage: "Percentage cannot be empty.",
                    }));
                  } else if (!trimmedValue.endsWith("%")) {
                    // Append '%' if missing
                    if (
                      /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)$/.test(trimmedValue)
                    ) {
                      trimmedValue += "%";
                      setPercentage(trimmedValue); // Update state with appended '%'
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        taxPercentage: "",
                      }));
                    } else {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        taxPercentage:
                          "Please provide a valid percentage between 1% and 100%.",
                      }));
                    }
                  } else if (parseFloat(trimmedValue) <= 0) {
                    // Ensure value is greater than 0
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxPercentage:
                        "Percentage must be greater than 0 and include the (%) symbol.",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      taxPercentage: "",
                    })); // Clear errors if valid
                  }
                }}
                onWheelCapture={(e) => e.preventDefault()} // Prevent scroll changes
                onKeyDown={(e) => {
                  if (
                    e.key === "ArrowUp" ||
                    e.key === "ArrowDown" ||
                    (percentage === "100%" && e.key === ".")
                  ) {
                    e.preventDefault(); // Prevent invalid key actions
                  }
                }}
                placeholder="Enter percentage"
              />
              {errors.taxPercentage && (
                <p style={{ color: "red" }}>{errors.taxPercentage}</p>
              )}
            </div>
            <div style={{ width: "40px" }}> </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                // alignItems: "center",
                width: "50%",
              }}
            >
              <div>
                <Label className="common-label-size">
                  Template Status <span style={{ color: "red" }}>*</span>
                </Label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-center",
                    width: "100%",
                  }}
                >
                  <RadioButton
                    name="group2"
                    value={1}
                    checked={active === 1}
                    label="Active"
                    onChange={(e) => {
                      setActive(e.value);
                    }}
                  />
                  <div style={{ width: "30px" }}></div>
                  <RadioButton
                    name="group2"
                    value={2}
                    checked={active === 2}
                    label="InActive"
                    onChange={(e) => {
                      setActive(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div> </div>
        <div>
          <Label className="list-heading">List of Tax slabs</Label>

          <Grid
            data={process(filteredData || [], dataState)}
            pageable={{ buttonCount: 5, info: true, pageSizes: true }}
            skip={dataState.skip}
            take={dataState.take}
            total={filteredData?.length || 0}
            onPageChange={(e) => setDataState(e.page)}
            onDataStateChange={(e) => setDataState(e.dataState)}
            selectable={{
              mode: "multiple",
              enabled: true,
            }}
          >
            <GridColumn
              field="Select"
              width={10}
              cell={(props) => (
                <td>
                  <Checkbox
                    style={{ borderColor: "black", borderStyle: "solid" }}
                    checked={props.dataItem.selected}
                    disabled={infoId === undefined ? false : true}
                    onChange={() =>
                      handleCheckboxChange(props.dataIndex, props.dataItem)
                    }
                  />
                </td>
              )}
            />
            <GridColumn
              field="SNo"
              title="S.No"
              width={10}
              headerCell={RightAlignedHeaderCell}
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "right",
                    paddingRight: "40px",
                  }}
                >
                  {props.dataIndex + 1}
                </td>
              )}
            />
            <GridColumn
              field="Name"
              title="Tax Name"
              filter="text"
              cell={(props) => (
                <td
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  {props.dataItem.Name}
                </td>
              )}
            />
            <GridColumn
              field="Description"
              title="Tax Description"
              filter="text"
              cell={(props) => (
                <td
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                >
                  {props.dataItem.Description}
                </td>
              )}
            />
            <GridColumn
              field="Percentage"
              title="Tax Percentage"
              className="customer-name-styles"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "right",
                    paddingRight: "35px",
                  }}
                >
                  {props.dataItem.Percentage
                    ? `${props.dataItem.Percentage}%`
                    : ""}
                </td>
              )}
            />
            <GridColumn
              field="IsActive"
              title="Status"
              cell={(props) => (
                <td>{props.dataItem.IsActive ? "Active" : "Inactive"}</td>
              )}
            />
          </Grid>
          {errors.taxTemplateDetails && (
            <p style={{ color: "red" }}>{errors.taxTemplateDetails}</p>
          )}
        </div>
        <div className="button-group">
          {infoId !== undefined ? (
            <Button className="button-view" onClick={handleUpdate}>
              UPDATE
            </Button>
          ) : (
            <Button className="button-view" onClick={handleCreate}>
              CREATE
            </Button>
          )}
          <Button
            // type="submit"
            className="common-button-view"
            onClick={() => handleCancel()}
          >
            CANCEL
          </Button>
        </div>
      </div>
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button className="button-view" onClick={handleDialogOK}>
                OK
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
