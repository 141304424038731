import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  homeIcon,
  bookIcon,
  importIcon,
  menuIcon,
  parameterDateTimeIcon,
  folderMoreIcon,
  chevronDownIcon,
  chevronRightIcon,
  listOrderedIcon,
  calendarDateIcon,
} from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { commonBaseUrl } from "../services/CommonUtil";
import axios from "axios";

const CustomItem = (props) => {
  const { visible, ...others } = props;
  const arrowDir = props.dataExpanded ? chevronDownIcon : chevronRightIcon;
  return props.visible === false ? null : (
    <DrawerItem {...others} style={{ margin: "6px" }}>
      <SvgIcon icon={props.svgIcon} />
      <span
        className={"k-item-text"}
        style={{
          flex: "1 1 auto", // Allow the text to take up available space
          overflow: "hidden", // Prevent overflow
          textOverflow: "ellipsis", // Ellipsis for truncated text
          whiteSpace: "nowrap",
          fontSize: "14px", // Keep text in a single line
          padding: "10px",
        }}
      >
        {props.text}
      </span>
      {props.dataExpanded !== undefined && (
        <SvgIcon
          icon={arrowDir}
          style={{
            marginLeft: "auto",
          }}
        />
      )}
    </DrawerItem>
  );
};

export const DrawerContainer = (props) => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [drawerExpanded, setDrawerExpanded] = React.useState(true);
  const [visible, setVisible] = React.useState(false);

  const [roleList, setRoleList] = useState([]);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      getRoleList(); // Role API Call
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("mobile");
      localStorage.removeItem("roleId");
      navigate("/", { replace: true });
    }
  });

  const getRoleList = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/UserRoles?$filter=Id ne 2`
      );
      const roleList = response.data.value;
      setRoleList(roleList);
      for (let r = 0; r < roleList.length; r++) {
        if (Number(localStorage.getItem("roleId")) === Number(roleList[r].Id)) {
          setAccess(Number(roleList[r].Id));
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setAccess = (roleId) => {
    if (roleId === 1) {
      setItems([
        {
          text: "Dashboard",
          svgIcon: homeIcon,
          id: 1,
          selected: true,
          route: "/home/dashboard",
        },
        {
          separator: true,
        },
        {
          text: "Booking management",
          svgIcon: bookIcon,
          id: 2,
          route: "/management/listbooking",
          dataExpanded: false,
        },
        {
          text: "List of Bookings",
          svgIcon: listOrderedIcon,
          id: 3,
          parentId: 2,
          route: "/management/listbooking",
          level: 1,
        },
        {
          text: "Invoice",
          svgIcon: listOrderedIcon,
          id: 4,
          parentId: 2,
          route: "/management/listbooking/invoice",
          level: 1,
        },
        {
          text: "KYC User List",
          svgIcon: listOrderedIcon,
          id: 33,
          parentId: 2,
          route: "/management/kycuserlist",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Masters",
          svgIcon: importIcon,
          id: 5,
          route: "/masters/duration",
          dataExpanded: false,
        },
        {
          text: "Duration",
          svgIcon: parameterDateTimeIcon,
          id: 6,
          parentId: 5,
          route: "/masters/duration",
          level: 1,
        },
        {
          text: "Amenities",
          svgIcon: calendarDateIcon,
          id: 7,
          parentId: 5,
          route: "/masters/amenities",
          level: 1,
        },
        {
          text: "Packages",
          svgIcon: folderMoreIcon,
          id: 8,
          parentId: 5,
          route: "/masters/package",
          level: 1,
        },
        {
          text: "Discount",
          svgIcon: importIcon,
          id: 9,
          parentId: 5,
          route: "/masters/discount",
          level: 1,
        },
        {
          text: "Space Master",
          svgIcon: listOrderedIcon,
          id: 10,
          parentId: 5,
          route: "/masters/room",
          level: 1,
        },
        {
          text: "Contract Master",
          svgIcon: listOrderedIcon,
          id: 24,
          parentId: 5,
          route: "/masters/contractmaster",
          level: 1,
        },
        {
          text: "Tax Master",
          svgIcon: listOrderedIcon,
          id: 34,
          parentId: 5,
          route: "/masters/taxmaster",
          level: 1,
        },
        {
          text: "Tax Template",
          svgIcon: listOrderedIcon,
          id: 35,
          parentId: 5,
          route: "/masters/taxtemplate",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Cafeteria Management",
          svgIcon: importIcon,

          id: 16,
          route: "/cafeteria/overview",
          dataExpanded: false,
        },
        {
          text: "Overview",
          svgIcon: parameterDateTimeIcon,
          id: 17,
          parentId: 16,
          route: "/cafeteria/overview",
          level: 1,
        },
        {
          text: "Orders",
          svgIcon: parameterDateTimeIcon,
          id: 18,
          parentId: 16,
          route: "/cafeteria/orders",
          level: 1,
        },
        {
          text: "Categories",
          svgIcon: parameterDateTimeIcon,
          id: 21,
          parentId: 16,
          route: "/cafeteria/categories",
          level: 1,
        },
        {
          text: "Sub-Categories",
          svgIcon: parameterDateTimeIcon,
          id: 19,
          parentId: 16,
          route: "/cafeteria/subcategories",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Reports",
          svgIcon: importIcon,
          id: 25,
          route: "/report/bookingsummary",
          dataExpanded: false,
        },
        {
          text: "Booking Summary Reports",
          svgIcon: parameterDateTimeIcon,
          id: 26,
          parentId: 25,
          route: "/report/bookingsummary",
          level: 1,
        },
        {
          text: "User Activity Reports",
          svgIcon: parameterDateTimeIcon,
          id: 27,
          parentId: 25,
          route: "/report/useractivity",
          level: 1,
        },
        {
          text: "Revenue Reports",
          svgIcon: parameterDateTimeIcon,
          id: 28,
          parentId: 25,
          route: "/report/revenue",
          level: 1,
        },
        {
          text: "Cafeteria Sales Reports",
          svgIcon: parameterDateTimeIcon,
          id: 29,
          parentId: 25,
          route: "/report/cafeteria",
          level: 1,
        },
        {
          text: "Package Utilization Reports",
          svgIcon: parameterDateTimeIcon,
          id: 30,
          parentId: 25,
          route: "/management/utilizepackage",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Member Register",
          svgIcon: importIcon,
          id: 31,
          route: "/member/register",
          dataExpanded: false,
        },
        {
          text: "Members",
          svgIcon: parameterDateTimeIcon,
          id: 32,
          parentId: 31,
          route: "/member/register",
          level: 1,
        },
      ]);
    } else if (roleId === 3) {
      setItems([
        {
          text: "Cafeteria Management",
          svgIcon: importIcon,

          id: 16,
          route: "/cafeteria/overview",
          dataExpanded: false,
        },
        {
          text: "Overview",
          svgIcon: parameterDateTimeIcon,
          id: 17,
          parentId: 16,
          route: "/cafeteria/overview",
          level: 1,
        },
        {
          text: "Orders",
          svgIcon: parameterDateTimeIcon,
          id: 18,
          parentId: 16,
          route: "/cafeteria/orders",
          level: 1,
        },
        {
          text: "Categories",
          svgIcon: parameterDateTimeIcon,
          id: 21,
          parentId: 16,
          route: "/cafeteria/categories",
          level: 1,
        },
        {
          text: "Sub-Categories",
          svgIcon: parameterDateTimeIcon,
          id: 19,
          parentId: 16,
          route: "/cafeteria/subcategories",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Reports",
          svgIcon: importIcon,
          id: 25,
          route: "/report/bookingsummary",
          dataExpanded: false,
        },
        {
          text: "Cafeteria Sales Reports",
          svgIcon: parameterDateTimeIcon,
          id: 29,
          parentId: 25,
          route: "/report/cafeteria",
          level: 1,
        },
        {
          text: "Package Utilization Reports",
          svgIcon: parameterDateTimeIcon,
          id: 30,
          parentId: 25,
          route: "/management/utilizepackage",
          level: 1,
        },
      ]);
    } else if (roleId === 4) {
      setItems([
        {
          text: "Cafeteria Management",
          svgIcon: importIcon,

          id: 16,
          route: "/cafeteria/overview",
          dataExpanded: false,
        },
        {
          text: "Overview",
          svgIcon: parameterDateTimeIcon,
          id: 17,
          parentId: 16,
          route: "/cafeteria/overview",
          level: 1,
        },
        {
          text: "Orders",
          svgIcon: parameterDateTimeIcon,
          id: 18,
          parentId: 16,
          route: "/cafeteria/orders",
          level: 1,
        },
        {
          text: "Categories",
          svgIcon: parameterDateTimeIcon,
          id: 21,
          parentId: 16,
          route: "/cafeteria/categories",
          level: 1,
        },
        {
          text: "Sub-Categories",
          svgIcon: parameterDateTimeIcon,
          id: 19,
          parentId: 16,
          route: "/cafeteria/subcategories",
          level: 1,
        },
      ]);
    } else if (roleId === 5) {
      setItems([
        {
          text: "Dashboard",
          svgIcon: homeIcon,
          id: 1,
          selected: true,
          route: "/home/dashboard",
        },
        {
          separator: true,
        },
        {
          text: "Booking management",
          svgIcon: bookIcon,
          id: 2,
          route: "/management/listbooking",
          dataExpanded: false,
        },
        {
          text: "List of Bookings",
          svgIcon: listOrderedIcon,
          id: 3,
          parentId: 2,
          route: "/management/listbooking",
          level: 1,
        },
        {
          text: "Invoice",
          svgIcon: listOrderedIcon,
          id: 4,
          parentId: 2,
          route: "/management/listbooking/invoice",
          level: 1,
        },
        {
          text: "KYC User List",
          svgIcon: listOrderedIcon,
          id: 33,
          parentId: 2,
          route: "/management/kycuserlist",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Masters",
          svgIcon: importIcon,
          id: 5,
          route: "/masters/duration",
          dataExpanded: false,
        },
        {
          text: "Duration",
          svgIcon: parameterDateTimeIcon,
          id: 6,
          parentId: 5,
          route: "/masters/duration",
          level: 1,
        },
        {
          text: "Amenities",
          svgIcon: calendarDateIcon,
          id: 7,
          parentId: 5,
          route: "/masters/amenities",
          level: 1,
        },
        {
          text: "Packages",
          svgIcon: folderMoreIcon,
          id: 8,
          parentId: 5,
          route: "/masters/package",
          level: 1,
        },
        {
          text: "Discount",
          svgIcon: importIcon,
          id: 9,
          parentId: 5,
          route: "/masters/discount",
          level: 1,
        },
        {
          text: "Space Master",
          svgIcon: listOrderedIcon,
          id: 10,
          parentId: 5,
          route: "/masters/room",
          level: 1,
        },
        {
          text: "Contract Master",
          svgIcon: listOrderedIcon,
          id: 24,
          parentId: 5,
          route: "/masters/contractmaster",
          level: 1,
        },
        {
          text: "Tax Master",
          svgIcon: listOrderedIcon,
          id: 34,
          parentId: 5,
          route: "/masters/taxmaster",
          level: 1,
        },
        {
          text: "Tax Template",
          svgIcon: listOrderedIcon,
          id: 35,
          parentId: 5,
          route: "/masters/taxtemplate",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Cafeteria Management",
          svgIcon: importIcon,

          id: 16,
          route: "/cafeteria/overview",
          dataExpanded: false,
        },
        {
          text: "Overview",
          svgIcon: parameterDateTimeIcon,
          id: 17,
          parentId: 16,
          route: "/cafeteria/overview",
          level: 1,
        },
        {
          text: "Orders",
          svgIcon: parameterDateTimeIcon,
          id: 18,
          parentId: 16,
          route: "/cafeteria/orders",
          level: 1,
        },
        {
          text: "Categories",
          svgIcon: parameterDateTimeIcon,
          id: 21,
          parentId: 16,
          route: "/cafeteria/categories",
          level: 1,
        },
        {
          text: "Sub-Categories",
          svgIcon: parameterDateTimeIcon,
          id: 19,
          parentId: 16,
          route: "/cafeteria/subcategories",
          level: 1,
        },
        {
          separator: true,
        },
        {
          text: "Reports",
          svgIcon: importIcon,
          id: 25,
          route: "/report/bookingsummary",
          dataExpanded: false,
        },
        {
          text: "Booking Summary Reports",
          svgIcon: parameterDateTimeIcon,
          id: 26,
          parentId: 25,
          route: "/report/bookingsummary",
          level: 1,
        },
        {
          text: "User Activity Reports",
          svgIcon: parameterDateTimeIcon,
          id: 27,
          parentId: 25,
          route: "/report/useractivity",
          level: 1,
        },
        {
          text: "Revenue Reports",
          svgIcon: parameterDateTimeIcon,
          id: 28,
          parentId: 25,
          route: "/report/revenue",
          level: 1,
        },
        {
          text: "Cafeteria Sales Reports",
          svgIcon: parameterDateTimeIcon,
          id: 29,
          parentId: 25,
          route: "/report/cafeteria",
          level: 1,
        },
        {
          text: "Package Utilization Reports",
          svgIcon: parameterDateTimeIcon,
          id: 30,
          parentId: 25,
          route: "/management/utilizepackage",
          level: 1,
        },
      ]);
    }
  };

  const handleClick = () => {
    setDrawerExpanded(!drawerExpanded);
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("mobile");
    localStorage.removeItem("roleId");
    navigate("/", { replace: true });
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const onSelect = (ev) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem.dataExpanded !== undefined;
    const nextExpanded = !currentItem.dataExpanded;
    window.scrollTo(0, 0);  
    const newData = items.map((item) => {
      const { selected, dataExpanded: currentExpanded, id, ...others } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        dataExpanded:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    navigate(ev.itemTarget.props.route);
    setItems(newData);
  };
  
  // For sub heading closing 

// const onSelect = (ev) => {
//   const currentItem = ev.itemTarget.props;
//   const isParent = currentItem.dataExpanded !== undefined;
//   const nextExpanded = !currentItem.dataExpanded;

//   // Scroll to the top of the page whenever an item is selected
//   window.scrollTo(0, 0);  // This scrolls to the top of the page

//   // Update the items state with expanded/collapsed status
//   const newData = items.map((item) => {
//     const { selected, dataExpanded: currentExpanded, id, ...others } = item;
//     const isCurrentItem = currentItem.id === id;

//     return {
//       selected: isCurrentItem,
//       dataExpanded: isCurrentItem && isParent ? nextExpanded : currentExpanded,
//       id,
//       ...others,
//     };
//   });

//   // Perform navigation to the selected item's route
//   navigate(ev.itemTarget.props.route);

//   // Update the items state to reflect the new expanded/collapsed state
//   setItems(newData);
// };

  const data = items.map((item) => {
    const { parentId, ...others } = item;
    if (parentId !== undefined) {
      const parentEl = items.find((parent) => parent.id === parentId);
      return {
        ...others,
        visible: parentEl && parentEl.dataExpanded,
      };
    }
    return item;
  });

  return (
    <div>
      <div
        className="custom-toolbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "64px",
        }}
      >
        <div>
          <Button
            style={{ backgroundColor: "#f6f6f6", color: "black" }}
            svgIcon={menuIcon}
            onClick={handleClick}
          />
          <img
            src={require("../assets/admin_logo.png")}
            alt="Logo"
            // style={{ height: "40px", marginRight: "20px" }}
          />
          {/* <span className="title">workfromhaven</span> */}
        </div>
        {/* <div>
          <Button className="user-button" style={{ color: "black" }} svgIcon={plusIcon}>
             CREATE BOOKING
          </Button>
        </div> */}
        <div>
          <Button
            style={{
              backgroundColor: "#42B677",
              color: "white",
              padding: "10px 20px 10px 20px",
            }}
            // onClick={handleSignOut}
            onClick={toggleDialog}
          >
            Sign Out
          </Button>
          <div>
            {visible && (
              <Dialog title={"Please confirm"} onClose={toggleDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                  Are you sure you want to sign out?
                </p>
                <DialogActionsBar>
                  <Button type="button" onClick={toggleDialog}>
                    No
                  </Button>
                  <Button type="button" onClick={handleSignOut}>
                    Yes
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
      <Drawer
        expanded={drawerExpanded}
        mode="push"
        width={280}
        items={data}
        item={CustomItem}
        onSelect={onSelect}
      >
        <DrawerContent>
          {props.children} <Outlet />
        </DrawerContent>
      </Drawer>
    </div>
  );
};
