import React, {
  useState,
  useEffect,
  useRef,
  useSyncExternalStore,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";
import { Button } from "@progress/kendo-react-buttons";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import {
  checkCircleIcon,
  downloadIcon,
  eyeIcon,
} from "@progress/kendo-svg-icons";
import fileDownload from "js-file-download";
import axios from "axios";
import "../css/EditInfo.scss";
import "../css/Common.scss";
import { Error, Label } from "@progress/kendo-react-labels";
import { Dialog } from "@progress/kendo-react-dialogs";

const baseURL = commonBaseUrl();
const imageURL = commonImageUrl();

export const KYCUpdate = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const location = useLocation();
  // const { state: { infoId, view } = {} } = useLocation();
  const [editFormData, setEditFormData] = useState(location.state.infoId);
  console.log("The log was-->", editFormData);
  const [selectedKyc, setSelectedKyc] = useState("");
  const KYCTypeFeildValue = editFormData.kycDocumentId;
  const [fileName, setFileName] = useState("");
  var documents;
  const [remarks, remarksSet] = useState(editFormData.kyRemarks || "");
  const [remarksError, remarksErrorSet] = useState("");
  const [check, checkSet] = useState(false);
  const breadCrumbsData = [
    {
      id: "1",
      text: "Booking Management",
      route: "",
    },
    {
      id: "2",
      text: "KYC User List",
      route: "/management/kycuserlist",
    },
    {
      id: "3",
      text: "KYC Update",
      route: "",
    },
  ];
  useEffect(() => {
    if (remarks.trim() === "") {
      remarksErrorSet("Remarks must be provided for Rejection");
    } else {
      remarksErrorSet("");
    }
  }, [check]);
  const update = (e: any, status: number) => {
    e.preventDefault();
    console.log("The log wass -->", status, remarks);
    console.log("The conditon -->", remarks.trim() !== "");
    checkSet(true);
    if (status === 2 && remarks.trim() !== "") {
      console.log("With Remarks Reject");
      updateApi(status);
    } else {
      if (status !== 2) {
        console.log("Without Remarks Reject");
        updateApi(status);
      } else {
        remarksSet("");
      }
    }
  };
  const [confirmDialog, confirmDialogSet] = useState(false);
  const [confirmationText, confirmationTextSet] = useState("");
  const updateApi = async (status: any) => {
    const update = {
      userId: editFormData.id,
      kycDocId: editFormData.kycDocumentId,
      remarks: remarks,
      statusId: status,
    };
    console.log("The data for api -->", update);
    try {
      const response = await axios.post(`${baseURL}odata/AddKYC`, update);
      console.log("Update Response ==>", response.status);
      if (response.status === 200) {
        console.log("True");
        confirmDialogSet(true);
        confirmationTextSet(
          `The KYC was ${
            status === 1 ? "Verified" : "Rejected"
          } successfully for this customer`
        );
      }
      console.log("Update Response ==>", response.data);
      // navigate("/management/kycuserlist");
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    // console.log("infoId-->",infoId);
    KycApi();
  });

  const KycApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/KycDocuments`);
      DocumentApi();
      for (let d = 0; d < response.data.value.length; d++) {
        if (KYCTypeFeildValue === response.data.value[d].Id) {
          setSelectedKyc(response.data.value[d].Name);
          break;
        }
      }
    } catch (err) {}
  };

  const formatFileName = (fileName: string, fileType: string) => {
    const baseName = fileName + "." + fileType;
    return `${baseName}`;
  };

  const DocumentApi = async () => {
    try {
      console.log(
        "the url is -->",
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${editFormData.userId} and contains(reftype, 'User')`
      );
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${editFormData.userId} and contains(reftype, 'User')`
      );
      console.log(response.data.value, "<image<<");
      let documentation = response.data.value;
      documents = response.data.value[documentation.length - 1];
      setdocument(response.data.value);
      setFileName(formatFileName(documents.Name, documents.Type));
    } catch (err) {}
  };

  const handlePreview = (url: any) => {
    window.open(`${imageURL}` + url, "_blank");
  };

  const handleDownload = (url: any, filename: any) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(
          res.data,
          filename + "." + document[document.length - 1]["Type"]
        );
      });
  };

  const [document, setdocument] = useState([]);
  function onClickdata(url: any, filename: any) {
    handleDownload(`${imageURL}` + url, filename);
  }

  const handleCancel = () => {
    navigate("/management/kycuserlist");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="overall-view">
        <div>
          <div className="dashboard-button">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
          </div>
          <hr className="hr-border" />
        </div>
      </div>
      <div className="overall-view ">
        <div className="common-margina edit-booking-margin">
          <form className="editForm">
            <div className="form-row-edit">
              <div className="form-group">
                <Label>Customer ID</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="Booking Id"
                  value={editFormData.customerId}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <Label>Customer Type</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="bookingid"
                  value={editFormData.customerType}
                  readOnly={true}
                />
              </div>
              {editFormData.customerType === "Company" ? (
                <div className="form-group">
                  <Label>Company Name</Label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="CompanyName"
                    value={editFormData.companyName}
                    readOnly={true}
                  />
                </div>
              ) : null}
              <div className="form-group">
                <Label>Customer Name</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="CustomerName"
                  value={`${editFormData.customerName}`}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <Label>Contact Number</Label>
                <Input
                  maxLength={10}
                  className="book-input-view book-height-edit disable-fields"
                  name="ContactNo"
                  value={editFormData.contactNumber}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <Label>Email Address</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="Email"
                  readOnly={true}
                  value={editFormData.emailAddress}
                />
              </div>
              <div className="form-group">
                <Label>Address</Label>
                <TextArea
                  className="book-input-view disable-fields"
                  name="Address"
                  rows={3}
                  readOnly={true}
                  value={editFormData.address}
                />
              </div>
              <div className="form-group">
                <Label>GST No</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="GSTNumber"
                  readOnly={true}
                  value={editFormData.gstNo}
                />
              </div>
              <div className="form-group">
                <Label>KYC Documents</Label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="bookingid"
                  value={selectedKyc}
                  readOnly={true}
                />
              </div>
              {editFormData.customerType === "Individual" && (
                <div className="form-group">
                  <div className="button-group-file">
                    {/* <Label>{fileName}</Label>
                  <Button
                    className="eye-icon"
                    type="button"
                    svgIcon={eyeIcon}
                    onClick={() =>
                      handlePreview(document[document.length - 1]["ImagePath"])
                    }
                  ></Button>
                  <Button
                    className="download-icon"
                    type="button"
                    svgIcon={downloadIcon}
                    onClick={() =>
                      onClickdata(
                        document[document.length - 1]["ImagePath"],
                        document[document.length - 1]["Name"]
                      )
                    }
                  ></Button> */}
                  </div>
                </div>
              )}
            </div>
            <div style={{ marginTop: "2%" }}>
              {document[0] !== undefined ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    onDoubleClick={() =>
                      handlePreview(document[document.length - 1]["ImagePath"])
                    }
                    width="40%"
                    src={`${imageURL}${
                      document[document.length - 1]["ImagePath"]
                    }`}
                    alt="new"
                  />
                  <Label>
                    {document[document.length - 1]["Name"]}.
                    {document[document.length - 1]["Type"]}
                  </Label>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "200px",
              }}
            >
              {(editFormData.kycStatusId === 3 ||
                editFormData.kycStatusId === 2) && (
                <div className="form-group" style={{ maxWidth: "33%" }}>
                  <Label>Remarks</Label>
                  <TextArea
                    className="book-input-view book-height-edit"
                    style={{
                      paddingLeft: "2px",
                      paddingTop: "2px",
                      width: "300px",
                      height: "100px",
                    }}
                    name="bookingid"
                    value={remarks}
                    disabled={editFormData.kycStatusId === 2}
                    maxLength={300}
                    onChange={(e) => {
                      remarksSet(e.value);
                      if (e.value.trim() === "") {
                        remarksErrorSet(
                          "Remarks must be provided for Rejection"
                        );
                      } else {
                        remarksErrorSet("");
                      }
                    }}
                    //   readOnly={true}
                  />
                  {remarksError && check && <Error>{remarksError}</Error>}
                </div>
              )}

              <div
                className="button-group"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {editFormData.kycStatusId === 3 && (
                  <Button className="edit-color" onClick={(e) => update(e, 1)}>
                    Verify
                  </Button>
                )}
                {editFormData.kycStatusId === 3 && (
                  <Button className="wrong-color" onClick={(e) => update(e, 2)}>
                    Reject
                  </Button>
                )}
                {editFormData.kycStatusId !== 3 && (
                  <div style={{ width: "200px" }}></div>
                )}
                <Button
                  className="delete-color"
                  onClick={(e) => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {confirmDialog && (
        <Dialog
          height={"40%"}
          // onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div>
            <div className="dialog-content">
              <h3>{confirmationText}</h3>
              <Button
                onClick={() => {
                  // confirmDialogSet(false);
                  navigate("/management/kycuserlist");
                }}
                style={{
                  // marginRight: "20px",
                  backgroundColor: "#42B677",
                  padding: "10px",
                  color: "#fff",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                OK
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
