import React, { createRef, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { chevronDownIcon } from "@progress/kendo-svg-icons";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { useLocation, useNavigate } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";

const UpdateSubCategoryDetails = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();
  const navigate = useNavigate();
  const location = useLocation();
  const DataItem = location?.state.ItemInfo || {};
  const [tempValue, tempValueSet] = useState(0);
  const [imgExtension, imgExtensionSet] = useState("");
  const [upload, uploadSet] = useState(false);
  const extensionList = [".jpeg", ".png", ".jpg", ".JPEG", ".PNG", ".JPG"];
  const [errorFormatMessage, errorFormatMessageSet] = useState("");

  useEffect(() => {
    DataItem.previousQty = DataItem.FixedQuantity;
  }, [DataItem]);

  const [quantity, quantitySet] = useState(DataItem.FixedQuantity);

  useEffect(() => {
    tempValueSet(quantity - DataItem.FixedQuantity);
  }, [quantity]);

  useEffect(() => {}, [tempValue]);

  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Sub-Categories",
      route: "/cafeteria/subcategories",
    },
    {
      id: "3",
      text: "Sub-Categories Update",
    },
  ];

  const [openTime, setOpenTime] = useState(new Date(DataItem.FromDate));
  const [closeTime, setCloseTime] = useState(new Date(DataItem.ToDate));
  const [categoryList, categoryListSet] = useState<any>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [document, documentSet] = useState([]);
  const minTime = new Date();
  minTime.setHours(8, 0, 0); // 8:00 AM
  const maxTime = new Date();
  maxTime.setHours(20, 0, 59);
  const [error, errorSet] = useState("");
  const [errors, errorsSet] = useState("");

  useEffect(() => {
    const CategoryApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Categorys`);
        console.log("Category Response", response.data.value);
        categoryListSet(response.data.value);
        var catList = response.data.value;
        for (let i = 0; i < catList.length; i++) {
          if (DataItem.CategoryId === catList[i].Id) {
            console.log("Selected Category==>", catList[i]);
            selectCatTypeSet(catList[i]);
            break;
          }
        }
      } catch (err) {}
    };

    // setVisibleDialog(false);
    const imgApi = async () => {
      if (DataItem.Id !== undefined) {
        try {
          const response = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${DataItem.Id} and contains(reftype, 'Food')`
          );

          const dataDoct = response.data.value;
          console.log(dataDoct.length, "<Document");
          const lastItem = dataDoct[dataDoct.length - 1];
          console.log(lastItem, "LastItemSelect");
          imgExtensionSet(`.${dataDoct[dataDoct.length - 1]["Type"]}`);
          uploadSet(true);
          documentSet(dataDoct);

          console.log(response.data.id, "<-responceData");
        } catch (err) {}
      }
    };

    if (firsttime === true) {
      console.log("Called");
      CategoryApi();
      imgApi();
      setFirstTime(false);
    }
  });

  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;

  const [statusValue, setstatusValue] = useState(
    DataItem.IsAvailable === true ? "1" : "2"
  );

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);
    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );
    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;
    return isoFormattedDate;
  };

  const [catTypeError, catTypeErrorSet] = useState("");

  const updateSubCategory = () => {
    let Validate = true;
    const extMatch = extensionList.some((char) => imgExtension.includes(char));
    checkSet(true);
    if (subCatName.trim() === "") {
      nameErrorSet("Name must be provided");
      Validate = false;
    } else {
      nameErrorSet("");
    }
    if (subCatDescription.trim() === "") {
      descriptionErrorSet("Description must be provided");
      Validate = false;
    } else {
      descriptionErrorSet("");
    }
    if (price === "") {
      priceErrorSet("Price was Empty");
      Validate = false;
    } else if (parseInt(price) <= 0) {
      priceErrorSet("Enter the Price above zero");
      Validate = false;
    } else {
      priceErrorSet("");
    }
    if (selectType.id === null) {
      typeErrorSet("Select the type");
      Validate = false;
    } else {
      typeErrorSet("");
    }
    if (selectCatType.Id === null) {
      catTypeErrorSet("Select the Category type");
      Validate = false;
    } else {
      catTypeErrorSet("");
    }
    if (quantity === "") {
      limitErrorSet("Enter the Quantity");
      Validate = false;
    } else if (parseInt(quantity) <= 0) {
      limitErrorSet("Enter the Quantity above zero");
      Validate = false;
    } else {
      limitErrorSet("");
    }
    if (
      subCatName.trim() !== "" &&
      subCatDescription.trim() !== "" &&
      selectType.id !== null &&
      selectCatType.Id !== null &&
      quantity !== "" &&
      price !== "" &&
      parseInt(quantity) > 0 &&
      parseInt(price) > 0
    ) {
      if (!extMatch) {
        errorFormatMessageSet("The Image Format was not allowed");
      } else {
        errorFormatMessageSet("");
        if (Validate === true) {
          updateSubCatApi();
        }
      }
    }
  };

  const updateSubCatApi = async () => {
    // setLoading(true);
    //Temp Qty Calculation part Sri
    let Qty = tempValue > 0 ? tempValue : 0;
    let finalQty = DataItem.Quantity + Qty;
    let showDown =
      finalQty > parseInt(quantity) ? parseInt(quantity) : finalQty;
    console.log(
      "Updated set is -->",
      Qty,
      DataItem.Quantity,
      finalQty,
      showDown
    );
    const updateSubCategory = {
      id: DataItem.Id,
      categoryId: selectCatType.Id,
      name: subCatName,
      isAvailable: statusValue === "1" ? true : false,
      price: parseInt(price),
      typeId: selectType.id,
      fixedQuantity: parseInt(quantity),
      quantity: DataItem.Quantity,
      // DataItem.FixedQuantity === parseInt(quantity)
      //   ? DataItem.Quantity
      //   : parseInt(quantity),
      // jsonDate: timeRanges.toString(),
      description: subCatDescription,
      fromDate: convertToISOFormat(openTime),
      toDate: convertToISOFormat(closeTime),
    };
    console.log("Subcategory Json =>", updateSubCategory);
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateSubCategory?key=${DataItem.Id}`,
        updateSubCategory,
        {
          headers: header,
        }
      );
      uploadDocumentFiles(DataItem.Id);
    } catch (err: any) {
      if (err.response.status === 500) {
        console.log("error", err.response.data.detail);
        alertSet(err.response.data.detail);
        statusDialogSet(true);
      }
    }
  };

  const [type, typeSet] = useState([
    { id: 1, name: "Veg" },
    { id: 2, name: "Non-Veg" },
  ]);
  const [subCatName, subCatNameSet] = useState(DataItem.Name);
  const [subCatDescription, subCatDescriptionSet] = useState(
    DataItem.Description
  );
  const defaultCatType = { Id: null, Name: "Choose the category type" };
  const [selectType, selectedTypeSet] = useState(
    DataItem.TypeId === 1 ? type[0] : type[1]
  );
  const [selectCatType, selectCatTypeSet] = useState(defaultCatType);

  const handleDropdown = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectedTypeSet(e);
  };

  const handleDropdownCat = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectCatTypeSet(e);
  };

  useEffect(() => {}, [selectType]);

  const [nameError, nameErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [typeError, typeErrorSet] = useState("");
  const [limitError, limitErrorSet] = useState("");
  const [check, checkSet] = useState(false);
  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };
    if (element) {
      element.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const [loading, setLoading] = useState(false); // State for loading
  const [price, priceSet] = useState(DataItem.Price); // State for loading
  const [priceError, priceErrorSet] = useState(""); // State for loading

  // Variables
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const uploadRefs = createRef<Upload>();

  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = (
    <span>
      Only JPEG, JPG, and PNG files are allowed. The maximum upload size is 2 MB
    </span>
  );

  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    imgExtensionSet("");
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    imgExtensionSet(currentFile.extension!);
    const uid = currentFile.uid;
    console.log(currentFile, "121");
    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  const [errorimageMessage, setErrorimegeMessage] = useState<string>("");

  //upload Image
  const uploadDocumentFiles = async (subcatid: String) => {
    let header = {
      "Content-Type":
        "application/json;odata.metadata=minimal;odata.streaming=true",
      accept: "*/*",
    };
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "Food",
        docUploadRefNo: subcatid,
        docUploadRefSubType: "SubCategory",
        image: [
          {
            value: imageData,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        alertSet("Sub-category Updated successfully");
        actionSet(false);
        statusDialogSet(true);
        // setVisibleDialog(true);
        // setVisibleDetails(false);
      } else {
        // setLoader(false)
        // setVisible(true);
        // setAlertContent("Server not rechable. Please try again later!");
        // setLoading(false);
      }
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      actionSet(true);
      statusDialogSet(true); // setLoading(false);
    }
  };
  const [statusDialog, statusDialogSet] = useState(false);
  const [action, actionSet] = useState(false);
  const [alert, alertSet] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevents arrow key actions
    }
    if (event.key === "." || event.key === "," || event.key === "-") {
      event.preventDefault(); // Prevents decimal input
    }
  };

  const handleCancel = () => {
    navigate(-1);
    window.scrollTo(0, 0);
  };

  const removeImage = () => {
    imgExtensionSet("");
    setImageData("");
    uploadSet(false);
  };

  return (
    <div>
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => {
                  console.log("Action", action);
                  action === true
                    ? window.location.reload()
                    : navigate("/cafeteria/subcategories");
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label className="common-label-size">
                    Sub-Category Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="book-height"
                    type="text"
                    maxLength={25}
                    value={subCatName}
                    onChange={(e) => subCatNameSet(e.value)}
                    placeholder="Enter the name of the sub-category"
                  />
                  {nameError && <Error>{nameError}</Error>}
                </div>
                <div style={{ width: "40px" }}> </div>
                <div className="custom-input-views">
                  <Label>
                    Category Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    data={categoryList}
                    textField="Name"
                    dataItemKey="Id"
                    onChange={(e) => handleDropdownCat(e.target.value)}
                    value={selectCatType}
                  />
                  {catTypeError && <Error>{catTypeError}</Error>}
                </div>
                <div className="custom-input-views">
                  <Label>
                    {" "}
                    Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    data={type}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => handleDropdown(e.target.value)}
                    value={selectType}
                  />
                  {typeError && <Error>{typeError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div>
                {upload === true ? (
                  <div>
                    <Label>
                      Sub-Category Image <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        width="30%"
                        src={`${imageURL}${
                          document[document.length - 1]["ImagePath"]
                        }`}
                        alt="new"
                      />
                      <Label>
                        {document[document.length - 1]["Name"]}.
                        {document[document.length - 1]["Type"]}
                      </Label>
                      <Button className="delete-text" onClick={removeImage}>
                        Remove image
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {(imgExtension === "" || upload === false) && (
                  <div>
                    <ExternalDropZone
                      className="field-space border-file-upload"
                      uploadRef={uploadRefs}
                      customHint={hint}
                      customNote={note}
                    />
                    <div className="center-view" style={{ paddingTop: "10px" }}>
                      <Upload
                        ref={uploadRefs}
                        restrictions={{
                          maxFileSize: 2000000,
                          // allowedExtensions: [".jpeg", ".png", ".jpg"],
                        }}
                        batch={false}
                        multiple={false}
                        defaultFiles={[]}
                        withCredentials={false}
                        saveUrl={onSaveRequest}
                        removeUrl={onRemoveRequest}
                      />
                      {errorimageMessage && <Error>{errorimageMessage}</Error>}
                      {errorFormatMessage && (
                        <div style={{ color: "red" }}>{errorFormatMessage}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <hr className="hr-border" />
              <div
                className="custom-input-containers"
                style={{ display: "flex", justifyContent: "start" }}
              >
                <div className="custom-input-views" style={{ width: "35%" }}>
                  <Label className="common-label-size">
                    Description <span style={{ color: "red" }}>*</span>
                  </Label>
                  <TextArea
                    maxLength={500}
                    value={subCatDescription}
                    onChange={(e) => subCatDescriptionSet(e.value)}
                    placeholder="Enter Description"
                  />
                  {descriptionError && <Error>{descriptionError}</Error>}
                </div>
                <div
                  className="custom-input-views"
                  style={{ width: "10%", marginLeft: "20px" }}
                >
                  <Label>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="number"
                    className="book-dropdown-news"
                    value={quantity}
                    onKeyDown={handleKeyDown}
                    min={0}
                    maxLength={3}
                    max={999}
                    onChange={(e) => {
                      console.log("Enter the Quantity");
                      if (e.nativeEvent.inputType !== undefined) {
                        if (e.value.length <= 3) {
                          quantitySet(e.value); // Allows up to 3 characters
                        }
                      }
                      if (parseInt(e.value) <= 0) {
                        limitErrorSet("Enter the Quantity");
                      }
                    }}
                    placeholder="Enter Quantity"
                  />
                  {check && limitError && <Error>{limitError}</Error>}
                </div>
                <div style={{ width: "40px" }}></div>
                <div className="custom-input-views" style={{ width: "11%" }}>
                  <Label>
                    Price (₹/piece)<span style={{ color: "red" }}> *</span>
                  </Label>
                  <Input
                    type="number"
                    className="book-dropdown-news"
                    value={price}
                    min={0}
                    onKeyDown={handleKeyDown}
                    maxLength={3}
                    max={999}
                    onChange={(e) => {
                      if (e.nativeEvent.inputType !== undefined) {
                        if (e.value.length <= 3) {
                          priceSet(e.value); // Allows up to 3 characters
                        }
                      }
                      // priceSet(e.value);
                      // console.log("Price changes from ==>", e.value);
                      // console.log("Condition 1 ==>", parseInt(e.value) <= 0);
                      // console.log("Condition 1 ==>", parseInt(e.value) > 1000);
                      if (parseInt(e.value) <= 0 || e.value === "") {
                        priceErrorSet("Enter the Price");
                      } else if (parseInt(e.value) > 999) {
                        priceErrorSet("Price not greater than 999");
                      } else {
                        priceErrorSet("");
                      }
                    }}
                    placeholder="Enter Price"
                  />
                  {check && priceError && <Error>{priceError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div className="form-row-create">
                <style>{styles}</style>
                <div className="form-group no-wheel">
                  <Label>
                    Start Time <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="createRoomTimePicker">
                    <TimePicker
                      value={openTime}
                      className="book-dropdown-new border_error_Change"
                      // format= {{hour: "2-digit",minute: "2-digit",}}
                      placeholder={"Hour / Min"}
                      // min={minTime}

                      // className=''
                      min={minTime}
                      max={new Date(maxTime.getTime() - 60 * 60 * 1000)}
                      onChange={(e) => {
                        setCloseTime(maxTime);
                        if (e.nativeEvent.type === "input") {
                          e.syntheticEvent.preventDefault();
                          console.log(e.value, "<tsfdytfsgsdhgsd");
                          if (e.value === null) {
                            setOpenTime(minTime);
                          }
                        } else {
                          if (
                            e.value !== null &&
                            e.value!.getHours() >= minTime.getHours() &&
                            e.value! < maxTime
                          ) {
                            setOpenTime(new Date(e.value!.setSeconds(0)));

                            console.log(e.value, "<backspaces");
                            errorSet("");
                          }
                        }
                        console.log(minTime, "<backspacess");
                        console.log(e.value, "<backspacess e.value");
                      }}
                    />
                  </div>
                  {error && <Error>{error}</Error>}
                </div>
                <style>{styles}</style>
                <div className="form-group no-wheel">
                  <Label>
                    End Time <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="createRoomTimePicker">
                    <TimePicker
                      required={false}
                      disabled={openTime !== undefined ? false : true}
                      className="book-dropdown-new border_error_Change"
                      value={closeTime}
                      // format={{ hour: "2-digit", minute: "2-digit" }}
                      min={
                        openTime
                          ? new Date(
                              openTime.setSeconds(0o0) + +60 * 60 * 1000 + 1000
                            )
                          : undefined
                      }
                      max={new Date(maxTime.setHours(20, 0, 59))}
                      placeholder={"Hour / Min"}
                      onChange={(e) => {
                        if (e.nativeEvent.type === "input") {
                          e.syntheticEvent.preventDefault();

                          if (e.value === null) {
                            setCloseTime(maxTime);
                          }
                        } else {
                          if (
                            e.value!.getHours() > openTime!.getHours()! &&
                            e.value !== null &&
                            e.value! < maxTime
                          ) {
                            setCloseTime(e.value!);
                            errorSet("");
                          }
                        }
                      }}
                    />
                  </div>
                  {errors && <Error>{errors}</Error>}
                </div>
              </div>
              <div
                className="center-view"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <div className="form-row-area">
                  <div className="form-group-area"></div>
                </div>
                <div className="button-group">
                  <Button className="button-view" onClick={updateSubCategory}>
                    UPDATE
                  </Button>
                  <Button
                    // type="submit"
                    className="common-button-view"
                    onClick={() => handleCancel()}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "50%",
                }}
              ></div>
              <hr className="hr-border" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateSubCategoryDetails;
