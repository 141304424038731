import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  process,
  State,
  toDataSourceRequest,
} from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { eyeIcon, pencilIcon, trashIcon } from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";

export const Discount = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [deleteID, setDeleteID] = useState(null);
  const [dataActiveState, setActiveDataState] =
    useState<State>(initialDataState);
  const [dataExpiredState, setExpiredDataState] =
    useState<State>(initialDataState);

  const handleActivePageChange = (pageState: any) => {
    setActiveDataState({ ...dataActiveState, ...pageState });
  };

  const handleExpiredPageChange = (pageState: any) => {
    setExpiredDataState({ ...dataExpiredState, ...pageState });
  };

  const [activeDiscountList, setActiveDiscountList] = useState<any[]>([]);
  const [expiredDiscountList, setExpiredDiscountList] = useState<any[]>([]);
  const getActivePageableSettings = () => {
    const length = activeDiscountList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const getExpiredPageableSettings = () => {
    const length = expiredDiscountList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Discount Master",
      route: "",
    },
  ];

  const [discountTypeList, discountTypeListSet] = useState<any>();
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    DiscountTypeApi();
    DiscountSummaryApi();
  });
  const [upcoming, upcomingSet] = useState("");
  const [active, activeSet] = useState("");
  const [expired, expiredSet] = useState("");
  const [soon, soonSet] = useState("");
  const DiscountTypeApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/GetAllDiscountType`);
      discountTypeListSet(response.data);
      DiscountApi();
    } catch (err) {}
  };
  const DiscountSummaryApi = async () => {
    try {
      const response = await axios.get(`${baseURL}GetDiscountSummary`);
      var summary = response.data;
      console.log("Discount Summary -->", summary);
      upcomingSet(summary.upcoming);
      activeSet(summary.active);
      expiredSet(summary.expired);
      soonSet(summary.expiringSoon);
      // discountTypeListSet(response.data);
      // DiscountApi();
    } catch (err) {}
  };

  const DiscountApi = async () => {
    try {
      const response = await axios.get(`${baseURL}GetDiscountHeader`);
      const activeDiscounts: any[] = [];
      const expiredDiscounts: any[] = [];
      console.log("Discount headeers-->", response.data.$values);
      console.log("Discount headers-->", response.data);
      for (let d = 0; d < response.data.length; d++) {
        const discount = response.data[d];
        console.log("Discount headeers-->", response.data[d]);
        console.log("Condition -->", discount.isActive);
        console.log("Condition -->", discount.isExpired);

        if (discount.isExpired) {
          expiredDiscounts.push(discount);
        } else {
          activeDiscounts.push(discount);
        }
      }
      console.log("Active Discount -->", activeDiscounts);
      for (let ad = 0; ad < expiredDiscounts.length; ad++) {
        expiredDiscounts[ad].sno = ad + 1;
      }
      for (let ad = 0; ad < activeDiscounts.length; ad++) {
        activeDiscounts[ad].sno = ad + 1;
      }
      console.log("Active Discount after loop-->", activeDiscounts);
      setActiveDiscountList(activeDiscounts);
      setExpiredDiscountList(expiredDiscounts);
    } catch (err) {}
  };

  function formatDate(isoDateString: any) {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const [edit, setEdit] = useState(false);
  const handleEdit = (item: any) => {
    console.log("Data came from --> ", item);
    navigate("/masters/discount/editdiscount", { state: { ItemInfo: item } });
  };

  const [filteredData, setFilteredData] = useState(activeDiscountList);
  const [filteredDataExp, setFilteredDataExp] = useState(expiredDiscountList);
  const [search, setSearch] = useState("");
  const [expSearch, setExpSearch] = useState<string>("");

  useEffect(() => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      setFilteredData(
        activeDiscountList.filter((item) =>
          Object.values(item).some(
            (value) =>
              value !== null &&
              value !== undefined &&
              value.toString().toLowerCase().includes(lowerCaseSearch)
          )
        )
      );
    } else {
      setFilteredData(activeDiscountList);
    }
  }, [search, activeDiscountList]);
  useEffect(() => {
    if (expSearch) {
      const lowerCaseSearch = expSearch.toLowerCase();
      setFilteredDataExp(
        expiredDiscountList.filter((item) =>
          Object.values(item).some(
            (value) =>
              value !== null &&
              value !== undefined &&
              value.toString().toLowerCase().includes(lowerCaseSearch)
          )
        )
      );
      setActiveDataState(initialDataState);
    } else {
      setFilteredDataExp(expiredDiscountList);
    }
  }, [expSearch, expiredDiscountList]);

  const handleSearchChange = (e: any) => {
    setActiveDataState(initialDataState);
    setSearch(e.target.value);
  };

  const handleActivePageChanges = (e: any) => {
    setActiveDataState(e.dataState);
  };

  const handleSearchChangeExp = (e: any) => {
    setExpSearch(e.target.value); // Update search state
  };

  const handleExpiredPageChangeExp = (e: any) => {
    setExpiredDataState(e.dataState);
  };
  // Reset filter values
  const resetSearch = () => {
    setSearch("");

    setFilteredData(activeDiscountList); // Reset to original data
  };
  const resetSearchExp = () => {
    setExpSearch("");
    setFilteredDataExp(expiredDiscountList); // Reset to original data
  };
  return (
    <div className="overall-view ">
      <div className="overall-view">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // paddingRight: "10px"
          }}
        >
          <Breadcrumbs breadCrumbData={breadCrumbsData} />
          <Button
            onClick={() => navigate("/masters/discount/creatediscount")}
            // onClick={() => navigate("/masters/create")}
            className="edit-colors"
          >
            + CREATE NEW
          </Button>
        </div>
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // paddingRight: "10px"
            }}
          >
            <Card
              elevation={5}
              style={{
                height: "100px",
                // backgroundColor:"blue",
                width: "20%",
                // padding: "10px",
                justifyContent: "center", //Y-axis
                alignItems: "center", //X-axis
              }}
            >
              <div style={{ color: "grey", fontWeight: "bold" }}>
                Total Active Discounts
              </div>
              <div style={{ fontWeight: "bold", fontSize: "2rem" }}>
                {active}
              </div>
            </Card>
            <Card
              elevation={5}
              style={{
                height: "100px",
                // backgroundColor:"blue",
                width: "20%",
                // padding: "10px",
                justifyContent: "center", //Y-axis
                alignItems: "center", //X-axis
              }}
            >
              <div style={{ color: "grey", fontWeight: "bold" }}>
                Discounts Expiring Soon
              </div>
              <div style={{ fontWeight: "bold", fontSize: "2rem" }}>{soon}</div>
            </Card>
            <Card
              elevation={5}
              style={{
                height: "100px",
                // backgroundColor:"blue",
                width: "20%",
                // padding: "10px",
                justifyContent: "center", //Y-axis
                alignItems: "center", //X-axis
              }}
            >
              <div style={{ color: "grey", fontWeight: "bold" }}>
                Upcoming Discounts
              </div>
              <div style={{ fontWeight: "bold", fontSize: "2rem" }}>
                {upcoming}
              </div>
            </Card>
            <Card
              elevation={5}
              style={{
                height: "100px",
                // backgroundColor:"blue",
                width: "20%",
                // padding: "10px",
                justifyContent: "center", //Y-axis
                alignItems: "center", //X-axis
              }}
            >
              <div style={{ color: "grey", fontWeight: "bold" }}>
                Expired Discounts
              </div>
              <div style={{ fontWeight: "bold", fontSize: "2rem" }}>
                {expired}
              </div>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              alignItems: "flex-end",
            }}
          >
            <Label className="list-heading">Active Discounts</Label>
            <TextBox
              className="textBoxcustom common-search-box-height"
              placeholder="Search Discount Name, Discount Type & Spaces Applicable"
              name="activeDiscount"
              value={search}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {search ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataActiveState)}
              pageable={getActivePageableSettings()}
              skip={dataActiveState.skip}
              take={dataActiveState.take}
              total={filteredData.length}
              onPageChange={(e) => setActiveDataState(e.page)}
              onDataStateChange={handleActivePageChanges}
            >
              <GridColumn
                field="sno"
                title="S.No."
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "40px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="discountName"
                title="Discount Name"
                // cell={(props) => <td>{props.dataItem.DiscountName}</td>}
              />
              <GridColumn
                field="discountTypeName"
                title="Discount Type"
                // cell={(props) => <td>{props.dataItem.Inclusion}</td>}
              />
              <GridColumn
                field="combinedApplicableSpacesNames"
                title="Spaces Applicable"
                width={200}
                cell={(props) => (
                  <td>{props.dataItem.combinedApplicableSpacesNames}</td>
                )}
              />
              <GridColumn
                field="isExpired"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.isExpired === true ? "InActive" : "Active"}
                  </td>
                )}
              />
              <GridColumn
                field="startDate"
                title="Start Date"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {formatDate(props.dataItem.startDate)}
                  </td>
                )}

                // cell={(props) => <td>{props.dataItem.CreatedOn}</td>}
              />
              <GridColumn
                field="endDate"
                title="End Date"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {formatDate(props.dataItem.endDate)}
                  </td>
                )}
                // cell={(props) => <td>{props.dataItem.CreatedOn}</td>}
              />
              <GridColumn
                title="Action"
                className="header-center"
                cell={(props) => (
                  <td>
                    <Button
                      className="download-icon"
                      onClick={() => handleEdit(props.dataItem)}
                      svgIcon={eyeIcon}
                    />
                    {/* <Button
                      className="delete-color"
                      //    onClick={() => setDeleteID(props.dataItem.Id)}
                      svgIcon={trashIcon}
                    /> */}
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
        <div className="common-margin view-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Label className="list-heading">Expired Discounts</Label>
            <TextBox
              className="textBoxcustom common-search-box-height"
              placeholder="Search Discount Name, Discount Type & Spaces Applicable"
              name="roomcost"
              value={expSearch}
              onChange={handleSearchChangeExp} // Attach the search handler
              suffix={() => (
                <>
                  <span className="input-icon">
                    {expSearch ? (
                      <Button icon="close" onClick={resetSearchExp} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredDataExp, dataExpiredState)} // Use the filtered data
              pageable={getExpiredPageableSettings()}
              skip={dataExpiredState.skip}
              take={dataExpiredState.take}
              total={filteredDataExp.length} // Use the filtered data length
              onPageChange={(e) => setExpiredDataState(e.page)}
              onDataStateChange={handleExpiredPageChangeExp}
            >
              <GridColumn
                field="sno"
                title="S.No."
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "40px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="discountName"
                title="Discount Name"
                // cell={(props) => <td>{props.dataItem.PackageName}</td>}
              />
              <GridColumn
                field="discountTypeName"
                title="Discount Type"
                // cell={(props) => <td>{props.dataItem.Inclusion}</td>}
              />
              <GridColumn
                field="combinedApplicableSpacesNames"
                title="Spaces Applicable"
                // cell={
                //   (props) => (
                //     //   editID === props.dataItem.Id ? (
                //     //     <td>
                //     //       <DropDownList
                //     //         data={exclusions}
                //     //         value={props.dataItem.Exclusion}
                //     //         // onChange={(e) =>
                //     //         //   updatePackage(
                //     //         //     props.dataItem.Id,
                //     //         //     props.dataItem.package,
                //     //         //     props.dataItem.inclusion,
                //     //         //     e.target.value
                //     //         //   )
                //     //         // }
                //     //       />
                //     //     </td>
                //     //   ) : (
                //     <td>{props.dataItem.Exclusion}</td>
                //   )
                //   //   )
                // }
              />
              <GridColumn
                field="isActive"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.isExpired === true ? "InActive" : "Active"}
                  </td>
                )}
                // cell={(props) => <td>{props.dataItem.PackageName}</td>}
              />
              <GridColumn
                field="startDate"
                title="Start Date"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {formatDate(props.dataItem.startDate)}
                  </td>
                )}
                // cell={(props) => <td>{props.dataItem.CreatedOn}</td>}
              />
              <GridColumn
                field="endDate"
                title="End Date"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {formatDate(props.dataItem.endDate)}
                  </td>
                )}
                // cell={(props) => <td>{props.dataItem.CreatedOn}</td>}
              />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    <Button
                      className="download-icon"
                      onClick={() => handleEdit(props.dataItem)}
                      svgIcon={eyeIcon}
                    />
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
