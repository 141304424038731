import React, { useEffect, useState, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import {
  chevronDownIcon,
  eyeIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useLocation, useNavigate } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { MyDatePicker } from "./MyDatePicker";
import { Loader } from "@progress/kendo-react-indicators";
import { commonBaseUrl } from "../../services/CommonUtil";

interface Spac {
  Id: number;
  SpaceType: string;
  checked: boolean;
  SpaceDetails: DurationDetail[];
}

interface DurationData {
  DurationId: number;
  DurationName: string;
  RentAmount: number;
  DiscountValue?: number;
  DiscountPrice?: number;
}

type DurationDetail = {
  durationId: number;
  durationName: string;
  actualPrice: number;
  discountValue: number;
  discountPrice: number;
};

type Space = {
  id: number;
  spaceName: string;
  durationDetails: DurationDetail[];
};

export const EditDiscount = () => {
  const baseURL = commonBaseUrl();
  const location = useLocation();
  const DataItem = location?.state.ItemInfo || {};
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Item from prev screen", DataItem);
  }, [DataItem]);
  const initialDataState: State = {
    take: 3,
    skip: 0,
    group: [],
  };

  const [dataState, setDataState] = useState<State>(initialDataState);

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = spaceList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Discount",
      route: "/masters/discount",
    },
    {
      id: "3",
      text: "Edit discount",
      route: "",
    },
  ];

  //mani sir code
  const [spaces, setSpaces] = useState<Space[]>();

  const [selectedSpaces, setSelectedSpaces] = useState<string[]>([]);
  //   const [durationSpaceList, setDurationSpaceList] = useState<DurationData[]>(
  //     []
  //   );
  const [durationSpaceList, setDurationSpaceList] = useState<any>(undefined);
  const [discountTypeList, discountTypeListSet] = useState<any>();

  const [spaceList, spaceListSet] = useState<Spac[]>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [selectedDiscount, selectedDiscountSet] = useState();
  //   const [durationSpaceList, durationSpaceListSet] = useState<any>([]);
  const [allDiscountData, setAllDiscountData] = useState<any>(
    DataItem.discountDetails
  );

  useEffect(() => {
    const SpacesApi = async () => {
      try {
        // const response = await axios.get(`${baseURL}odata/SpaceHeaders`);
        const response = await axios.get(
          `${baseURL}odata/SpaceHeaders?$filter=IsActive eq true&$expand=SpaceDetails($filter=IsActive eq true;$expand=Duration($select=DurationType))`
        );
        const ss = response.data.value;
        const dats = ss.map((dataItem: any, index: number) => {
          console.log("Space Index -->", index);
          return {
            Index: index,
            Id: dataItem.Id,
            checked: false,
            SpaceType: dataItem.SpaceName,
            SpaceDetails: dataItem.SpaceDetails,
          };
        });
        console.log("Space Api List -->", dats);
        spaceListSet(dats);
        updatedSpaceList = dats.map((space: any) => {
          const found = spaceNames.find(
            (r: any) => r.SpaceType === space.SpaceType
          );
          if (found) {
            return { ...space, checked: true };
          }
          return space;
        });
        console.log(
          "updated space list from the api calculation -->",
          updatedSpaceList
        );
        spaceListSet(updatedSpaceList);
        setSelectedSpaces(updatedSpaceList);
        // console.log(response.data.value, "Api value");
        // console.log(dats, "Value");
      } catch (err) {}
    };
    const discountTypeApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/GetAllDiscountType`);
        discountTypeListSet(response.data);
        selectedTypeSet(response.data[1]);
        // console.log("Discount type api response", response.data);
      } catch (err) {}
    };
    if (firsttime === true) {
      //   console.log("Called");
      SpacesApi();
      discountTypeApi();
      setFirstTime(false);
    }
  });

  const spaceNames = DataItem.discountDetails.map((space: any) => ({
    Id: space.durationDetails[0].applicableSpacesId,
    SpaceType: space.spaceName,
    checked: true,
  }));

  // Update checked values in the spaceList based on the result list
  var updatedSpaceList;
  const storeDiscountData: any[] = [];
  //   useEffect(() => {
  //     console.log(durationSpaceList.spaceName, "Value changes");
  //   }, [durationSpaceList]);

  const [allDurationData, setAllDurationData] = useState<{
    [key: string]: DurationData[];
  }>({});
  const datas: { [key: string]: DurationData[] } = {};

  const customStyles = `
    .no-wheel input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Hide spinner controls */
      pointer-events: none;
      user-select: none;
    }
    
    .no-wheel input::-webkit-inner-spin-button,
    .no-wheel input::-webkit-calendar-picker-indicator {
      display: none;
    }
    
    .no-wheel input::-moz-inner-spin-button {
      display: none;
    }
    `;
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = timePickerRef.current;
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };
    if (element) {
      // Attach wheel event listener
      element.addEventListener("wheel", handleWheel, { passive: false });
      // Clean up event listener
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);
  const setTimeCalc = (value: any, name: string) => {
    const curDate = new Date();
    console.log(convertToISOFormat(value), "<<<drs<");
    if (name === "end") {
      endDateSet(value);
    } else {
      startDateSet(value);
      if (value > endDate! && endDate !== undefined) {
        endDateSet(value);
      }
    }
  };
  const durationSpaceApi = async (data: any, selectedSpaceType: string) => {
    // var list;
    // console.log("SpaceId --> ", spaceId);
    // try {
    //   const response = await axios.get(
    //     `${baseURL}odata/SpaceDetails?$filter=SpaceHeaderId eq ${spaceId}`
    //   );
    //   const durationList = response.data.value;
    //   console.log("Duration Space --> ", durationList);
    //   const transformedData = transformResponse(
    //     durationList,
    //     selectedSpaceType
    //   );
    //   if (allDiscountData !== undefined) {
    //     for (let d = 0; d < allDiscountData.length; d++) {
    //       storeDiscountData.push(allDiscountData[d]);
    //     }
    //   }

    //   storeDiscountData.push(transformedData);
    //   setDurationSpaceList(transformedData);
    //   setAllDiscountData(storeDiscountData);
    //   console.log("After Store", storeDiscountData);
    // } catch (err) {}
    // return list;
    const durationList = data;
    console.log("the upcoming data was->", data);
    const transformedData = transformResponse(durationList, selectedSpaceType);
    if (allDiscountData !== undefined) {
      console.log("calling", storeDiscountData);
      for (let d = 0; d < allDiscountData.length; d++) {
        storeDiscountData.push(allDiscountData[d]);
      }
    }
    console.log("Before storing Data -->", storeDiscountData);
    console.log("Transformed Data -->", transformedData);
    storeDiscountData.push(transformedData);
    // setDurationSpaceList(transformedData);
    setAllDiscountData(storeDiscountData);
    console.log("After Store", storeDiscountData);
  };
  const transformResponse = (response: any, selectedSpaceType: string) => {
    return {
      spaceName: selectedSpaceType,
      durationDetails: response.map((detail: any) => ({
        applicableSpacesId: detail.SpaceHeaderId,
        durationId: detail.DurationId,
        durationName: detail.Duration.DurationType,
        actualPrice: detail.RentAmount,
        discountValue: 0, // Default value
        discountPrice: 0, // Default value
      })),
    };
  };

  //   const setDurationList = (list: any) => {
  //     durationSpaceListSet;
  //   };

  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [discountList, discountListSet] = useState([
    "Fixed off",
    "Percentage off",
  ]);
  const [selectedValue, setSelectedValue] = useState(`${DataItem.eligibleFor}`);

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const [checked, checkedSet] = useState(false);
  const checkclicked = (item: any, index: any, checked: boolean) => {
    // console.log(index, item, "<");
    // console.log("State -->", checked);
    const field = item.Id;
    const checkupdate = spaceList.map((item: any, idx: any) => {
      //   console.log(idx, index, "<s");
      if (idx === index) {
        // console.log(idx, index, "<st");
        // console.log("Check when change", checked);
        return { ...item, ["checked"]: !checked };
      }
      return item;
    });
    spaceListSet(checkupdate);
    // console.log("Space List", checkupdate);
    // checkedSet(!checked);
    if (!checked) {
      //   durationSpaceApi(item.Id);
    }
  };

  // Handle checkbox click
  // const handleCheckboxChange = (item: any, index: any) => {
  //   const updatedList = [...spaceList];
  //   updatedList[index].checked = !updatedList[index].checked;
  //   const selectedSpaceType = updatedList[index].SpaceType;
  //   selectedSpaceTypesSet(selectedSpaceType);
  //   // Toggle selected spaces and update duration data
  //   if (updatedList[index].checked) {
  //     setAllDiscountData(updatedList[index].SpaceDetails);
  //     setSelectedSpaces([...selectedSpaces, selectedSpaceType]);
  //     durationSpaceApi(item.SpaceDetails, item.spaceType);
  //     //   setDurationSpaceList(allDurationData[selectedSpaceType]);
  //   } else {
  //     console.log("space", selectedSpaces, allDiscountData);
  //     setSelectedSpaces(
  //       selectedSpaces.filter((space) => space !== selectedSpaceType)
  //     );
  //     setAllDiscountData(
  //       allDiscountData.filter(
  //         (space: any) => space.spaceName !== selectedSpaceType
  //       )
  //     );
  //     // setDurationSpaceList(undefined);
  //   }
  // };

  const handleCheckboxChange = (item: any, index: any) => {
    console.log("Space Details->", item.SpaceDetails);
    selectedSpaceTypesSet("");
    const updatedList = [...spaceList];
    console.log("Updated space list is ->", updatedList);
    updatedList[index].checked = !updatedList[index].checked;
    spaceListSet(updatedList);
    const selectedSpaceTypes = updatedList[index].SpaceType;
    selectedSpaceTypesSet(selectedSpaceTypes);
    // Toggle selected spaces and update duration data
    if (updatedList[index].checked) {
      setAllDiscountData(updatedList[index].SpaceDetails);
      console.log("The selected spaces -->", selectedSpaces);
      console.log("The selected spacetypes -->", selectedSpaceTypes);
      console.log("The alldisount data -->", allDiscountData);
      setSelectedSpaces([...selectedSpaces, selectedSpaceTypes]);
      durationSpaceApi(item.SpaceDetails, item.SpaceType);
      console.log("Selected -->", selectedSpaces.length);
    } else {
      console.log("space", selectedSpaces, allDiscountData);
      setSelectedSpaces(
        selectedSpaces.filter((space) => space !== selectedSpaceTypes)
      );
      setAllDiscountData(
        allDiscountData.filter(
          (space: any) => space.spaceName !== selectedSpaceTypes
        )
      );
      console.log("Selected -->", selectedSpaces.length);
      // selectedSpaceType = ""
      // console.log("Space removed -->",selectedSpaceType);
      // setDurationSpaceList(undefined);
    }
  };

  //Handle mani code
  const handleDiscountChange = (
    spaceId: string,
    durationId: number,
    field: keyof DurationDetail,
    value: number
  ) => {
    // Update the space state dynamically
    // console.log("Spaceid -->", spaceId);
    // console.log("Field -->", field);
    // console.log("Value -->", value);
    setAllDiscountData((prevSpaces: any) =>
      prevSpaces.map((space: Space) => {
        // console.log("Space id on handlechange-->",space);
        if (space.spaceName === spaceId) {
          // console.log("Space name on handlechange-->",spaceId);
          return {
            ...space,
            durationDetails: space.durationDetails.map(
              (detail: DurationDetail) => {
                // console.log("Duration id on handlechange -->",detail.durationId, "-->",durationId);
                if (detail.durationId === durationId) {
                  // console.log("duration came with condition", durationId);
                  // console.log("Detail data -->", detail);
                  const updatedDetail = detail;
                  updatedDetail.discountValue = value;
                  // console.log("Came in discount updation -->",updatedDetail);
                  // console.log("Came in discount value -->",value);
                  // console.log("Came in discount updation -->",updatedDetail.discountPrice);
                  // console.log("Came in discount actual price -->",updatedDetail.actualPrice);
                  if (selectType.id === 2) {
                    const discountAmount =
                      (updatedDetail.discountValue *
                        updatedDetail.actualPrice) /
                      100;
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - discountAmount >= 0
                        ? updatedDetail.actualPrice - discountAmount
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  } else {
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - updatedDetail.discountValue >=
                      0
                        ? updatedDetail.actualPrice -
                          updatedDetail.discountValue
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  }
                  // }
                  return updatedDetail;
                }
                return detail;
              }
            ),
          };
        }
        return space;
      })
    );
  };

  // Handle discount input change
  const handleDiscountChanges = (e: any, index: any, indexes: any) => {
    const updatedList = allDiscountData;
    console.log(
      "Data came from -->",
      updatedList[indexes].durationDetails[index]
    );
    var data = updatedList[indexes].durationDetails[index];
    data.discountValue = parseInt(e.target.value);
    data.discountPrice =
      parseInt(data.actualPrice) - parseInt(data.discountValue) >= 0
        ? parseInt(data.actualPrice) - parseInt(data.discountValue)
        : 0;
    // updatedList[indexes].durationDetails[index].discountValue = e.target.value;
    // updatedList[indexes].durationDetails[index].discountPrice = e.target.value;
    console.log("Value come from", data);
    console.log(
      "Before updation-->",
      updatedList[indexes].durationDetails[index]
    );
    updatedList[indexes].durationDetails[index] = data;
    console.log(
      "After updation-->",
      updatedList[indexes].durationDetails[index]
    );
    console.log("Updated Whole data-->", updatedList);
    setAllDiscountData(updatedList);
    // updatedList[index].DiscountPrice =
    //   updatedList[index].RentAmount - e.target.value;
    // setDurationSpaceList(updatedList);
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const [edited, editedSet] = useState(true);
  const [startDate, startDateSet] = useState(new Date(DataItem.startDate));
  const [endDate, endDateSet] = useState(new Date(DataItem.endDate));
  const [selectedUsage, selectedUsageSet] = useState(`${DataItem.usageLimit}`);
  const handleDateChange = (value: any, name: string) => {
    if (name === "start") {
      startDateSet(value);
    } else {
      endDateSet(value);
    }
    console.log("date -->", value);
  };
  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");

    const hours = "00";
    const minutes = "00";
    const seconds = "00";
    // const hours = String(dateObj.getHours()).padStart(2, "0");
    // const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    // const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    return isoFormattedDate;
  };

  const [statusDialog, statusDialogSet] = useState(false);
  const [alertState, alertStateSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [error, setError] = useState(false);

  const updateDiscount = () => {
    checkSet(true);
    if (discountName.trim() === "") {
      nameErrorSet("Discount name must be provided");
    } else {
      console.log("name", true);
      nameErrorSet("");
    }
    if (discountDescription.trim() === "") {
      descriptionErrorSet("Description must be provided");
    } else {
      console.log("desc", true);
      descriptionErrorSet("");
    }
    if (selectType.id === null) {
      typeErrorSet("Select the type");
    } else {
      console.log("type", true);
      typeErrorSet("");
    }
    if (startDate === undefined) {
      startErrorSet("Choose the Start Date ");
    } else {
      console.log("start", true);
      startErrorSet("");
    }
    if (endDate === undefined) {
      endErrorSet("Choose the End Date");
    } else {
      console.log("end", true);
      endErrorSet("");
    }
    if (selectedUsage === "") {
      limitErrorSet("Enter the Usage limit");
    } else {
      console.log("usage", true);
      limitErrorSet("");
    }
    if (parseInt(selectedUsage) <= 0) {
      limitErrorSet("Enter the Usage limit");
    } else {
      limitErrorSet("");
    }
    if (selectedSpaces.length === 0) {
      spaceErrorSet("Select the Spaces");
      console.log(
        "Error handling",
        selectedSpaceTypes,
        selectedSpaceTypes === ""
      );
    } else {
      console.log("Space --< length -->", selectedSpaces.length);
      spaceErrorSet("");
    }
    let Validate = true;
    // allDiscountData.forEach((item: any) => {
    //   item.durationDetails.forEach((durationItem: any) => {
    //     console.log(durationItem.discountValue, "dd<");
    //     if (durationItem.discountValue <= 0) {
    //       Validate = false;
    //       console.log("Option -->", true);
    //     }
    //     if (durationItem.discountPrice <= 0) {
    //       Validate = false;
    //     }
    //   });
    // });
    Validate = allDiscountData.every((item: any) => 
      item.durationDetails.some((durationItem: any) => durationItem.discountValue !== 0)
    );
    
    console.log(Validate ? "Validation passed" : "Validation failed");
    if(Validate){
      setError(false);
    }else{
      setError(true);
    }

    if (
      discountName.trim() !== "" &&
      discountDescription.trim() !== "" &&
      selectType.id !== null &&
      startDate !== undefined &&
      endDate !== undefined &&
      selectedUsage !== "" &&
      parseInt(selectedUsage) > 0 &&
      allDiscountData.length !== 0
    ) {
      if (Validate === true) {
        updateApi();
      }
    }
  };

  const updateApi = async () => {
    setLoading(true);
    const updateDiscount = {
      id: DataItem.id,
      discountName: discountName,
      description: discountDescription,
      discountType: selectType.id,
      startDate: convertToISOFormat(startDate),
      endDate: convertToISOFormat(endDate),
      eligibleFor: parseInt(selectedValue),
      usageLimit: selectedUsage,
      discountDetails: allDiscountData,
    };
    console.log("The json structure is -->", updateDiscount);

    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateDiscountHeader`,
        updateDiscount,
        {
          headers: header,
        }
      );
      console.log("Response -->", response.data);
      alertStateSet(false);
      statusDialogSet(true);
      setLoading(false);
      alertSet("Discount updated successfully");
    } catch (err: any) {
      console.log(err);
      alertStateSet(true);
      setLoading(false);
      statusDialogSet(true);
      alertSet(err.response.data.detail);
    }
  };

  const navigateBack = () => {
    if (alertState === false) {
      navigate("/masters/discount");
    }
    statusDialogSet(false);
  };

  // Function to handle checkbox change
  //   const handleCheckboxChange = (index: number) => {
  //     const updatedData = tableData.map((item, idx) => {
  //       if (idx === index) {
  //         return { ...item, checked: !item.checked }; // Toggle checked field
  //       }
  //       return item;
  //     });
  //     setTableData(updatedData);
  //   };

  {
    /* Sri code */
  }
  const [discountName, discountNameSet] = useState(DataItem.discountName);
  const [discountDescription, discountDescriptionSet] = useState(
    DataItem.description
  );
  const defaultType = {
    id: DataItem.discountType,
    name: DataItem.discountTypeName,
  };
  const [selectType, selectedTypeSet] = useState(defaultType);

  const handleDropdown = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectedTypeSet(e);
    reset();
  };
  const reset = () => {
    console.log("Data in the list before selecting ==>", allDiscountData);
    if (allDiscountData !== undefined) {
      if (allDiscountData.length !== 0) {
        setAllDiscountData((prevSpaces: any) =>
          prevSpaces.map((space: Space) => {
            return {
              ...space,
              durationDetails: space.durationDetails.map(
                (detail: DurationDetail) => {
                  return {
                    ...detail,
                    discountValue: 0,
                    discountPrice: 0,
                  };
                  // return detail;
                }
              ),
            };
          })
        );
      }
    }
  };
  //Sri Code ^
  const [nameError, nameErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [typeError, typeErrorSet] = useState("");
  const [startError, startErrorSet] = useState("");
  const [endError, endErrorSet] = useState("");
  const [limitError, limitErrorSet] = useState("");
  const [spaceError, spaceErrorSet] = useState("");
  const [check, checkSet] = useState(false);
  const [selectedSpaceTypes, selectedSpaceTypesSet] = useState("");
  useEffect(() => {
    if (check) {
      if (discountName.trim() === "") {
        nameErrorSet("Discount name must be provided");
      } else {
        nameErrorSet("");
      }
      if (discountDescription.trim() === "") {
        descriptionErrorSet("Description must be provided");
      } else {
        descriptionErrorSet("");
      }
      if (selectType.id === null) {
        typeErrorSet("Select the type");
      } else {
        typeErrorSet("");
      }
      if (startDate === undefined) {
        startErrorSet("Choose the Start Date");
      } else {
        startErrorSet("");
      }
      if (endDate === undefined) {
        endErrorSet("Choose the End Date");
      } else {
        endErrorSet("");
      }
      if (selectedUsage === "") {
        limitErrorSet("Enter the Usage limit");
      } else {
        limitErrorSet("");
      }
      if (selectedSpaces.length === 0) {
        spaceErrorSet("Select the Spaces");
        console.log(
          "Error handling",
          selectedSpaceTypes,
          selectedSpaces.length === 0
        );
      } else {
        spaceErrorSet("");
      }
    }
  }, [
    check,
    discountName,
    discountDescription,
    selectType,
    startDate,
    endDate,
    selectedUsage,
    selectedSpaces,
  ]);
  const [loading, setLoading] = useState(false); // State for loading
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevents arrow key actions
    }
    if (event.key === "." || event.key === "," || event.key === "-") {
      event.preventDefault(); // Prevents decimal input
    }
  };
  const handleCancel = () => {
    navigate(-1);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumbs breadCrumbData={breadCrumbsData} />
              {edited && (
                <Button
                  className="button-view "
                  onClick={() => editedSet(false)}
                >
                  Edit
                </Button>
              )}
            </div>
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label className="common-label-size">
                    Discount Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="book-height"
                    maxLength={25}
                    type="text"
                    readOnly={edited}
                    value={discountName}
                    onChange={(e) => discountNameSet(e.value)}
                    placeholder="Enter the discount name"
                  />
                  {nameError && <Error>{nameError}</Error>}
                </div>

                <div style={{ width: "40px" }}> </div>
                <div className="custom-input-views" style={{ width: "80%" }}>
                  <Label className="common-label-size">
                    Description <span style={{ color: "red" }}>*</span>
                  </Label>
                  <TextArea
                    maxLength={500}
                    readOnly={edited}
                    value={discountDescription}
                    onChange={(e) => discountDescriptionSet(e.value)}
                    placeholder="Enter Description"
                  />
                  {descriptionError && <Error>{descriptionError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label>
                    Discount Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    disabled
                    data={discountTypeList}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => handleDropdown(e.target.value)}
                    value={selectType}
                    // defaultItem={defaultType}
                  />
                  {typeError && <Error>{typeError}</Error>}
                </div>
                <div className="custom-input-views">
                  <Label>
                    Start Date <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{customStyles}</style>
                  <div
                    className="no-wheel"
                    ref={timePickerRef}
                    style={{ width: "80%" }}
                  >
                    <MyDatePicker
                      minDate={new Date()}
                      checkInDate={startDate!}
                      isDisableCheck={false}
                      customFormatter={false}
                      setCheckInDate={(e: any) => {
                        // setCheckInDate(e);
                        setTimeCalc(e, "start");
                      }}
                    />
                  </div>
                  {startError && <Error>{startError}</Error>}
                </div>
                <div className="custom-input-views">
                  <Label>
                    End Date <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{customStyles}</style>
                  <div
                    className="no-wheel"
                    ref={timePickerRef}
                    style={{ width: "80%" }}
                  >
                    <MyDatePicker
                      minDate={startDate !== undefined ? startDate : new Date()}
                      checkInDate={endDate!}
                      isDisableCheck={false}
                      customFormatter={false}
                      setCheckInDate={(e: any) => {
                        // setCheckInDate(e);
                        setTimeCalc(e, "end");
                      }}
                    />
                  </div>
                  {endError && <Error>{endError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  // alignItems: "center",
                  width: "50%",
                }}
              >
                <div>
                  <div>
                    Eligible For <span style={{ color: "red" }}>*</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      // alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <RadioButton
                      name="group1"
                      disabled={edited}
                      value="1"
                      checked={selectedValue === "1"}
                      label="All Users"
                      onChange={() => setSelectedValue("1")}
                      // style={{marginRight:"5%"}}
                    />
                    <RadioButton
                      name="group1"
                      disabled={edited}
                      value="2"
                      checked={selectedValue === "2"}
                      label="New users"
                      onChange={() => setSelectedValue("2")}
                      // style={{marginRight:"5%"}}
                    />
                    <RadioButton
                      name="group1"
                      disabled={edited}
                      value="3"
                      checked={selectedValue === "3"}
                      label="Existing users"
                      onChange={() => setSelectedValue("3")}
                      // style={{marg`inRight:"5%"}}
                    />
                  </div>
                </div>
                <div style={{ width: "100px", marginLeft: "10%" }}>
                  <Label>
                    Usage Limit <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="number"
                    maxLength={5}
                    onKeyDown={handleKeyDown}
                    readOnly={edited}
                    className="book-dropdown-news"
                    value={selectedUsage}
                    onChange={(e) => {
                      console.log("Event ->", e.nativeEvent.inputType, e.value);
                      // console.log("Event Type ->", e.nativeEvent.type);
                      if (e.nativeEvent.inputType !== undefined) {
                        if (e.value.toString().length <= 4) {
                          selectedUsageSet(e.value);
                        }
                        if (parseInt(e.value) <= 0) {
                          limitErrorSet("Enter the Usage Limit");
                        }
                      }
                    }}
                  />
                  {check && limitError && <Error>{limitError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              {spaceList.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  Select Applicable Spaces
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {spaceList.map((item: any, index: any) => (
                  <Checkbox
                    style={{ borderColor: "black", borderStyle: "solid" }}
                    disabled={edited}
                    key={index}
                    value={item.checked}
                    label={item.SpaceType}
                    onChange={() => handleCheckboxChange(item, index)}
                  />
                ))}
              </div>
              {spaceList.length === 0 && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  {" "}
                  No Spaces Available for this discount
                </div>
              )}
              {spaceError && <Error>{spaceError}</Error>}
              {error && <Error>Please apply discount for any of the selected space duration</Error>}
              {allDiscountData.length === 0 && check && (
                <Error>Please Select any of the Space for update</Error>
              )}
              {allDiscountData !== undefined &&
                allDiscountData.map((item: any, indexes: any) => (
                  <div
                    style={{
                      fontWeight: "bold",
                      border: "2px solid black",
                      padding: "10px",
                    }}
                  >
                    {item.spaceName}
                    <div>
                      <table cellPadding="10">
                        <thead>
                          <tr>
                            <th>Duration</th>
                            <th>Actual Price(₹)</th>
                            <th>
                              {" "}
                              {selectType.id === 2
                                ? " Discount Percentage(%)"
                                : "Discount Value(₹)"}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>Discount Price(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.durationDetails.map(
                            (durationItem: any, index: any) => (
                              <tr key={index}>
                                <td
                                  style={{ fontWeight: "normal" }}
                                  width={"250px"}
                                >
                                  {durationItem.durationName}
                                </td>
                                {/* <td>{durationItem.actualPrice}</td> */}
                                <td>
                                  <Input
                                    style={{ color: "grey", textAlign: "end" }}
                                    type="number"
                                    value={durationItem.actualPrice}
                                    readOnly={true}
                                  />
                                </td>
                                <td>
                                  <Input
                                    readOnly={edited}
                                    style={{ textAlign: "end" }}
                                    type="number"
                                    value={durationItem.discountValue}
                                    valid={
                                      durationItem.discountValue > 0 ||
                                      durationItem.discountPrice <= 0
                                    }
                                    min={0}
                                    onChange={(e) => {
                                      if (
                                        e.nativeEvent.inputType !== undefined
                                      ) {
                                        handleDiscountChange(
                                          item.spaceName,
                                          durationItem.durationId,
                                          durationItem,
                                          parseInt(e.value) || 0
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    style={{ color: "grey", textAlign: "end" }}
                                    type="number"
                                    value={durationItem.discountPrice}
                                    readOnly={true}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}

              <div
                className="center-view"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <div className="form-row-area">
                  <div className="form-group-area"></div>
                </div>
                <div className="button-group">
                  {!edited && allDiscountData.length !== 0 && (
                    <Button className="button-view" onClick={updateDiscount}>
                      UPDATE
                    </Button>
                  )}
                  <Button
                    // type="submit"
                    className="common-button-view"
                    onClick={() => handleCancel()}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
              {/* {durationSpaceList.length !== 0 && (
            <div
              style={{
                fontWeight: "bold",
                border: "2px",
                borderColor: "black",
              }}
            >
              {durationSpaceList[0].SpaceName === undefined
                ? ""
                : durationSpaceList[0].SpaceName}
              <div>
                <table cellPadding="10">
                  <thead>
                    <tr>
                      <th>Duration</th>
                      <th>Actual Price(₹)</th>
                      <th>Discount Value</th>
                      <th>Discount Price (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {durationSpaceList.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{item.DurationName}</td>
                        <td>{item.RentAmount}</td>
                        <td>
                          <Input
                            value={item.DiscountValue}
                            // onChange={(e)=>handleChanges(index,"discount",e.target.value)}
                          />
                        </td>
                        <td>{item.RentAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
              {statusDialog && (
                <Dialog>
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                    }}
                  >
                    <h3>{alert}</h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "30px",
                      }}
                    >
                      <Button
                        className="button-view"
                        onClick={() => navigateBack()}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </Dialog>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
