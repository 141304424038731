import React, { useEffect, useState, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "../css/Duration.scss";
import "../css/Common.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Scheduler } from "./schedular.jsx";
import { MonthView } from "@progress/kendo-react-scheduler";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Slider } from "@alm-engineering/react-scroll-snap-slider";
import { IntlService } from "@progress/kendo-react-intl";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";

export const BookingManagementData = (props) => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();
  const {
    onDateClick
  } = props;

  const [spaceId, setSpaceId] = useState(0);
  const [bookingData, setBookingData] = useState([]);
  const navigate = useNavigate();
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [visibleDialogCheck, setVisibleDialogCheck] = useState(false);

  useEffect(() => {
    // getBookingData();
    setVisibleDialog(false);
  }, []);

  //Srikkanth
  const hasFetched = useRef(false);
  const [spaceDetailsData, setSpaceDetailsData] = useState([]);
  const [selectedCard, setSelectedCard] = useState();

  const [spaceList, setSpaceList] = useState([]);
  const [durationList, durationListSet] = useState([]);
  var disk;
  var diskList;
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    getSpaceList();
    getDurationList();
  }, [spaceDetailsData.length > 0]);

  // Initial load API's
  const getDurationList = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Durations`);
      // setSpaceList(response.data.value);
      console.log("Duration API response", response.data.value);
      durationListSet(response.data.value);
    } catch (err) {
      alert(err);
    }
  };
  const getSpaceList = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/SpaceHeaders`);
      // setSpaceList(response.data.value);
      disk = response.data.value;
      console.log("Space API disk", disk);
      setSpaceList(disk);
      console.log("Space API response", response.data.value);
      for (let s = 0; s < response.data.value.length; s++) {
      
          console.log(s, "<this api is Image Get")
 const responseImage = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${response.data.value[s].Id} and contains(reftype, 'Space')
 `
          );
        
          const imageDataList = responseImage.data.value
  console.log(imageDataList[imageDataList.length -1].ImagePath, "<><><><<<")

        const newData = {
          Id: response.data.value[s].Id,
          SpaceType: response.data.value[s].SpaceName,
          NoOfSeats: response.data.value[s].NoOfSeats,
          Description: response.data.value[s].Description,
          DurationId: response.data.value[s].DurationId,         
          PackageDetailsId: response.data.value[s].PackageDetailsId,
          RentAmount: response.data.value[s].RentAmount,
          Tax: response.data.value[s].Tax,
          HourlyRate: response.data.value[s].HourlyRate,
          DailyRate: response.data.value[s].DailyRate,
          MonthlyRate: response.data.value[s].MonthlyRate,
          YearlyRate: response.data.value[s].YearlyRate,
          SpaceImage: imageDataList[imageDataList.length -1].ImagePath,
        };


         
        addSpaceData(newData);
        diskList = newData;
     


       
      }
      console.log("Space API value", spaceList);
    } catch (err) {
      alert(err);
    }
  };





  const durationCheck =(id)=>{
    var durationType;
    for(let i =0; i < durationList.length;i++){
      if(id == durationList[i].Id){
        durationType = durationList[i].DurationType;
        break;
      }
    }
    return durationType;
  }

  const addSpaceData = (newData) => {
    setSpaceDetailsData((prevData) => [...prevData, newData]);
    console.log("SpaceList was -->", diskList);
  };

  const handleView = () => {
    console.log("The id is -->", id);
    console.log("Full Details clicked");
    navigate("/management/editbooking", {
      state: { infoId: bookingData, undefined },
    });
  }

  const handleClose = () => {
    window.location.reload();
    setVisibleDialog(false);
    setVisibleDialogCheck(true);
    console.log("Close clicked", visibleDialog);
  }
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [seat, seatSet] = useState("");
  const [duration, durationSet] = useState("");
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const handleClick = (e) => {
    console.log(e, "hmhh")
    if (e.dataItem.id != null) {
      console.log("Required Data item -->", e.dataItem);
      setName(e.dataItem.title);
      setId(e.dataItem.id);
      seatSet(e.dataItem.noofseats);
      console.log(e.dataItem.start)
      setStart(formatDateMonth(e.dataItem.start));
      setEnd(formatDateMonth(e.dataItem.end));
      durationSet(durationCheck(e.dataItem.duration));
      setVisibleDialog(true);
      console.log("id is hear");
    } else {
      console.log("no id found ",e);
      console.log("The space list was -->", spaceList);
      const selectedSpace = spaceList.find((item)=> item.Id === spaceId);
      console.log("The selected space was -->", selectedSpace);
      // setSpaceList
      onDateClick(e,selectedSpace);
    }
    // onDateClick(e);
  }
  const slidesPerPageSettings = {
    mobileSmall: 1.5,
    mobileBig: 2.5,
    tablet: 3,
    desktop: 4,
  };

  useEffect(() => {
    getBookingData(id);
  }, [id]);

  const getBookingData = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/Bookings(${id})`
      );
      setBookingData(response.data);
    } catch (err) {}
  };

  const [tempData, setTempData] = useState([]);
  const intl = new IntlService();
  const formatDateMonth = (givenDate) => {
    const formattedDate = intl.formatDate(givenDate, "dd-MMM-yyyy");
    return formattedDate;
  }
  const formatDate = (givenDate) => {
    const formattedDate = intl.formatDate(givenDate, "yyyy-MM-dd");
    return formattedDate;
  }
  useEffect(() => {
    var savedIndex = localStorage.getItem('selectedIndex');
    var saveIndex;
    if (savedIndex !== null) {
      saveIndex = parseInt(savedIndex, 10);
      console.log("Saved Index send to handlespace", saveIndex);  // Parse and set the saved index
      handleSpaceSelect(saveIndex);
    }
  }, []);

  useEffect(() => {
    const fetchBookingData = async () => {
      // setTempData([]);
      console.log("Triggered space id change", spaceId);
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      // console.log("Start Date -->",formatDate(firstDay));
      // console.log("Last Date -->",formatDate(lastDay));
      var startDate = formatDate(firstDay);
      var endDate = formatDate(lastDay);
      // setSpaceId(localStorage.getItem('selectedIndex')+1);
      var spaceidlocal = parseInt(localStorage.getItem('selectedIndex')) +1;
      console.log(spaceidlocal + 1,"Selected index of local storage");
      console.log("By UseEffect",spaceId);
      var spaceidlocaldata = spaceId === 0 ? spaceidlocal : spaceId;
      try {
        const response = await axios.get(
          `${baseURL}odata/GetAllBookingsDetailsByDate?spaceId=${spaceidlocaldata}&startDate=${startDate}&endDate=${endDate}`
        );

console.log(response.data, "Datassss");
const DataValueMine = response.data.filter((Dataset )=>  Dataset.IsActive === false)
console.log(response.data, "Datassss");


        
      const mappedBookings = response.data.reduce((acc, item) => {
        if (item.bookingInfos) {
          const bookings = item.bookingInfos.map(booking => ({
            id: booking.bookingRecId,
            title: booking.customerName,
            start: new Date(booking.bookedFrom),
            end: new Date(booking.bookedTo),
            employeeID: booking.bookingRecId,
            customerEmail: booking.customerEmail,
            customerPhone: booking.customerPhone,
            duration: booking.durationId,
            noofseats: booking.noOfSeats,
          }));
          return [...acc, ...bookings];
        }
        return acc;
      }, []);
      console.log("Mapped Bookings", mappedBookings);
      setTempData(mappedBookings);
        // console.log("Selected SpaceId",spaceId);
        // console.log("API Response", response.data);
        // for (var i = 0; i < response.data.length; i++) {
        //   if (response.data[i].bookingInfos !== null) {
        //     console.log(response.data[i].bookingInfos, "<-before data data");
        //     const mappedBookings = response.data[i].bookingInfos.map((booking) => ({
        //       id: booking.bookingRecId,
        //       title: `${booking.customerName}`,
        //       start: new Date(booking.bookedFrom),
        //       end: new Date(booking.bookedTo),
        //       employeeID: booking.bookingRecId, // Could be used if needed
        //       customerEmail: booking.customerEmail,
        //       customerPhone: booking.customerPhone,
        //       duration: booking.durationId,
        //       noofseats: booking.noOfSeats,
        //     }));
        //     // setTempData([]);
        //     console.log("API Response structured", mappedBookings);
        //     setTempData(mappedBookings);
        //     console.log("Data Setted", tempData);
        //   }
        //   // console.log(i);
        // }
        // console.log("API response:", response.data.length);
        // const bookingInfos = response.data.bookingInfos || [];
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };
    fetchBookingData();
  }, []);


  const fetchBookingDataSpace = async (spaceId, month) => {
    console.log(month, 'Data month')
    setTempData([]);
    var date = new Date();
    if(month !== undefined ){
      var firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    var lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    }else{
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); 
    }
    var startDate = formatDate(firstDay);
    var endDate = formatDate(lastDay);
    console.log(spaceId,startDate, endDate, "firstDay and last Day   ")
    try {
      const response = await axios.get(
        `${baseURL}odata/GetAllBookingsDetailsByDate?spaceId=${spaceId}&startDate=${startDate}&endDate=${endDate}`
      );


    const mappedBookings = response.data.reduce((acc, item) => {
      if (item.bookingInfos) {
        const bookings = item.bookingInfos.map(booking => ({
          id: booking.bookingRecId,
          title: booking.customerName,
          start: new Date(booking.bookedFrom),
          end: new Date(booking.bookedTo),
          employeeID: booking.bookingRecId,
          customerEmail: booking.customerEmail,
          customerPhone: booking.customerPhone,
          duration: booking.durationId,
          noofseats: booking.noOfSeats,
        }));
        return [...acc, ...bookings];
      }
      return acc;
    }, []);

    console.log("Mapped Bookings", mappedBookings);

    setTempData(mappedBookings);
      // console.log("Selected SpaceId",spaceId);
      // console.log("API Response", response.data);
      // setTempData([]);
      // for (var i = 0; i < response.data.length; i++) {
      //   if (response.data[i].bookingInfos !== null) {
      //     console.log(response.data[i].bookingInfos, "<-after data");
      //   const mappedBookings = response.data[i].bookingInfos.map((booking) => ({
      //       id: booking.bookingRecId,
      //       title: `${booking.customerName}`,
      //       start: new Date(booking.bookedFrom),
      //       end: new Date(booking.bookedTo),
      //       employeeID: booking.bookingRecId, // Could be used if needed
      //       customerEmail: booking.customerEmail,
      //       customerPhone: booking.customerPhone,
      //       duration: booking.durationId,
      //       noofseats: booking.noOfSeats,
      //     }));
      //     console.log("API Response structure d", mappedBookings);
      //    setTempData(mappedBookings);
      //     console.log("Data Setted", tempData);
      //   }
       
      //   // console.log(i);
      // }
      // console.log("API response:", response.data.length);
      // const bookingInfos = response.data.bookingInfos || [];
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };


  // Space selection
  const handleSpaceSelect = async (index) => {
    console.log("Triggered");
    setSelectedCard(null);
    // getAvailability(spaceList[index]["Id"]);
    // getDurationDetails(spaceList[index]["Id"]);
    setSelectedCard(index);
    setSpaceId(index + 1);
    fetchBookingDataSpace(index + 1);
    console.log("Spaceid", index + 1);
    localStorage.setItem('selectedIndex', index); // Save index in localStorage
    // Hide & Reset views based on selected space
    // setIsCheckMonth(true);
    // setIsCheckSpaceCount(true);
    // setSelectedDuration(defaultDurationValue);
    // setCheckInDate(null);
    // window.setTimeout(() => {
    //   window.scroll({
    //     top: 550,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }, 5);
  };
  const [isSelected, setIsSelected] = useState(false);
  const handleClck = () => {
    setIsSelected(!isSelected);
  };
 
  const sliderChildren = () => {
    return (
      <div>
        {spaceDetailsData.length > 0 && spaceDetailsData.map((card, index) =>
        selectedCard === index ? (
          <div
            className="card-container "
            style={{
              // width: "300px",
              backgroundColor: "#f8f8f8",
              borderRadius: "20px",
              margin: "20px",
              boxShadow: "0 0 10px rgba(66,182,119,255)",
            }}
            onClick={() => {
              handleSpaceSelect(index);
            }}
          >
            <img
              className="card-image "
              src={`${imageURL}${card.SpaceImage}`}
              alt="Lounge Space"
            />
            <div className="card-text">
              <div className="card-title">{card.SpaceType}</div>
              <div className="card-description">Space</div>
            </div>
          </div>
        ) : (
          <div
            className="card-container"
            onClick={() => {
              handleSpaceSelect(index);
            }}
          >
            <img
              className="card-image"
              src={`${imageURL}${card.SpaceImage}`}
              alt="Lounge Space"
            />
            <div className="card-text">
              <div className="card-title">{card.SpaceType}</div>
              <div className="card-description">Space</div>
            </div>
          </div>
        )
      )}
      </div>
    );
  }

const handleDateChange = (event) => {
  const datas = new Date(event.value);
  console.log(datas.getMonth(), "this is that ");
  fetchBookingDataSpace(spaceId, datas)

}

  return (
    <div id="Planning" className="planning-page main-content scheduler-space">
      {/* <Scheduler
   data={tempData}
   defaultView="month"
 /> */}
      {visibleDialog == true ? (
        <Dialog
          height={400}
          // width={300}
          onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "350px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px"
            }}>
            <div className="dialog-content"
            >
              <h1>Customer Details</h1>
              <div>
                <div className="dialog-item">
                  <span
                    style={{ paddingRight: "20px", width: "100px" }}>Name</span>
                  <div>{name}</div>
                </div>
                <div className="dialog-item">
                  <span style={{ paddingRight: "20px", width: "100px" }}>Booked</span>
                  <div>{start} - {end}</div>
                </div>
                <div className="dialog-item">
                  <span style={{ paddingRight: "20px", width: "100px" }}>Duration</span>
                  <div>{duration}</div>
                </div>
                <div className="dialog-item">
                  <span style={{ paddingRight: "20px", width: "100px" }}>Seats</span>
                  <div>{seat}</div>
                </div>
              </div>
              <div
                style={{
                  width: "100%"
                }}>
                <div style={{
                  paddingTop: "50px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}>
                  {/* <div className="content-button-padding"> */}
                  <Button primary={true} onClick={handleView} style={{
                    marginRight: "20px",
                    backgroundColor: "#42B677",
                    padding: "10px",
                    color: "#fff",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}>
                    {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                    VIEW FULL DETAILS
                  </Button>
                  {/* </div> */}
                  {/* <div className="content-button-padding"> */}
                  <Button onClick={handleClose}
                    style={{
                      marginRight: "20px",
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff"


                    }}>
                    CLOSE
                  </Button>
                  {/* </div> */}



                </div>
              </div>
            </div></div>
        </Dialog>) : <div> </div>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}>
        <Slider
          // hideArrows = {true}
          slideWidth={5}
          // Button = {}
          hideArrows = {false}
          slidesPerPageSettings={slidesPerPageSettings} style={{ display: "flex", width: "50%",}}
          >
            {spaceDetailsData.length > 0 && spaceDetailsData.map((card, index) =>
        selectedCard === index ? (
          <div
            className="card-container "
            style={{
              // width: "300px",
              backgroundColor: "#f8f8f8",
              borderRadius: "20px",
              margin: "20px",
              boxShadow: "0 0 10px rgba(66,182,119,255)",
            }}
            onClick={() => {
              handleSpaceSelect(index);
            }}
          >
            <img
              className="card-image "
              src={`${imageURL}${card.SpaceImage}`}
              alt="Lounge Space"
            />
            <div className="card-text">
              <div className="card-title">{card.SpaceType}</div>
              <div className="card-description">Space</div>
            </div>
          </div>
        ) : (
          <div
            className="card-container"
            onClick={() => {
              handleSpaceSelect(index);
            }}
          >
            <img
              className="card-image"
              src={`${imageURL}${card.SpaceImage}`}
              alt="Lounge Space"
            />
            <div className="card-text">
              <div className="card-title">{card.SpaceType}</div>
              <div className="card-description">Space</div>
            </div>
          </div>
        )
      )}
        </Slider>

      </div>
      <div className=" grid scheduler-content-space">
        <div className="card-component">
          <div id="Planning" className="planning-page main-content scheduler-space" >
            <Scheduler
              data={tempData}
              handlechangeDate= {handleDateChange}
              ontap={(e) => handleClick(e)}
            //  ontap={(e)=>onDateClick(e)}
            // defaultView="month"
            >
              <MonthView
              
              
              />
            </Scheduler>
            {/* <Scheduler
         data={data.filter((event) => filterState[event.employeeID])}
         ontap={onDateClick}
         onDataChange={onDataChange}
         modelFields={ordersModelFields}
         resources={[
           {
             name: "Teams",
             data: teams,
             field: "teamID",
             valueField: "teamID",
             textField: "teamName",
             colorField: "teamColor",
           },
         ]}
       /> */}
          </div>
        </div>
      </div>
    </div>
  );
};