import React, { useEffect, useRef, useState } from "react";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process } from "@progress/kendo-data-query";
import "hammerjs";
import "../css/Reports.scss";
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { exportIcon, eyeIcon } from "@progress/kendo-svg-icons";
import Breadcrumbs from "../components/Breadcrumbs";
import { Error, Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import axios from "axios";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
import { useNavigate } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";

interface GraphData {
  userCount: number;
  userName: string;
}

export const RoleStatus = {
  Company: 1,
  Individual: 2,
};

export const KYCUserList = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Booking Management",
      route: "",
    },
    {
      id: "2",
      text: "KYC User List",
      route: "",
    },
  ];

  const roles = [
    { Id: 1, Name: "Company" },
    { Id: 2, Name: "Individual" },
  ];
  const statuses = [
    { Id: 1, Name: "Verified" },
    { Id: 2, Name: "Rejected" },
    { Id: 3, Name: "Not Verified" },
  ];
  const defaultRoleValue = {
    Id: 0,
    Name: "Select customer type",
  };
  const defaultStatusValue = {
    Id: 0,
    Name: "Select Status",
  };

  const initialDataState = { skip: 0, take: 5 };
  const [dataState, setDataState] = useState(initialDataState);
  const [userListCount, setUserListCount] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [endDateError, endDateErrorSet] = useState("");
  const [startDateError, startDateErrorSet] = useState("");
  const [filterRole, setFilterRole] = useState(defaultRoleValue);
  const [filterStatus, setFilterStatus] = useState(defaultStatusValue);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>();
  const [filterEndDate, setFilterEndDate] = useState<Date | null>();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(userListCount);

  // Function to format date to YYYY-MM-DD
  const formatDate = (date: any) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };
  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    UserList(0, 0, 0, 0);
  }, [searchTerm]);

  // Initial API call
  const UserList = async (
    fromDate: any,
    toDate: any,
    role: any,
    status: any
  ) => {
    // console.log("User search type -->", role, "Status -->", status);
    setLoading(true);
    let fullURL = ``;
    let fromdateURL = fromDate === 0 ? "" : `startDate=${fromDate}`;
    let todateURL = toDate === 0 ? "" : `&endDate=${toDate}`;
    let roleURL = role === 0 ? "" : `&customerType=${role}`;
    let statusURL = status === 0 ? "" : `&status=${status}`;

    fullURL = `${baseURL}odata/GetKYCBookingDetails?${fromdateURL}${todateURL}${roleURL}${statusURL}`;
    try {
      const response = await axios.get(fullURL);
      //   console.log("content", response.data);
      const content = response.data.map((item: any, index: any) => ({
        ...item,
        sno: index + 1,
        formatdate: customDateOnly(item.date),
        kycStatus:
          item.kycStatusId === 1
            ? "Verified"
            : item.kycStatusId === 2
            ? "Rejected"
            : "Not Verified",
        customerType: item.customerType === 2 ? "Individual" : "Company",
      }));
      if (searchTerm.trim() === "") {
        setFilteredData(content); // Reset to original data when search is empty
      } else {
        const newData = content.filter((item: any) => {
          return item.customerName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase());
        });
        setDataState(initialDataState);
        setFilteredData(newData);
        let clearedPagerDataState = { ...dataState, take: 5, skip: 0 };
        setDataState(clearedPagerDataState);
      }
      //   console.log("User List for Export -->", content);
      setUserListCount(content);
      //   resetListSet(content);
      setUserList(content);
      setLoading(false);
      //   console.log("content", content);
    } catch (err: any) {
      //   console.log("Error --> ", err.response.data.message);
      alert(err.response.data.message);
      window.location.reload();
    }
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    console.log(item);
    navigate("/management/kycuserlist/kycupdate", {
      state: { infoId: item },
    });
  };

  // Date  filter
  const onFilterChangefromdate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setFilterStartDate(ev.value);
      // if (filterEndDate && ev.value > filterEndDate) {
      //   setFilterEndDate(null); // Clear the end date if it is less than the new start date
      // }
      if (filterEndDate && new Date(filterEndDate) < new Date(ev.value)) {
        setFilterEndDate(ev.value); // Set from date when from date less than to date
      }
    }
  };

  const onFilterChangetodate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setFilterEndDate(ev.value);
    }
  };

  // Handle filtering
  //   const filteredUsers = userListCount.filter(
  //     (user) =>
  //       (filterRole.Name === "Company" || user["customerType"] === 1
  //         ? filterRole
  //         : filterRole) &&
  //       (filterStatus.Name === "Active" || user["status"] === filterStatus)
  //   );

  // Search
  const handleSearchChanges = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchTerm(value);
    if (value === "") {
      setFilteredData(userListCount); // Reset to original data when search is empty
    } else {
      const newData = userListCount.filter((item: any) => {
        return item.customerName?.toLowerCase().includes(value.toLowerCase());
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };
  //   const [resetList, resetListSet] = useState([]);
  //   const filtered = filteredUsers.filter((filteredDataSearch: any) => {
  //     const search = searchQuery.toLowerCase();

  //     return filteredDataSearch.customerName?.toLowerCase().includes(search);
  //   });
  //   const [search, searchSet] = useState("");
  //   useEffect(() => {
  //     if (search) {
  //       const lowerCaseTerm = search.toLowerCase();
  //       setUserListCount(
  //         resetList.filter((item: any) =>
  //           item.customerName.toLowerCase().includes(lowerCaseTerm)
  //         )
  //       );
  //       //   setDataState(initialDataState);
  //     } else {
  //       setUserListCount(resetList);
  //     }
  //   }, [search]);

  const handleFilterChange = (e: any) => {
    e.preventDefault();
    // console.log(
    //   "The variables were -->",
    //   filterStartDate,
    //   filterEndDate,
    //   filterRole.Id,
    //   filterStatus.Id
    // );
    if (filterStartDate !== null && filterStartDate !== undefined) {
      if (filterEndDate !== null && filterEndDate !== undefined) {
        endDateErrorSet("");
        startDateErrorSet("");
        // console.log("The start date was", filterStartDate);
        UserList(
          // filterStartDate !== null && filterStartDate !== undefined ? formatDate(filterStartDate) : 0,
          // filterEndDate !== null && filterEndDate !== undefined ? formatDate(filterEndDate) : 0,
          formatDate(filterStartDate),
          formatDate(filterEndDate),
          filterRole.Id,
          filterStatus.Id
        );
      } else {
        endDateErrorSet("Please select To date");
        // startDateErrorSet("Please select Start date");
      }
    } else if (filterEndDate !== null && filterEndDate !== undefined) {
      if (filterStartDate !== null && filterStartDate !== undefined) {
        endDateErrorSet("");
        startDateErrorSet("");
        // console.log("The start date was", filterStartDate);
        UserList(
          // filterStartDate !== null && filterStartDate !== undefined ? formatDate(filterStartDate) : 0,
          // filterEndDate !== null && filterEndDate !== undefined ? formatDate(filterEndDate) : 0,
          formatDate(filterStartDate),
          formatDate(filterEndDate),
          filterRole.Id,
          filterStatus.Id
        );
      } else {
        //   endDateErrorSet("Please select To date");
        startDateErrorSet("Please select Start date");
      }
    } else {
      UserList(0, 0, filterRole.Id, filterStatus.Id);
    }
  };

  const handleClearFilters = () => {
    setFilterRole(defaultRoleValue);
    setFilterStatus(defaultStatusValue);
    setFilterStartDate(null);
    setFilterEndDate(null);
    UserList(0, 0, 0, 0);
    endDateErrorSet("");
    startDateErrorSet("");
  };

  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(userListCount);
  };

  function wrapText(text: string, maxCharsPerLine: number): string {
    const words = text.split(" ");
    let lines = [""];
    let currentLine = 0;

    for (const word of words) {
      if ((lines[currentLine] + word).length > maxCharsPerLine) {
        lines.push("");
        currentLine++;
      }
      lines[currentLine] += word + " ";
    }

    return lines.join("\n").trim(); // Join lines with line breaks
  }

  const handleActiveExport = (e: any) => {
    setLoading(false);
  };

  const _export = React.useRef<ExcelExport | null>(null);
  const _grid = React.useRef<any>();
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    let len = filteredData.length;
    const length = len;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    }
  };

  const detailedView = () => {
    return (
      <div
        style={{
          marginLeft: "1%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            Users KYC List
          </div>
          <div>
            <TextBox
              className="textBoxcustom"
              placeholder="Search using Username"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChanges}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchTerm ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
        </div>
        <ExcelExport
          ref={_export}
          fileName="Booked KYC User List.xlsx"
          onExportComplete={(e) => handleActiveExport(e)}
        />
        <Grid
          className={loading ? "k-disabled" : ""}
          skip={dataState.skip}
          take={dataState.take}
          total={filteredData.length}
          // pageable={{ buttonCount: 5, pageSizes: [5, 10, 15, 20] }}
          pageable={getPageableSettings()}
          onPageChange={(e) => setDataState(e.page)}
          data={process(filteredData, dataState)}
          // data={userListCount.slice(
          //     dataState.skip,
          //     dataState.skip + dataState.take
          // )}
          onDataStateChange={handlePageChange}
          ref={_grid}
        >
          <GridColumn
            field="sno"
            title="S.No"
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "35px",
                }}
              >
                {props.dataIndex + 1}
              </td>
            )}
          />
          <GridColumn field="customerId" title="User ID" width="50px" />
          <GridColumn
            field="formatdate"
            title="Date"
            headerCell={RightAlignedHeaderCell}
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "right",
                  paddingRight: "35px",
                }}
              >
                {props.dataItem.formatdate}
              </td>
            )}
          />
          <GridColumn
            field="customerName"
            title="Username"
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "left",
                }}
              >
                {props.dataItem.customerName}
              </td>
            )}
          />
          <GridColumn
            field="emailAddress"
            title="Email"
            width="250px"
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "left",
                  width: "100px",
                }}
              >
                {props.dataItem.emailAddress}
              </td>
            )}
          />
          <GridColumn
            field="customerType"
            title="Customer Type"
            cell={(props) => <td>{props.dataItem.customerType}</td>}
          />
          {/* <GridColumn field="kycId" title="KYC Verified"
                        cell={(props) => (
                            <td >
                                {props.dataItem.kycId === 1 ? "Verified" : props.dataItem.kycId === 2 ? "Rejected" : "Not Verified"}
                            </td>
                        )} /> */}
          <GridColumn field="kycStatus" title="Status" />
          <GridColumn
            field="kyRemarks"
            title="Remarks"
            cell={(props) => (
              <td
                style={{
                  wordWrap: "break-word",
                }}
              >
                {props.dataItem.kyRemarks === null
                  ? "-"
                  : props.dataItem.kyRemarks}
              </td>
            )}
          />
          <GridColumn
            title="Action"
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "left",
                }}
              >
                <MyViewCommandCell {...props} enterView={enterView} />
              </td>
            )}
          />
        </Grid>
      </div>
    );
  };

  const excelExport = () => {
    setLoading(true);
    if (_export.current !== null && userList.length !== 0) {
      _export.current.save(userList, _grid.current.columns);
    }
  };

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  -select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                <div>
                  {userListCount.length > 0 && (
                    <Button
                      className="bio-export-button-view"
                      svgIcon={exportIcon}
                      onClick={excelExport}
                    >
                      Export To CSV
                    </Button>
                  )}
                </div>
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              <div
                // className="common-margin"
                style={{
                  fontWeight: "bold",
                  fontSize: "2rem",
                  marginTop: "0px",
                  marginLeft: "2%",
                }}
              >
                Filters
              </div>
              <div
                className="filter-group"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  className="filter-view"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    // padding: "1%",
                  }}
                >
                  <div>
                    <Label>Select From Date</Label>
                    <style>{styles}</style>
                    <div className="no-wheel" ref={timePickerRef}>
                      <DatePicker
                        width="150px"
                        name="toDate"
                        className="dropdown-date"
                        onChange={onFilterChangefromdate}
                        value={filterStartDate}
                        format={"dd/MM/yyyy"}
                        placeholder="DD/MM/YYYY"
                        ref={timePickerRef}
                      />
                      {startDateError && <Error>{startDateError}</Error>}
                    </div>
                  </div>
                  <div style={{ width: "20px" }}></div>

                  <div>
                    <Label>Select To Date</Label>
                    <style>{styles}</style>
                    <div className="no-wheel" ref={timePickerRef}>
                      <DatePicker
                        width="150px"
                        name="toDate"
                        className="dropdown-date"
                        onChange={onFilterChangetodate}
                        value={filterEndDate}
                        format={"dd/MM/yyyy"}
                        placeholder="DD/MM/YYYY"
                        ref={timePickerRef}
                        min={filterStartDate ? filterStartDate : undefined}
                      />
                      {endDateError && <Error>{endDateError}</Error>}
                    </div>
                  </div>
                  <div style={{ width: "20px" }}></div>

                  <div>
                    <Label>Customer Type</Label>
                    <DropDownList
                      className="dropdown-date"
                      textField="Name"
                      dataItemKey="Id"
                      data={roles}
                      value={filterRole}
                      style={{ width: "200px", marginBottom: "10px" }}
                      onChange={(e) => {
                        // console.log("e.value -->", e.target.value);
                        setFilterRole(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div>
                    <Label>KYC Status</Label>
                    <DropDownList
                      className="dropdown-date"
                      textField="Name"
                      dataItemKey="Id"
                      data={statuses}
                      value={filterStatus}
                      style={{ width: "200px", marginBottom: "10px" }}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    />
                  </div>
                  <div>
                    <Label></Label>
                    <Button
                      className="common-button-view search-color"
                      onClick={handleFilterChange}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Label></Label>
                    <Button
                      className="common-button-view clear-color"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
              {detailedView()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
