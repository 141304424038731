import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";

interface MyDatePickerProps {
  minDate?: Date;
  maxDate? : Date;
  checkInDate: Date | null;
  setCheckInDate: (date: Date | null) => void;
  isDisableCheck: boolean;
  customFormatter: boolean;
}

export const MyDatePicker: React.FC<MyDatePickerProps> = ({
  minDate,
  checkInDate,
  setCheckInDate,
  isDisableCheck,
  maxDate,
  customFormatter,
}) => {
  const datePickerWrapperRef = useRef<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const datePickerRef = useRef<any>(null);
  const [includeTime, setIncludeTime] = useState(customFormatter); // condition to switch format

  useEffect(() => {
    const inputElement = datePickerRef.current?.element?.querySelector("input");
    if (inputElement) {
      // Disable arrow keys
      inputElement.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      });

      // Disable mouse wheel
      inputElement.addEventListener("wheel", (event: WheelEvent) => {
        event.preventDefault();
      });

      // Clean up event listeners
      return () => {
        inputElement.removeEventListener("keydown", () => {});
        inputElement.removeEventListener("wheel", () => {});
      };
    }
  }, [] );

  const handleDateChange = (e: DatePickerChangeEvent) => {
    if (
      e.nativeEvent.code === "ArrowUp" ||
      e.nativeEvent.code === "ArrowDown" ||
      e.nativeEvent.type === "input" ||
      e.nativeEvent.data === "0"
    ) {
      e.syntheticEvent.preventDefault();
    } else {
      setSelectedDate(e.value);
      setCheckInDate(e.value);
    }
  };

  // Function to dynamically set the date format
  const getCustomFormatterDate = () => {
    return includeTime ? "dd-MMM-yyyy HH:mm" : "EEE, dd/MM/yyyy";
  };

  return (
    <div ref={datePickerWrapperRef}>
      <DatePicker
      
        disabled={isDisableCheck}
        value={checkInDate}
        onChange={handleDateChange}
        min={minDate}
        max={maxDate}
        placeholder={"DD/MM/YYYY"}
        format={"dd/MM/yyyy"}
        className="personal-fields no-focus book-dropdown-new border_error_Change"
        name="bookingDatetime"
        ref={datePickerRef}
        onWheel={(e) => e.preventDefault()}
      />
    </div>
  );
};