import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import {
  calendarIcon,
  chevronDownIcon,
  divIcon,
  eyeIcon,
  listOrderedIcon,
  listUnorderedIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { BookingManagementData } from "./BookingListMain.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const BookingManagement = () => {
  const defaultBookingTypeValue = { Id: 0, SpaceType: "Select Booking Type" };

  const [deleteID, setDeleteID] = useState(0);
  const baseURL = commonBaseUrl();

  // Dani code
  const [mainData, setMainData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const [newToDate, setToDate] = useState<Date | null>();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [dataResult, setDataResult] = useState(
    process(filteredData, dataState)
  );
  const [bookingTypeList, setBookinTypeList] = useState([]);

  const [data, setData] = useState(filteredData);
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;
  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");
    const dataValue: any = searchData;
    for (var i = 0; i < dataValue.length; i++) {
      dataValue[i]["UserId"] = i + 1;
    }
    const DataValueMine = dataValue.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    // const DataValue = dataValue.find()
    setFilteredData(DataValueMine);
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //

  // Filter search
  const onFilterChange = (ev: any) => {
    let value = ev.value;
    setFilterValue(ev.value);
    var newData: any = [];

    if (value === "") {
      clearInput();
      newData = searchData;
    } else {
      newData = searchData.filter((item: any) => {
        return (
          item.CustomerName === null ||
          item.CustomerName.toLowerCase().includes(value.toLowerCase()) ||
          item.BookingId.toLowerCase().includes(value.toLowerCase()) ||
          item.ContactNo.toString().includes(value.toString()) ||
          item.BookingStatus.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
    for (var i = 0; i < newData.length; i++) {
      newData[i]["UserId"] = i + 1;
    }

    setFilteredData(newData);
    let clearedPagerDataState = { ...dataState, take: 5, skip: 0 };
    let processedData = process(newData, clearedPagerDataState);
    setDataResult(processedData);
    setDataState(clearedPagerDataState);
    setData(newData);
  };

  const [bookingType, setBookingType] = useState();

  // Date  filter
  const onFilterChangetodate = (ev: any) => {
    // Create a Date object from the string
    if (
      ev.nativeEvent.type === "input" ||
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input" ||
      ev.nativeEvent.data === "0"
    ) {
    } else {
      const date = new Date(ev.value);

      // Extract the year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index
      const day = String(date.getDate()).padStart(2, "0");

      // Format as "YYYY-MM-DD"
      const formattedDate = `${year}-${month}-${day}`;
      setSearchFromDate(formattedDate);
      setToDate(ev.value);
      console.log("gsygfwubho8ulgeytgy");
    }
  };

  const [editItems, setEditItem] = React.useState({});

  // Edit, View & Delete column booking list
  const MyEditCommandCell = (props: EditCommandCellProps) => (
    <EditCommandCell {...props} enterEdit={props.enterEdit} />
  );

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td>
        <Button
          className="edit-color"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterEdit = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], undefined);
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(` ${baseURL}odata/Bookings(${id})`);
      navigate("/management/editbooking", {
        state: { infoId: response.data, view },
      });
    } catch (err) {}
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], true);
        // navigate("/management/editbooking", {
        //   state: { infoId: originalData[d] },
        // });
        // setEditItem(originalData[d]);
      }
    }
    // navigate("/management/editbooking", {
    //   state: { infoId: item, view: true },
    // });
    setEditItem(item);
  };

  const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
    <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  );

  const DeleteCommandCell = (props: DeleteCommandCellProps) => {
    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
          onClick={() => {
            props.enterDelete(props.dataItem);
          }}
        ></Button>
      </td>
    );
  };

  const enterDelete = (item: any) => {
    console.log(item.Id);
    setDeleteID(item.Id);
    // navigate("/management/editbooking", { state: { infoId: item } });
    // setEditItem(item);
  };
  //
  // Clear data
  const clearInput = () => {
    const SpacesApi = async () => {
      try {
        console.log("main");
        const response = await axios.get(` ${baseURL}odata/SpaceHeaders`);
        setBookinTypeList(response.data.value);
        BookingExpandAPi();
      } catch (err) {}
    };
    setBookingType(undefined);
    SpacesApi();
    setToDate(null);
    // setBookingType();
    const DataValueMine = mainData.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    setFilteredData(DataValueMine);
    setSearchBooking("");
    setSearchFromDate("");
  };

  const onHandleSearch = () => {
    var bookingFilterURL = ``;
    if (searchBooking === "" && SearchFromDate === "") {
    } else if (
      searchBooking !== "" &&
      searchBooking !== undefined &&
      SearchFromDate === ""
    ) {
      bookingFilterURL = ` ${baseURL}odata/Bookings?$expand=Space&$filter=SpaceId%20eq%20${searchBooking}&orderby=Id desc`;
      BookingExpandWithFilterAPi(bookingFilterURL);
    } else if (
      (searchBooking === "" || searchBooking === undefined) &&
      SearchFromDate !== ""
    ) {
      bookingFilterURL = `
 ${baseURL}odata/Bookings?$expand=Space
&$filter=BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
      console.log(SearchFromDate, "<< search Date");
      BookingExpandWithFilterAPi(bookingFilterURL);
    } else if (
      searchBooking !== "" &&
      searchBooking !== undefined &&
      SearchFromDate !== ""
    ) {
      bookingFilterURL = `
 ${baseURL}odata/Bookings?$expand=Space
&$filter=SpaceId%20eq%20${searchBooking}%20and%20BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
      BookingExpandWithFilterAPi(bookingFilterURL);
    }
  };

  const [searchBooking, setSearchBooking] = useState("");
  const [SearchFromDate, setSearchFromDate] = useState("");

  const handleChangeBookingType = (e: any) => {
    console.log("The booking type was -->", e.value);
    setSearchBooking(e.value.Id);
    setBookingType(e.value);
  };

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Booking Management",
      route: "",
    },
    {
      id: "2",
      text: "Bookings",
      route: "",
    },
  ];

  // Convert date format
  const formatDate = (dateString: any) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    // List of booking type

    const SpacesApi = async () => {
      try {
        console.log("main");
        const response = await axios.get(` ${baseURL}odata/SpaceHeaders`);
        setBookinTypeList(response.data.value);
        BookingExpandAPi();
      } catch (err) {}
    };

    SpacesApi();
  }, []);
  const [getAllBookingStatus, setGetAllBookingStatus] = useState();
  // List of booking with expand list

  const BookingExpandAPi = async () => {
    try {
      const responseGetAllBookingStatus = await axios.get(
        ` ${baseURL}odata/GetAllBookingStatus`
      );
      console.log(
        responseGetAllBookingStatus.data,
        "responseGetAllBookingStatus"
      );
      const bookingStatus = responseGetAllBookingStatus.data;
      setGetAllBookingStatus(bookingStatus);

      try {
        const response = await axios.get(
          ` ${baseURL}odata/Bookings?$expand=Space&orderby=Id desc&$filter=IsActive eq true`
        );
        var content = response.data.value;
        setOriginalData(response.data.value);

        const contentLen = content.length;
        for (let i = 0; i < contentLen; i++) {
          const bookingDaeandtime =
            content[i]["BookingFrom"] === null ? "" : content[i]["BookingFrom"];

          // // Split at 'T' to separate date and time
          const [date1, timeWithZone1] =
            bookingDaeandtime !== undefined ? bookingDaeandtime.split("T") : "";

          content[i]["BookingFrom"] = formatDate(date1);
          content[i]["UserId"] = i + 1;
          const BookingData = bookingStatus.find(
            (BStatus: any) => BStatus.id === content[i]["BookingStatus"]
          );

          content[i]["BookingStatus"] = BookingData.name;
        }

        const DataValueMine = content.filter(
          (Dataset: any) => Dataset.IsActive === true
        );
        console.log(DataValueMine, "11234567890wdfghjkl");

        setMainData(DataValueMine);
        setFilteredData(DataValueMine);
        setSearchData(DataValueMine);
      } catch (err) {}
    } catch (err) {}
  };

  // List of booking expand list with filter
  const BookingExpandWithFilterAPi = async (filterUrl: any) => {
    try {
      const responseGetAllBookingStatus = await axios.get(
        ` ${baseURL}odata/GetAllBookingStatus`
      );
      console.log(
        responseGetAllBookingStatus.data,
        "responseGetAllBookingStatus"
      );
      const bookingStatus = responseGetAllBookingStatus.data;
      setGetAllBookingStatus(bookingStatus);

      try {
        console.log("test 1");
        const response = await axios.get(filterUrl);
        var content = response.data.value;
        console.log(">>", response);
        const contentLen = content.length;
        for (let i = 0; i < contentLen; i++) {
          const bookingDaeandtime =
            content[i]["BookingFrom"] === null ? "" : content[i]["BookingFrom"];

          // // Split at 'T' to separate date and time
          const [date1, timeWithZone1] =
            bookingDaeandtime !== undefined ? bookingDaeandtime.split("T") : "";
          const BookingData = bookingStatus.find(
            (BStatus: any) => BStatus.id === content[i]["BookingStatus"]
          );

          content[i]["BookingStatus"] = BookingData.name;
          content[i]["BookingFrom"] = formatDate(date1);
          content[i]["UserId"] = i + 1;
        }
        console.log(content, "DataLoades");
        const DataValueMine = content.filter(
          (Dataset: any) => Dataset.IsActive === true
        );

        setFilteredData(DataValueMine);
        setSearchData(content);
      } catch (err) {}
    } catch (err) {}
  };

  const DeleteApi = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}odata/Bookings(${deleteID})`
      );
      if (response.status === 204) {
        setDeleteID(0);
        setVisible(true);
        BookingExpandAPi();
        setAlertContent("Booking has been deleted successfully");
      }
      console.log(response);
    } catch (err) {}
  };
  const [selected, setSelected] = useState<string>("calendar");
  const handleSelect = (view: string) => {
    setSelected(view);
    // Handle the logic when a view is selected
  };
  const onDateClickData = React.useCallback((e: any, idSpace: any) => {
    const startDate = e.dataItem.start;
    setSelected("list");
    const date = new Date(e.dataItem.start);
    console.log("VALUE FROM CALENDAR", e);
    console.log("SPACE ID FROM CALENDAR", idSpace);
    setBookingType(idSpace);
    setToDate(date);
    // const setBookingType = bookingTypeList.find((space:any)=>{
    // })
    // for(let i =0; i<bookingTypeList.length;i++){
    //   if(idSpace === bookingTypeList[i].Id){
    //   }
    // }
    // Extract the year, month, and day
    console.log("The bookingList was -->", bookingTypeList);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index
    const day = String(date.getDate()).padStart(2, "0");

    // Format as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate, "<for");
    // setSearchFromDate(formattedDate)
    var bookingFilterURL = `${baseURL}odata/Bookings?$expand=Space
&$filter=SpaceId%20eq%20${idSpace.Id}%20and%20BookingFrom%20eq%20${formattedDate}&orderby=Id desc`;

    //  `
    //   ${baseURL}odata/Bookings?$expand=Space
    //  &$filter=BookingDatetime%20eq%20${formattedDate}&orderby=Id desc`;
    if (idSpace !== 0) {
      BookingExpandWithFilterAPi(bookingFilterURL);
    }
    //     var bookingFilterURL = `
    //   ${baseURL}odata/Bookings?$expand=Space
    //  &$filter=SpaceId%20eq%20%20and%20BookingDatetime%20eq%20${formattedDate}&orderby=Id desc`;
    //  BookingExpandWithFilterAPi(bookingFilterURL);
    // setSearchFromDate(formattedDate);
    //     window.setTimeout(()=>{
    // onHandleSearch();
    //     },1000)
    console.log(startDate, "<this Datas");
  }, []);

  const [visible, setVisible] = React.useState<boolean>(false);
  const [alertContent, setAlertContent] = React.useState<String>("");
  const toggleDialog = () => {
    setVisible(false);
  };

  return (
    <div className="overall-view ">
      <div>
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      {/* Srikkanth */}
      <div className="button-toggle">
        <ButtonGroup>
          <Button
            selected={selected === "calendar"}
            onClick={() => {
              handleSelect("calendar");
            }}
            className={
              selected === "calendar" ? "selected-color" : "unselect-color"
            }
            svgIcon={calendarIcon}
            style={{ borderColor: "#42B677" }}
          >
            CALENDAR VIEW
          </Button>
          <Button
            selected={selected === "list"}
            onClick={() => handleSelect("list")}
            svgIcon={listUnorderedIcon}
            className={
              selected === "list" ? "selected-color" : "unselect-color"
            }
            style={{ borderColor: "#42B677" }}
            // className= {selected === 'calendar' ?"unselect-color" : 'selected-color'}
          >
            LIST VIEW
          </Button>
        </ButtonGroup>
      </div>
      {selected === "calendar" && (
        <BookingManagementData onDateClick={onDateClickData} />
      )}
      {selected === "list" && (
        <div>
          <div className="common-margin">
            <Label className="list-heading">Filter</Label>
            <div className=" filter-view ">
              <div className="rowset">
                <div className="columnset">
                  <Label className="common-label-size">Booking Type</Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    name="bookingType"
                    className="book-dropdown"
                    data={bookingTypeList}
                    textField="SpaceName"
                    value={
                      bookingType !== undefined
                        ? bookingType
                        : { Id: 0, SpaceName: "Select Booking Type" }
                    }
                    onChange={(e) => handleChangeBookingType(e)}
                  />
                </div>
                <div className="columnset-datepicker ">
                  <style>{styles}</style>
                  <Label className="common-label-size">Booking Date</Label>
                  <DatePicker
                    name="toDate"
                    className="book-dropdown no-wheel"
                    onChange={onFilterChangetodate}
                    value={newToDate}
                    format={"dd/MM/yyyy"}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <div className="columnset-end">
                  <Label className="common-label-size"></Label>
                  <Button
                    className="common-button-view search-color"
                    onClick={onHandleSearch}
                  >
                    Search
                  </Button>
                </div>
                <div className="columnset-end">
                  <Label className="common-label-size"></Label>
                  <Button
                    className="common-button-view clear-color"
                    onClick={clearInput}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-container"></div>
          <div className="common-margin view-margin">
            <div className="table-title-view">
              <div className="large-custom-input-container">
                <Label className="list-heading">Booking List</Label>
                <TextBox
                  className="textBoxcustomSearchLB"
                  placeholder="Search by Customer Name / Booking ID / Mobile Number / Status"
                  name="roomcost"
                  value={filterValue}
                  onChange={onFilterChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {filterValue ? (
                          <Button icon="close" onClick={resetSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              {/* {filteredData.length === 0 && loadingPanel} */}
              <Grid
                data={process(filteredData, dataState)}
                pageable={getPageableSettings()}
                skip={dataState.skip}
                take={dataState.take}
                total={filteredData.length}
                onPageChange={(e) => setDataState(e.page)}
                onDataStateChange={handlePageChange}
              >
                <GridColumn
                  field="UserId"
                  title="S.No"
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                  format="{0:d}"
                  className="list-sno"
                />
                <GridColumn
                  field="BookingId"
                  title="Booking ID"
                  filter="numeric"
                  width={"200px"}
                />
                <GridColumn
                  field="Space.SpaceTypeName"
                  title="Space Type"
                  filter="text"
                />
                <GridColumn
                  field="CustomerName"
                  title="Customer Name"
                  className="customer-name-styles"
                />
                <GridColumn
                  field="ContactNo"
                  title="Customer Mobile"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.ContactNo}
                    </td>
                  )}
                />
                <GridColumn
                  field="BookingFrom"
                  title="Booked Date"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.BookingFrom}
                    </td>
                  )}
                />
                <GridColumn field="BookingStatus" title="Status" />
                <GridColumn
                  // field="sno"
                  title="Action"
                  width={10}
                  // className="Data"
                  // width={"240px"}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      <MyViewCommandCell {...props} enterView={enterView} />
                      {/* <MyDeleteCommandCell
                        {...props}
                        enterDelete={enterDelete}
                      /> */}
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      )}
      {deleteID !== 0 && (
        <Dialog title="Confirm" onClose={() => setDeleteID(0)}>
          <p>Are you sure you want to delete this row?</p>
          <DialogActionsBar>
            <Button onClick={DeleteApi}>Yes</Button>
            <Button onClick={() => setDeleteID(0)}>No</Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {visible && (
        <Dialog
          title={"Message"}
          onClose={toggleDialog}
          style={{ alignItems: "center" }}
        >
          <Label
            style={{
              height: "80px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {alertContent}
            <div
              style={{
                padding: "20px",
              }}
            />
            <Button
              className="alert-button"
              type="button"
              onClick={toggleDialog}
            >
              Ok
            </Button>
          </Label>
        </Dialog>
      )}
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}
interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
interface DeleteCommandCellProps extends GridCellProps {
  enterDelete: (item: any) => void;
}
