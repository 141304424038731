import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  chevronDownIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import React, { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { process, State } from "@progress/kendo-data-query";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const UserEnrollment = () => {
  const [mainData, setMainData] = useState([]);
  const [bookingType, setBookingType] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [bookingTypeList, setBookinTypeList] = useState([]);

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Biometric Devices",
      route: "",
    },
    {
      id: "2",
      text: "User Enrollment",
      route: "",
    },
  ];

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");
    const dataValue: any = searchData;
    for (var i = 0; i < dataValue.length; i++) {
      dataValue[i]["UserId"] = i + 1;
    }
    const DataValueMine = dataValue.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    setFilteredData(DataValueMine);
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //

  // Filter search
  const onFilterChange = (ev: any) => {
    let value = ev.value;
    setFilterValue(ev.value);
    var newData: any = [];

    if (value === "") {
      newData = searchData;
    } else {
      newData = searchData.filter((item: any) => {
        return (
          item.CustomerName === null ||
          item.CustomerName.toLowerCase().includes(value.toLowerCase()) ||
          item.BookingId.toLowerCase().includes(value.toLowerCase()) ||
          item.ContactNo.toString().includes(value.toString())
        );
      });
    }
    for (var i = 0; i < newData.length; i++) {
      newData[i]["UserId"] = i + 1;
    }

    setFilteredData(newData);
    let clearedPagerDataState = { ...dataState, take: 5, skip: 0 };
    setDataState(clearedPagerDataState);
  };

  // Edit, Delete column device list
  const MyEditCommandCell = (props: EditCommandCellProps) => (
    <EditCommandCell {...props} enterEdit={props.enterEdit} />
  );

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = async (item: any) => {
    // try {
    //   const response = await axios.get(
    //     `https://dev-cw-api.equadriga.in/odata/SpaceHeaders(${item.Id})?$expand=SpaceDetails`
    //   );
    //   if (response.status === 200 || response.status === 201) {
    //     console.log(response.status, "<");
    //     const datas = response.data || [];
    //     navigate("/masters/room/createroom", { state: datas });
    //   }
    // } catch (err) {}
  };

  const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
    <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  );

  const DeleteCommandCell = (props: DeleteCommandCellProps) => {
    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
        ></Button>
      </td>
    );
  };

  const enterDelete = (item: any) => {
    console.log(item.Id);
  };

  const [searchBooking, setSearchBooking] = useState("");

  // Clear data
  const clearInput = () => {
    const DataValueMine = mainData.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    setFilteredData(DataValueMine);
    setSearchBooking("");
  };

  const handleChangeBookingType = (e: any) => {
    setSearchBooking(e.value.Id);
    setBookingType(e.value);
  };

  const onHandleSearch = () => {
    //     var bookingFilterURL = ``;
    //     if (searchBooking === "" && SearchFromDate === "") {
    //     } else if (
    //       searchBooking !== "" &&
    //       searchBooking !== undefined &&
    //       SearchFromDate === ""
    //     ) {
    //       bookingFilterURL = ` ${baseURL}odata/Bookings?$expand=Space&$filter=SpaceId%20eq%20${searchBooking}&orderby=Id desc`;
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     } else if (
    //       (searchBooking === "" || searchBooking === undefined) &&
    //       SearchFromDate !== ""
    //     ) {
    //       bookingFilterURL = `
    //  ${baseURL}odata/Bookings?$expand=Space
    // &$filter=BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
    //       console.log(SearchFromDate, "<< search Date");
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     } else if (
    //       searchBooking !== "" &&
    //       searchBooking !== undefined &&
    //       SearchFromDate !== ""
    //     ) {
    //       bookingFilterURL = `
    //  ${baseURL}odata/Bookings?$expand=Space
    // &$filter=SpaceId%20eq%20${searchBooking}%20and%20BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     }
  };

  return (
    <div className="overall-view ">
      <div>
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div>
        <div className="common-margin">
          <Label className="list-heading">Filter</Label>
          <div className=" filter-view ">
            <div className="rowset">
              <div className="columnset">
                <Label className="common-label-size">User Type</Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  name="bookingType"
                  className="book-dropdown"
                  data={bookingTypeList}
                  textField="SpaceName"
                  value={
                    bookingType !== undefined
                      ? bookingType
                      : { Id: 0, SpaceName: "Select User Type" }
                  }
                  onChange={(e) => handleChangeBookingType(e)}
                />
              </div>
              <div className="columnset-datepicker">
                <Label className="common-label-size">Biometric Enrolled</Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  name="bookingType"
                  className="book-dropdown"
                  data={bookingTypeList}
                  textField="SpaceName"
                  value={
                    bookingType !== undefined
                      ? bookingType
                      : { Id: 0, SpaceName: "Select Biometric Enrolled" }
                  }
                  onChange={(e) => handleChangeBookingType(e)}
                />
              </div>
              <div className="columnset-end">
                <Label className="common-label-size"></Label>
                <Button
                  className="common-button-view search-color"
                  onClick={onHandleSearch}
                >
                  Filter
                </Button>
              </div>
              <div className="columnset-end">
                <Label className="common-label-size"></Label>
                <Button
                  className="common-button-view clear-color"
                  onClick={clearInput}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">List of Users</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using any keywords"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            {/* {filteredData.length === 0 && loadingPanel} */}
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="UserId"
                title="S.No"
                format="{0:d}"
                className="list-sno"
              />
              <GridColumn field="BookingId" title="User ID" filter="numeric" />
              <GridColumn field="CustomerName" title="Name" />
              <GridColumn
                field="Space.SpaceTypeName"
                title="User Type"
                filter="text"
              />
              <GridColumn field="BookingStatus" title="Biometric Enrolled" />
              <GridColumn
                title="Action"
                width={"240px"}
                cell={(props) => (
                  <td>
                    <MyEditCommandCell {...props} enterEdit={enterView} />
                    <MyDeleteCommandCell {...props} enterDelete={enterDelete} />
                  </td>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}

interface DeleteCommandCellProps extends GridCellProps {
  enterDelete: (item: any) => void;
}
